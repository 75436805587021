import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { AuthGuard } from './services/guard/auth.guard';
import { ChoosenContaGuardService } from './theme/auth/choosen-conta-guard.service';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [ChoosenContaGuardService],
    canActivateChild: [ChoosenContaGuardService],
    children: [
      {
        path: '',
        redirectTo: 'dashboard/default',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: './theme/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'pacientes',
        loadChildren: './pages/patients/patients.module#PatientsModule',
        data: {
          id: 2,
          children: false
        },
        canActivate: [AuthGuard, ChoosenContaGuardService]
      },
      {
        path: 'agenda',
        loadChildren: './pages/agenda/agenda.module#AgendaModule',
        data: {
          id: 4,
          children: false
        },
        canActivate: [AuthGuard, ChoosenContaGuardService]
      },
      {
        path: 'produtos',
        loadChildren: './pages/produtos/produtos.module#ProdutosModule',
        canActivate: [ChoosenContaGuardService]
      },
      {
        path: 'financial',
        loadChildren: './pages/financial/financial.module#FinancialModule',
        data: {
          id: 6,
          children: false
        },
        canActivate: [AuthGuard, ChoosenContaGuardService]
      },
      {
        path: 'reports',
        loadChildren: './pages/reports/reports.module#ReportsModule',
        data: {
          id: 9,
          children: false
        },
        canActivate: [AuthGuard, ChoosenContaGuardService]
      },
      {
        path: 'config',
        loadChildren: './pages/configs/configs.module#ConfigsModule',
        canActivate: [ChoosenContaGuardService]
      },
      {
        path: 'tools',
        loadChildren: './pages/tools/tools.module#ToolsModule',
        data: {
          id: 7,
          children: false
        },
        canActivate: [AuthGuard, ChoosenContaGuardService]
      }
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: './theme/auth/auth.module#AuthModule'
      },
      {
        path: 'maintenance/offline-ui',
        loadChildren:
          './theme/maintenance/offline-ui/offline-ui.module#OfflineUiModule'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
