import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { UtilsService } from "../../services/utils/utils.service";
import { ShortListItem } from "../../core/models/forms/common/common.model";
import { CONSTANTS } from "../../core/constants/constants";
import {
	AgendaModel,
	BusinessHours,
	EventAgenda,
	ServicesListModel,
} from "../../core/models/agenda/agenda.model";
import { Calendar } from "@fullcalendar/core";
import timeGridPlugin from "@fullcalendar/timegrid";
import interaction from "@fullcalendar/interaction";
import { animate, state, style, transition, trigger } from "@angular/animations";
import Swal from "sweetalert2";
import { ConvenantModel } from "../../core/models/patients/convenio.model";
import * as moment from "moment";
import { CustomDatepickerI18n, I18n } from "../../shared/ngb-datepicker/datepicker-i18n";
import {
	NgbDate,
	NgbDateParserFormatter,
	NgbDatepickerI18n,
	NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "../../shared/ngb-datepicker/ngb-date-fr-parser-formatter";
import { forkJoin, of, Subject } from "rxjs";
import { classToPlain } from "class-transformer";
import { flatMap, map } from "rxjs/operators";
import { always, equals, identity, ifElse } from "ramda";
import { Observable } from "rxjs/Observable";
import { PatientModel } from "../../core/models/patients/patients.model";
import { ModalAnimationComponent } from "../../shared/modal-animation/modal-animation.component";
import Tooltip from "tooltip.js";
import { isEmpty } from "lodash-es";
import { FULL_CELLPHONE_NUMBER_MASK } from "../../lib/input-masks";
import { LISTS } from "./lists";
import { NotificationsService } from "angular2-notifications";
import { DEFAULT_AVATAR_PROFILE_PATH } from "../../layout/admin/account-chooser/account-chooser.component";
import { PATIENT_RECORD_LINK } from "./agendamento/scheduling/scheduling.component";
import { CommitmentService } from "./agendamento/commitment/service/commitment.service";
import { ReturnAlert } from "./return-alert/model/return-alert";
import { ReturnAlertService } from "./return-alert/service/return-alert.service";
import { ReturnAlertStatus } from "./return-alert/model/enum/return-alert-status";
import { Router } from "@angular/router";

declare let $: any;

moment.locale("pt-br");

export const SCHEDULING = "AGENDAMENTO";
export const COMMITMENT = "COMPROMISSO";
export const MARCADO = "MARCADO";
const diaIsDomingo = equals(7);
const tratarDomingo = ifElse(diaIsDomingo, always(0), identity);

export const NO_LABEL = "Não Disponível";
export const FULL_CELLPHONE_LENGTH = 11;
export const CELLPHONE_LENGTH = 10;
const FOUR_DIGITS_HOUR_REGEX = /\d{1,2}:\d{2}/;
const BRAZILIAN_FORMAT_DATE = /(\d{4})-(\d{2})-(\d{2})/;
const buildWhatsappMessage = (
	patientCellPhone: string,
	greeting: string,
	patientName: string,
	date: string,
	time: string,
	doctorName: string
): string =>
	`https://api.whatsapp.com/send?phone=55${patientCellPhone}&text=${greeting}, ${patientName}!
  Passando apenas para confirmar seu horário dia ${date} às ${time}h, com o(a) Dr(a): ${doctorName}, OK?`;

interface SchedulingForTooltip {
	patientId?: number;
	pacienteCodigoProntuario?: string;
	convenioNome?: string;
	schedulingForDatabase: AgendaModel;
	professionalName: string;
}

@Component({
	selector: "app-agenda",
	templateUrl: "./agenda.component.html",
	styleUrls: ["./agenda.component.scss", "./../../../assets/icon/icofont/css/icofont.scss"],
	providers: [
		I18n,
		{ provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
		{ provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
	],
})
export class AgendaComponent implements OnInit, OnDestroy {
	@ViewChild("fullCalendar") fullCalendar: ElementRef;

	@ViewChild("modalAgenda") modalAgenda?: ModalAnimationComponent;

	@ViewChild("schedulingTooltip") schedulingTooltip: ElementRef;
	@ViewChild("schedulingComponent") schedulingComponent?;

	@HostListener("document:click", ["$event"])
	clickOutside(event) {
		if (this.fullCalendar.nativeElement.contains(event.target)) {
			return;
		}
		if (event.path.find((el) => el.classList && el.classList.contains("scheduling-tooltip"))) {
			return;
		}
		if (!this.schedulingTooltip.nativeElement.contains(event.target)) {
			this.closeTooltipsOnCalendarEvents();
		}
	}

	schedulingStatusList = new LISTS(this.service.translate).listStatusAgenda;
	readonly defaultAvatarPath = DEFAULT_AVATAR_PROFILE_PATH;

	currentScheduling?: SchedulingForTooltip;
	currentTooltip?: Tooltip;

	view = "timeGridWeek";
	option = "calendar";
	labelScheduledFormated: string;

	professionalId = null;
	professionals: ShortListItem[] = [];
	professionalsModal: ShortListItem[] = [];
	convenantsList: ConvenantModel[] = [];
	servicesList: ServicesListModel[] = [];
	public dataHoraInicial;
	private dataHoraFinal;
	events: EventAgenda[] = [];
	private lastProfessionalId = -1;
	public businessHours: BusinessHours[] = [];
	public updateSchduleOptions = true;
	initCalendar = false;
	showModalAgenda = false;
	showModalPrintAgenda = false;
	calendar: Calendar;
	eventObj: AgendaModel;
	patient: PatientModel;
	initDate: NgbDateStruct;
	endDate: NgbDateStruct;
	returnAlerts: ReturnAlert[];
	gridOptions = [
		this.service.translate.instant("AGENDA.WEEK"),
		this.service.translate.instant("AGENDA.DAY"),
	];
	gridSelected: string = this.service.translate.instant("AGENDA.WEEK");

	private destroy$ = new Subject();

	readonly cfgCalendarByStatusListMap = {
		COMMITMENT: {
			textColor: "white",
			backgroundColor: "rgb(255, 166, 113)",
			descricao: "Compromisso",
		},
		MARCADO: {
			textColor: "#FFFFFF",
			backgroundColor: "#607d8b",
			descricao: "Marcado",
			icon: "MARCADO",
		},
		REMARCADO: {
			textColor: "#FFFFFF",
			backgroundColor: "#e6b72a",
			descricao: "Remarcado",
			icon: "REMARCADO",
		},
		AGUARDANDO: {
			textColor: "#FFFFFF",
			backgroundColor: "#00bcd4",
			descricao: "Aguardando",
			icon: "AGUARDANDO",
		},
		CONFIRMADO: {
			textColor: "#FFFFFF",
			backgroundColor: "#af82b5",
			descricao: "Confirmado",
			icon: "CONFIRMADO",
		},
		ATENDIDO: {
			textColor: "#FFFFFF",
			backgroundColor: "#4caf50",
			descricao: "Atendido",
			icon: "ATENDIDO",
		},
		EM_ATENDIMENTO: {
			textColor: "#FFFFFF",
			backgroundColor: "#187ddb",
			descricao: "Em atendimento",
			icon: "EM_ATENDIMENTO",
		},
		FALTOU: {
			textColor: "#FFFFFF",
			backgroundColor: "#f44336",
			descricao: "Faltou",
			icon: "FALTOU",
		},
		PACIENTE_DESMARCOU: {
			textColor: "#534505",
			backgroundColor: "#fadf69",
			descricao: "Desmarcado pelo paciente",
			icon: "PACIENTE_DESMARCOU",
		},
		PROFISSIONAL_DESMARCOU: {
			textColor: "#573A05",
			backgroundColor: "#fab469",
			descricao: "Desmarcado pelo profissional",
			icon: "PROFISSIONAL_DESMARCOU",
		},
	};

	public options = {
		minTime: "07:00:00",
		maxTime: "19:00:00",
		contentHeight: "auto",
		slotLabelFormat: "HH:mm",
		slotDuration: "00:15:00",
		slotLabelInterval: 15,
		selectConstraint: "businessHours",
	};

	constructor(
		public service: UtilsService,
		private readonly _router: Router,
		private readonly _notificationsService: NotificationsService,
		private readonly _commitmentService: CommitmentService,
		private readonly _returnAlertService: ReturnAlertService
	) {
		this.professionalId = this.service.getStorage(CONSTANTS.STORAGE.PROFESSIONAL_ID);
	}

	ngOnInit() {
		this.initItens();
		this._initReturnAlerts();
	}

	async _initReturnAlerts() {
		const now = moment().format("L");
		const fifteenDaysAfter = moment().add(15, "days").format("L");
		this.returnAlerts = await this._returnAlertService.listAll(now, fifteenDaysAfter);
	}

	ngOnDestroy() {
		this.destroy$.next();
		this.destroy$.complete();
		this.removeTooltips();
	}

	initItens() {
		this.service.loading(true);
		window.onresize = () => {
			this.setCalendarSize();
		};

		const actives = this.service.httpGET(CONSTANTS.ENDPOINTS.professional.active);
		const dropdown = this.service.httpGET(CONSTANTS.ENDPOINTS.patients.treatment.convenant);
		const servicesList = this.service.httpGET(CONSTANTS.ENDPOINTS.agenda.typeService);

		forkJoin([actives, dropdown, servicesList]).subscribe(
			(list) => {
				this.professionals = list[0].body as ShortListItem[];
				this.convenantsList = list[1].body as ConvenantModel[];
				this.servicesList = list[2].body as ServicesListModel[];

				this.professionalsModal = classToPlain(this.professionals) as ShortListItem[];

				this.professionals.unshift({
					id: null,
					nome: this.service.translate.instant("AGENDA.ALL_PROFESSIONALS"),
				});

				this.loadEvents(null);
			},
			(err) => {
				this.service.loading(false);
				this.service.notification.error(
					this.service.translate.instant("COMMON.ERROR.SEARCH"),
					err.error.error
				);
			}
		);
	}

	loadEvents(event) {
		if (!event) {
			this.dataHoraInicial = moment().startOf("week").subtract(1, "day").toDate().toISOString();
			this.dataHoraFinal = moment().endOf("week").subtract(1, "day").toDate().toISOString();
		} else {
			this.dataHoraInicial = moment(event.view.activeStart)
				.subtract(1, "day")
				.toDate()
				.toISOString();
			this.dataHoraFinal = moment(event.view.activeEnd).subtract(1, "day").toDate().toISOString();
		}

		setTimeout(() => {
			this.loadEventsFromBackend();
		});
	}

	addEvents() {
		this.events.map((event) => {
			const index = this.calendar
				.getEvents()
				.findIndex(
					(eventCalendar) => eventCalendar.extendedProps.eventObject.id === event.eventObject.id
				);
			if (index < 0) {
				this.calendar.addEvent(event);
			}
		});
	}

	private clearTemporaryEvents() {
		if (this.events) {
			this.events = this.events.filter((item) => {
				return item.title;
			});
		}
	}

	private openEvent(event) {
		this.patient = null;
		this.clearTemporaryEvents();

		this.eventObj = {
			id: null,
			pacienteId: null,
			profissionalId: this.professionalId,
			convenioId: null,
			celular: null,
			telefoneFixo: null,
			email: null,
			dataHoraInicialEvento: moment(event.dateStr).format("DD/MM/YYYY HH:mm:ss"),
			duracao: this.options.slotLabelInterval,
			emStatusAgendamento: MARCADO,
			anotacoes: null,
			pacienteCodigoProntuario: null,
			tipoAtendimentoId: null,
			dataHoraCadastro: null,
			dataHoraAlteracao: null,
			usuarioCadastroNome: null,
			usuarioAlteracaoNome: null,
			emTipoAgendamento: SCHEDULING,
			alertaRetornoId: null,
		};

		this.openModalAgenda(true);
	}

	openModalAgenda(newEvent: boolean = false) {
		this.hideTooltips();
		if (newEvent) {
			this.showModalAgenda = true;
			setTimeout(() => {
				if (this.modalAgenda) {
					this.modalAgenda.open();
				}
			});
		} else {
			this.getModalInfo(true);
		}
	}

	getModalInfo(openModal: boolean = true) {
		this.service.loading(true);
		this.getEvent(this.eventObj.id).subscribe(
			(data) => {
				this.service.loading(false);
				this.patient = data.patient;

				delete data.event.pacientePessoaId;
				delete data.event.pacientePessoaNome;
				delete data.event.dataHoraFinalEvento;
				delete data.event.usuarioCadastroId;
				delete data.event.usuarioAlteracaoId;

				this.eventObj = data.event;
				this.eventObj.dataHoraInicialEvento = moment(data.event.dataHoraInicialEvento).format(
					"DD/MM/YYYY HH:mm:ss"
				);
				if (openModal) {
					this.showModalAgenda = true;
					setTimeout(() => {
						if (this.modalAgenda) {
							this.modalAgenda.open();
						}
					});
				} else {
					this.eventObj.emStatusAgendamento = this.currentScheduling.schedulingForDatabase.emStatusAgendamento;
					this._saveEventStatus();
				}
			},
			(err) => {
				this.service.loading(false);
				this.service.notification.error(
					this.service.translate.instant("COMMON.ERROR.SEARCH"),
					err.error.error
				);
			}
		);
	}

	updateEvents() {
		this.initCalendar = false;
		this.hideTooltips();
		this.calendar.removeAllEvents();
		this.updateLabel();
		this.loadEventsFromBackend();
	}

	clickAction(info) {
		if ((info.jsEvent.target.className as string).indexOf("nonbusiness") >= 0) {
			const translate = this.service.translate;
			const refSWAL = "AGENDA.SWAL";

			Swal.fire({
				title: translate.instant(`${refSWAL}.TITLE`),
				text: translate.instant(`${refSWAL}.TEXT`),
				type: "question",
				showCancelButton: true,
				confirmButtonText: translate.instant(`${refSWAL}.CONFIRM_BUTTON`),
				cancelButtonText: translate.instant(`${refSWAL}.CANCEL_BUTTON`),
			}).then((result) => {
				if (result.value) {
					this.openEvent(info);
				}
			});
		} else {
			this.openEvent(info);
		}
	}

	public onEventDrop(event: any) {
		const params = {
			eventoAgendaId: event.event.extendedProps.eventObject.id,
			dataHoraInicial: moment(event.event.start).toDate().toISOString(),
		};

		this.service.loading(true);
		this.service.httpPUT(CONSTANTS.ENDPOINTS.agenda.updateDateTime, {}, params).subscribe(
			(data) => {
				this.service.loading(false);

				this.service.notification.success(
					this.service.translate.instant("AGENDA.TITLE"),
					this.service.translate.instant("AGENDA.SUCCESS")
				);
				this.refreshCalendar();
			},
			(err) => {
				this.service.loading(false);
				this.service.notification.error(
					this.service.translate.instant("COMMON.ERROR.SEARCH"),
					err.error.error
				);
			}
		);
	}

	onEventClick(eventObject) {
		this._fillTooltipOnClick(this._buildSchedulingForTooltip({ eventObject }));
		this.eventObj = eventObject;
	}

	getEvent(id): Observable<any> {
		return this.service.httpGET(CONSTANTS.ENDPOINTS.agenda.findOne + id).pipe(
			map((res) => {
				return { event: res.body };
			}),
			flatMap((evt: any) => {
				if (!evt.event.pacienteId) {
					return of({ event: evt.event });
				}

				return this.service
					.httpGET(CONSTANTS.ENDPOINTS.patients.general.findOne + evt.event.pacienteId)
					.pipe(
						map((resPatient) => {
							return { patient: resPatient.body, event: evt.event };
						})
					);
			})
		);
	}

	public onEventResize(event: any) {
		const params = {
			eventoAgendaId: event.event.extendedProps.eventObject.id,
			dataHoraInicial: moment(event.event.start).toDate().toISOString(),
			dataHoraFinal: moment(event.event.end).toDate().toISOString(),
		};

		this.service.loading(true);
		this.service.httpPUT(CONSTANTS.ENDPOINTS.agenda.updateDateTime, {}, params).subscribe(
			(data) => {
				this.service.loading(false);

				this.service.notification.success(
					this.service.translate.instant("AGENDA.TITLE"),
					this.service.translate.instant("AGENDA.SUCCESS")
				);
				this.refreshCalendar();
			},
			(err) => {
				this.service.loading(false);
				this.service.notification.error(
					this.service.translate.instant("COMMON.ERROR.SEARCH"),
					err.error.error
				);
			}
		);
	}

	updateLabel() {
		this.dataHoraInicial = moment(this.calendar.view.activeStart)
			.add(1, "day")
			.toDate()
			.toISOString();
		this.dataHoraFinal = moment(this.calendar.view.activeEnd).toDate().toISOString();
		this.labelScheduledFormated = moment(this.dataHoraInicial).format("MMMM [|] YYYY");
	}

	setCalendarSize() {
		this.calendar && this.calendar.setOption("contentHeight", window.innerHeight - 188);
	}

	createCalendar() {
		this.fullCalendar.nativeElement.textContent = null;
		let that = this;

		this.calendar = new Calendar(this.fullCalendar.nativeElement, {
			plugins: [interaction, timeGridPlugin],
			height: "parent",
			timeZone: "America/Sao_Paulo",
			defaultView: this.view,
			header: false,
			locale: "pt-br",
			allDaySlot: false,
			scrollTime: "08:00",
			minTime: this.options.minTime,
			maxTime: this.options.maxTime,
			businessHours: this.businessHours,
			selectConstraint: "businessHours",
			slotDuration: this.options.slotDuration,
			slotLabelInterval: this.options.slotLabelInterval,
			columnHeaderHtml: function (date) {
				let dayName = that.nameOfDay(moment(date.toString()).add(1, "day").weekday());
				let dateNumber = moment(date.toString()).add(1, "day").date();
				return (
					dateNumber.toString() + '<br/> <span style="font-size: 0.7em;">' + dayName + "</span>"
				);
			},
			slotLabelFormat: {
				hour: "2-digit",
				minute: "2-digit",
				omitZeroMinute: false,
				meridiem: "short",
			},
			defaultDate: moment(this.dataHoraFinal).subtract(1, "day").toDate().toISOString(),
			dateClick: (info) => {
				if (this.currentTooltip) {
					this.hideTooltips();
				} else {
					this.clickAction(info);
				}
			},
			eventClick: (event) => {
				const eventObject = event.event.extendedProps.eventObject;
				if (eventObject.emTipoAgendamento === COMMITMENT) {
					this.eventObj = eventObject;
					this.openModalAgenda(true);
				} else {
					if (this.currentTooltip) {
						this.hideTooltips();
					} else {
						this.currentTooltip = new Tooltip(event.el, {
							title: this.schedulingTooltip.nativeElement,
							placement: "left",
							trigger: "click",
							container: "body",
							html: true,
							popperOptions: {
								onCreate(data) {
									data.instance.scheduleUpdate();
								},
							},
						});
						this.onEventClick(eventObject);
					}
				}
			},
			eventMouseEnter: (event) => {
				const eventObject = event.event.extendedProps.eventObject;
				if (eventObject.emTipoAgendamento !== COMMITMENT) {
					const localSchedule = this._buildSchedulingForTooltip({ eventObject });
					var tooltip = new Tooltip(event.el, {
						title: `<b>Paciente:</b> ${this.getLabel(
							localSchedule.schedulingForDatabase.nomePaciente ||
								localSchedule.schedulingForDatabase.pacientePessoaNome
						)}<br/>
							<b>Nº:</b> ${this.getLabel(localSchedule.pacienteCodigoProntuario)}<br/>
							<b>Profissional:</b> ${this.getLabel(localSchedule.professionalName)}<br/>
							<b>Convênio:</b> ${this.getLabel(localSchedule.convenioNome)}<br/>
							<b>Tipo:</b> ${this.getLabel(localSchedule.schedulingForDatabase.tipoAtendimentoDescricao)}<br/>
							<b>Celular:</b>  ${this.getFormatedCellPhoneLabel(localSchedule.schedulingForDatabase.celular)}<br/>
							<b>E-mail:</b>  ${this.getLabel(localSchedule.schedulingForDatabase.email)}<br/>
							<b>Horário:</b> ${this.getLocalScheduleForHuman(localSchedule)}`,
						placement: "right",
						trigger: "hover",
						container: "body",
						html: true,
						template:
							'<div class="littleTooltip tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>',
						popperOptions: {
							modifiers: {
								arrow: { enabled: true },
							},
						},
					});
					setTimeout(() => {
						let cardProps = event.el.getBoundingClientRect();
						let arrow = document.querySelectorAll<HTMLElement>(".arrow")[0];
						let arrowProps = arrow ? arrow.getBoundingClientRect() : null;
						let tltip = document.querySelectorAll<HTMLElement>(".tooltip-inner")[0];
						let tltipProps = tltip ? tltip.getBoundingClientRect() : null;
						if (arrow && tltip) {
							let moveFor =
								cardProps.top + cardProps.height / 2 - arrowProps.top - arrowProps.height / 2;
							moveFor =
								moveFor > tltipProps.height - arrowProps.height - 3
									? tltipProps.height - arrowProps.height - 3
									: moveFor;
							moveFor = moveFor < 3 ? 3 : moveFor;
							arrow.style.top = moveFor.toString() + "px";
							arrow.style.opacity = "1";
						}
					}, 10);
				}
			},
			eventMouseLeave: (event) => {
				this.hideLittleTooltips();
			},
			eventAllow: (span, movingEvent) => {
				return movingEvent.extendedProps.eventObject.emTipoAgendamento !== COMMITMENT;
			},
			eventResize: (event) => {
				this.closeTooltipsOnCalendarEvents();
				this.onEventResize(event);
			},
			eventDrop: (event) => {
				this.closeTooltipsOnCalendarEvents();
				this.onEventDrop(event);
			},
			events: this.events,
		});
		this.calendar.render();
		this.updateLabel();
		this.setCalendarSize();
		this.calendar.scrollToTime("08:00");
		this.service.loading(false);
	}

	hideTooltips() {
		this.currentTooltip = null;
		let elTltp = document.querySelectorAll<HTMLElement>(".tooltip:not(.littleTooltip)");
		for (let i = 0; i < elTltp.length; i++) {
			elTltp[i].parentNode.removeChild(elTltp[i]);
		}
	}

	hideLittleTooltips() {
		let elTltp = document.querySelectorAll<HTMLElement>(".littleTooltip");
		for (let i = 0; i < elTltp.length; i++) {
			elTltp[i].parentNode.removeChild(elTltp[i]);
		}
	}

	private nameOfDay(date: any) {
		switch (date) {
			case 0:
				return "dom";
			case 1:
				return "seg";
			case 2:
				return "ter";
			case 3:
				return "qua";
			case 4:
				return "qui";
			case 5:
				return "sex";
			case 6:
				return "sáb";
		}
	}

	private loadEventsFromBackend() {
		const delta = 1;
		this.service.loading(true);
		const params = {
			profissionalId: this.professionalId,
			dataHoraInicial: moment(this.dataHoraInicial).subtract(delta, "day").toDate().toISOString(),
			dataHoraFinal: this.dataHoraFinal,
		};

		if (!params.profissionalId) {
			delete params.profissionalId;
		}

		this.service.httpGET(CONSTANTS.ENDPOINTS.agenda.findAll, params).subscribe(
			(data) => {
				this.events = [];
				const result = data.body as any[];

				result.forEach((item) => {
					const nomes = [
						item.pacientePessoaNome || item.nomePaciente,
						this.professionalId ? "" : `${item.profissionalNome}`,
					]
						.filter((a) => a)
						.join(" \n ");

					const colors =
						item.emTipoAgendamento === COMMITMENT
							? this.cfgCalendarByStatusListMap["COMMITMENT"]
							: this.cfgCalendarByStatusListMap[item.emStatusAgendamento];
					const event: EventAgenda = {
						title: nomes,
						start: item.dataHoraInicialEvento,
						end: item.dataHoraFinalEvento,
						editable: true,
						eventDurationEditable: true,
						eventStartEditable: true,
						textColor: colors.textColor,
						backgroundColor: colors.backgroundColor,
						borderColor: colors.backgroundColor,
						eventObject: item,
					};

					this.events = [...this.events, event];
				});

				if (this.professionalId !== this.lastProfessionalId) {
					this.businessHours = [];
					this.updateSchduleOptions = this.professionalId;

					this.service
						.httpGET(
							CONSTANTS.ENDPOINTS.agenda.professionalConfig,
							this.professionalId ? { profissionalId: this.professionalId } : null
						)
						.subscribe(
							(dataConfig) => {
								const resConfig = dataConfig.body as any;
								if (resConfig && resConfig.businessHours) {
									this.businessHours = resConfig.businessHours.map((item) => ({
										daysOfWeek: [tratarDomingo(item.dow)],
										startTime: item.start,
										endTime: moment(item.end, "HH:mm")
											.add(resConfig.slotLabelInterval, "minute")
											.format("HH:mm:ss"),
									}));
								}

								this.lastProfessionalId = this.professionalId;

								this.options.minTime = moment(resConfig.minTime, "HH:mm").format("HH:mm:ss");
								this.options.maxTime = moment(resConfig.maxTime, "HH:mm")
									.add(resConfig.slotLabelInterval, "minute")
									.format("HH:mm:ss");
								this.options.slotLabelInterval = resConfig.slotLabelInterval;
								this.options.slotDuration = resConfig.slotDuration;

								if (!this.initCalendar) {
									this.createCalendar();
									this.initCalendar = true;
								} else {
									this.addEvents();
									// this.calendar.gotoDate(this.dataHoraInicial);
									this.updateLabel();
								}
							},
							(errConfig) => {
								this.service.loading(false);
								this.service.notification.error(
									this.service.translate.instant("COMMON.ERROR.SEARCH"),
									errConfig.error.error
								);
							}
						);
				} else {
					if (!this.initCalendar) {
						this.createCalendar();
					} else {
						this.service.loading(false);
						this.addEvents();

						this.updateLabel();
					}
				}
			},
			(err) => {
				this.service.loading(false);
				this.service.notification.error(
					this.service.translate.instant("COMMON.ERROR.SEARCH"),
					err.error.error
				);
			}
		);
	}

	public onSelectedProfessional(event) {
		if (this.professionalId) {
			this.service.setStorage(CONSTANTS.STORAGE.PROFESSIONAL_ID, this.professionalId);
		} else {
			this.service.setStorage(CONSTANTS.STORAGE.PROFESSIONAL_ID, null);
		}

		this.initCalendar = false;
		this.calendar.removeAllEvents();
		this.updateLabel();
		this.loadEventsFromBackend();
	}

	changeView(view: string) {
		view = view == this.service.translate.instant("AGENDA.WEEK") ? "timeGridWeek" : "timeGridDay";
		this.view = view;
		this.calendar.changeView(view);

		this.updateLabel();
	}

	nextDay() {
		this.calendar.next();
		this.dataHoraInicial = moment(this.calendar.view.activeStart)
			.subtract(1, "day")
			.toDate()
			.toISOString();
		this.dataHoraFinal = moment(this.calendar.view.activeEnd)
			.subtract(1, "day")
			.toDate()
			.toISOString();

		this.updateLabel();
		this.loadEventsFromBackend();
	}

	refreshCalendar() {
		this.updateEvents();
	}

	prevDay() {
		this.calendar.prev();
		this.dataHoraInicial = moment(this.calendar.view.activeStart)
			.subtract(1, "day")
			.toDate()
			.toISOString();
		this.dataHoraFinal = moment(this.calendar.view.activeEnd)
			.subtract(1, "day")
			.toDate()
			.toISOString();

		this.updateLabel();
		this.loadEventsFromBackend();
	}

	today() {
		this.calendar.today();
		this.updateLabel();
		this.updateEvents();
	}

	onSelectDate(event: NgbDate) {
		const date =
			event.year + "-" + ("0" + event.month).slice(-2) + "-" + ("0" + event.day).slice(-2);

		if (this.view !== "timeGridDay") {
			this.dataHoraInicial = moment(date).startOf("week").toDate().toISOString();
			this.dataHoraFinal = moment(date).endOf("week").toDate().toISOString();
		} else {
			this.dataHoraInicial = moment(date).toDate().toISOString();
			this.dataHoraFinal = moment(date).add(1, "day").toDate().toISOString();
		}

		this.calendar.gotoDate(this.dataHoraInicial);

		this.loadEventsFromBackend();
	}

	toggleVisualization(option: string) {
		if (this.option !== option) {
			this.option = option;
			if (this.option === "calendar") {
				this.refreshCalendar();
			}
		}
	}

	print() {
		this.showModalPrintAgenda = true;
		if (this.option === "calendar") {
			this.initDate = this.service.parseDatePicker(
				this.dataHoraInicial.split("T")[0],
				"YYYY-MM-DD"
			);
			this.endDate = this.service.parseDatePicker(this.dataHoraFinal.split("T")[0], "YYYY-MM-DD");
		}

		setTimeout(() => {
			document.querySelector("#modal-print-agenda").classList.add("md-show");
		}, 500);
	}

	private _buildSchedulingForTooltip(data: any): SchedulingForTooltip {
		const eventObject = data.eventObject;
		return {
			patientId: eventObject.pacienteId || eventObject.id,
			pacienteCodigoProntuario: eventObject.pacienteCodigoProntuario,
			convenioNome: eventObject.convenioNome,
			schedulingForDatabase: eventObject,
			professionalName: eventObject.profissionalNome,
		};
	}

	private _fillTooltipOnClick(currentScheduling: SchedulingForTooltip): void {
		setTimeout(() => {
			this.currentScheduling = currentScheduling;
			this.currentTooltip.show();
			this._setTooltipStyles();
		});
	}

	getLabel(label: string): string {
		return isEmpty(label) ? NO_LABEL : label;
	}

	getFormatedCellPhoneLabel(cellphone: string) {
		let i = 0;

		return isEmpty(cellphone)
			? NO_LABEL
			: cellphone.length === FULL_CELLPHONE_LENGTH
			? FULL_CELLPHONE_NUMBER_MASK.replace(/0/g, (_) => cellphone[i++])
			: cellphone;
	}

	deleteScheduling(): void {
		if (this.currentScheduling) {
			this._removeEvent();
		}
	}

	updateSchedulingStatus(): void {
		if (this.currentScheduling) {
			this.getModalInfo(false);
		}
	}

	private async _saveEventStatus() {
		try {
			this.service.loading(true);
			const aux = { ...this.eventObj };
			if (aux.dataHoraInicialEvento.length === 16) {
				aux.dataHoraInicialEvento += ":00";
			}
			await this.service.httpPOST(CONSTANTS.ENDPOINTS.agenda.save, aux).toPromise();
			this.updateEvents();
			this.service.notification.success("Status alterado com sucesso");
		} catch (err) {
			this.service.notification.error(
				this.service.translate.instant("COMMON.ERROR.SEARCH"),
				err.error.error
			);
		} finally {
			this.service.loading(false);
		}
	}

	removeTooltips() {
		const tooltips = document.getElementsByClassName("tooltip");
		for (const tooltip of tooltips as any) {
			tooltip.remove();
		}
	}

	closeTooltipsOnCalendarEvents() {
		if (this.currentTooltip && this.currentTooltip._isOpen) {
			// Por algum motivo  não funciona aqui
			this.removeTooltips();
			this.currentTooltip = null;
		}
	}

	private async _removeEvent() {
		const translate = this.service.translate;
		const refSWAL = "AGENDA.SWAL";

		const { value } = await Swal.fire({
			title: translate.instant(`${refSWAL}.TITLE`),
			text: translate.instant(`${refSWAL}.TEXT_DELETE`),
			type: "question",
			showCancelButton: true,
			confirmButtonText: translate.instant(`${refSWAL}.CONFIRM_BUTTON_DELTE`),
			cancelButtonText: translate.instant(`${refSWAL}.CANCEL_BUTTON`),
		});

		if (!value) {
			return;
		}

		try {
			this.service.loading(true);
			await this.service
				.httpDELETE(CONSTANTS.ENDPOINTS.agenda.delete + this.eventObj.id)
				.toPromise();
			this.service.notification.success(
				this.service.translate.instant("AGENDA.TITLE"),
				this.service.translate.instant("AGENDA.SUCCESS_DELETE")
			);
			this.updateEvents();
		} catch (e) {
			this.service.notification.error(
				this.service.translate.instant("COMMON.ERROR.SEARCH"),
				e.error.error
			);
		} finally {
			this.service.loading(false);
		}
	}

	// Setando estilo via jQuery não deixa que afete outros lugares, via css afeta
	private _setTooltipStyles() {
		const $tooltipInner = $(".tooltip-inner");
		const $tooltip = $(".tooltip");
		$tooltipInner.css("background-color", "transparent");
		$tooltipInner.css("max-width", "400px");
		$tooltip.css("z-index", "0");
		$tooltip.css("opacity", "1");
	}

	// Setando estilo via jQuery não deixa que afete outros lugares, via css afeta
	_setDropDownStyles() {
		const $ul = $(".actions-row ul");
		$(".actions-row .dropdown-menu").css("top", "auto");
		$ul.css("bottom", "100%");
		$ul.css("width", "200px");
	}

	goToWhatsappMessage() {
		if (!this.currentScheduling) {
			return;
		} else if (
			!this.currentScheduling.schedulingForDatabase.celular ||
			!this.currentScheduling.schedulingForDatabase.celular.match(/\d+/) ||
			this.currentScheduling.schedulingForDatabase.celular.match(/\d+/)[0].length !==
				FULL_CELLPHONE_LENGTH
		) {
			this._notificationsService.alert(
				"O paciente não possui um número de telefone válido! Deve ter 11 dígitos."
			);
		}

		const greeting = this.getHumanGreetingAccordingToHour();
		const { pacientePessoaNome, profissionalNome } = this.currentScheduling.schedulingForDatabase;
		const celular = this.currentScheduling.schedulingForDatabase.celular.match(/\d+/)[0];
		const schedulingDate = this.currentScheduling.schedulingForDatabase.dataHoraInicialEvento;
		const nomePaciente = this.currentScheduling.schedulingForDatabase.nomePaciente;
		const date = this._getBrazilianFormatDateFromJsonDate(schedulingDate);
		const time = this._getFourDigitsHourFromJsonDate(schedulingDate);
		this.hideTooltips();
		const whatsappLink = buildWhatsappMessage(
			celular,
			greeting,
			pacientePessoaNome != null ? pacientePessoaNome : nomePaciente,
			date,
			time,
			profissionalNome
		);
		//const whatsappLink = buildWhatsappMessage(celular, greeting, pacientePessoaNome, date, time, profissionalNome);

		// Usar window.open() pode não funcionar de acordo com browser e/ou configuração do usuário
		$("<a />", { href: whatsappLink, target: "_blank" }).get(0).click();
	}

	// Retorna saudação de acordo se está de manhã, tarde ou noite
	getHumanGreetingAccordingToHour(): string {
		const hours = new Date().getHours();

		if (hours < 11) {
			return "Bom dia";
		} else if (hours <= 18) {
			return "Boa tarde";
		}

		return "Boa noite";
	}

	getScheduleForHuman() {
		if (!this.currentScheduling) {
			return;
		}

		const {
			dataHoraInicialEvento,
			dataHoraFinalEvento,
		} = this.currentScheduling.schedulingForDatabase;
		const initialHour = this._getFourDigitsHourFromJsonDate(dataHoraInicialEvento);
		const finalHour = this._getFourDigitsHourFromJsonDate(dataHoraFinalEvento);

		return `${initialHour} até ${finalHour}`;
	}

	getLocalScheduleForHuman(schedule: any) {
		if (!schedule) {
			return;
		}

		const { dataHoraInicialEvento, dataHoraFinalEvento } = schedule.schedulingForDatabase;
		const initialHour = this._getFourDigitsHourFromJsonDate(dataHoraInicialEvento);
		const finalHour = this._getFourDigitsHourFromJsonDate(dataHoraFinalEvento);

		return `${initialHour} até ${finalHour}`;
	}

	private _getBrazilianFormatDateFromJsonDate(json: string) {
		return json.match(BRAZILIAN_FORMAT_DATE)[0].split("-").reverse().join("/");
	}

	private _getFourDigitsHourFromJsonDate(json: string): string {
		return json.match(FOUR_DIGITS_HOUR_REGEX)[0];
	}

	goToPatientRecord(patientId: number): void {
		if (!patientId) {
			this._notificationsService.info("Este paciente ainda não possui cadastro.");
			return;
		}

		this._router.navigate(PATIENT_RECORD_LINK(patientId));
	}

	get expiredReturnAlerts() {
		if (!this.returnAlerts) {
			return false;
		}

		const expired = this.returnAlerts.filter(
			(alert: ReturnAlert) => alert.status === ReturnAlertStatus.ABERTO
		);

		return expired && expired.length > 0;
	}
}
