import { SelectItem, SelectItemStatus } from '../../core/models/forms/common/common.model';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable()
export class LISTS {

  listStatusAgenda: SelectItemStatus[];

  constructor(translate: TranslateService) {
    this.listStatusAgenda = [
      {
        label: translate.instant('AGENDA.STATUS.MARCADO'),
        value: 'MARCADO',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.REMARCADO'),
        value: 'REMARCADO',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.AGUARDANDO'),
        value: 'AGUARDANDO',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.CONFIRMADO'),
        value: 'CONFIRMADO',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.ATENDIDO'),
        value: 'ATENDIDO',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.EM_ATENDIMENTO'),
        value: 'EM_ATENDIMENTO',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.FALTOU'),
        value: 'FALTOU',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.PACIENTE_DESMARCOU'),
        value: 'PACIENTE_DESMARCOU',
        color: '#c9ac09'
      },
      {
        label: translate.instant('AGENDA.STATUS.PROFISSIONAL_DESMARCOU'),
        value: 'PROFISSIONAL_DESMARCOU',
        color: '#c9ac09'
      }
    ];
  }

}
