import { Inject, Injectable } from '@angular/core';
import { API_CONFIGURATION, ApiConfiguration } from './api-configuration';
import { HttpClient } from '@angular/common/http';
import { ContaUserModel, UserModel } from '../core/models/user/user.model';

export type MudancasUsuario =
    | { nome: string }
    | { nome: string, senha: string, confirmacaoSenha: string };

export interface UserInfo {
    id: number;
    dataCadastro: string;
    email: string;
    nome: string;
    statusAtivo: boolean;
}

@Injectable({
                providedIn: 'root',
            })
export class LoginApiService {
    constructor(
        @Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
        private readonly _http: HttpClient,
    ) {
    }

    loginUser(usuario: string, senha: string): Promise<UserModel> {
        return this._http.get<UserModel>(`${ this._config.openBasePath }/usuario/login`, {
            params: {
                login: usuario,
                senha: senha,
            },
        }).toPromise();
    }

    encontraContas(): Promise<ContaUserModel[]> {
        return this._http
            .get<ContaUserModel[]>(`${ this._config.apiBasePath }/usuarioPerfilContaModulo/usuario/all`)
            .toPromise();
    }

    atualizarUsuario(mudancas: MudancasUsuario) {
        const { nome } = mudancas;
        const { senha = undefined, confirmacaoSenha = undefined } = 'senha' in mudancas ? mudancas : {};

        if (senha && senha !== confirmacaoSenha) {
            throw Error('Senhas não batem');
        }

        return this._http
            .put<void>(`${ this._config.apiBasePath }/usuario/alterar`, null, {
                params: {
                    nome,
                    ...senha && { senha }, // Previne que o vá um parametro senha vazio
                },
            })
            .toPromise();
    }

    fetchUsuarioInfo() {
        return this._http
            .get<UserInfo>(`${ this._config.apiBasePath }/usuario/info`)
            .toPromise();
    }

}
