const defaultMessage = 'Erro desconhecido do sistema';

export const errorToString = (e: any): string => {
  if (e) {
    if (e.error && typeof e.error.message === 'string') {
      return e.error.message;
    }

    if (typeof e.message === 'string') return e.message;
  }

  return defaultMessage;
};
