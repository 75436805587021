import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';

const TIME_MASK = '00:00';
const DATE_MASK = '00/00/0000';
const DATE_TIME_LENGHT = 16;
const DATE_LENGHT = 10;

@Component({
	selector: 'app-commitment-datetime-picker',
	templateUrl: './commitment-datetime-picker.component.html',
	styleUrls: ['./commitment-datetime-picker.component.scss'],
})
export class CommitmentDatetimePickerComponent implements OnInit, OnDestroy {

	@ViewChild('htmlDivElement') htmlDivElement?: Object;

	@Input() id: string;
	@Input() top: number = -50;
	@Input() dateTime: string;
	@Input() defaultTime: NgbTimeStruct;
	@Input() allDayObservable: Observable<boolean>;
	@Output() emitDateTime = new EventEmitter<Date>();

	isFocusInsideComponent = false;
	isComponentClicked = false;

	@HostListener('click')
	clickInside() {
		this.isFocusInsideComponent = true;
		this.isComponentClicked = true;
	}

	@HostListener('document:click')
	clickout() {
		if (!this.isFocusInsideComponent && this.isComponentClicked) {
			this.isComponentClicked = false;
		}
		this.isFocusInsideComponent = false;
	}

	private _subscriptions = new Subscription();
	dateTimeMask = DATE_MASK + ' ' + TIME_MASK;
	showPicker = false;
	dateTimeLength = DATE_TIME_LENGHT;

	date: NgbDateStruct;
	time: NgbTimeStruct;

	constructor() { }

	ngOnInit() {
		this._allDaySubscription();
		this._fillStructs();
	}

	ngOnDestroy() {
		this._subscriptions.unsubscribe();
	}

	togglePicker() {
		this.showPicker = !this.showPicker;
		if (this.showPicker) {
			this.htmlDivElement['nativeElement'].style.top = this.top + 'px';
		}
	}

	private _fillStructs() {
		if (!this.dateTime) {
			return;
		}

		const momentDate = moment(this._formatteDateForMomentCanParse());
		this.date = { year: momentDate.year(), month: momentDate.month() + 1, day: momentDate.date() };
		this.time = { minute: momentDate.minute(), hour: momentDate.hour(), second: momentDate.second() };

		this.applyDateTime();
	}

	changeDatetimeManually() {
		if (this.incompletedDateTimeLength) {
			return;
		}

		const splittedDateTime = this.dateTime.split(' ');
		const date = splittedDateTime[0].split('/');
		this.date = { day: +date[0], month: +date[1], year: +date[2] };

		if (this.dateTime.length === DATE_TIME_LENGHT) {
			const time = splittedDateTime[1].split(':');
			this.time = { hour: +time[0], minute: +time[1], second: 0 };
		}

		this.applyDateTime();
	}

	applyDateTime() {
		if (!this.date || (!this.defaultTime && !this.time)) {
			return;
		}

		const { day, month, year } = this.date;
		const { hour, minute } = this.defaultTime || this.time;

		this.dateTime = CommitmentDatetimePickerComponent._formatTimeFields(day) + '/' +
				CommitmentDatetimePickerComponent._formatTimeFields(month) + '/' + year + ' ' +
				CommitmentDatetimePickerComponent._formatTimeFields(hour) + ':' + CommitmentDatetimePickerComponent._formatTimeFields(minute);

		this._emit();
	}

	private _emit(): void {
		if (this.incompletedDateTimeLength) {
			return;
		}
		this.emitDateTime.emit(moment(this._formatteDateForMomentCanParse()).toDate());
	}

	get incompletedDateTimeLength(): boolean {
		return this.dateTime.length < this.dateTimeLength;
	}

	private _formatteDateForMomentCanParse(): string {
		const splitted = this.dateTime.split(' ');
		return splitted[0].split('/').reverse().join('-') + ' ' + splitted[1];
	}

	private _allDaySubscription() {
		const sub = this.allDayObservable.subscribe(value => {
			this.dateTimeLength = value ? DATE_LENGHT : DATE_TIME_LENGHT;
			this.dateTimeMask = value ? DATE_MASK : DATE_MASK + ' ' + TIME_MASK;
			this.applyDateTime();
		})

		this._subscriptions.add(sub);
	}

	private static _formatTimeFields(field: number): string {
		return ('0' + field).slice(-2);
	}

}
