import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToggleFullScreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { AccordionAnchorDirective } from './accordion/accordionanchor.directive';
import { AccordionLinkDirective } from './accordion/accordionlink.directive';
import { AccordionDirective } from './accordion/accordion.directive';
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TitleComponent } from '../layout/admin/title/title.component';
import { CardComponent } from './card/card.component';
import { CardToggleDirective } from './card/card-toggle.directive';
import { ModalBasicComponent } from './modal-basic/modal-basic.component';
import { ModalAnimationComponent } from './modal-animation/modal-animation.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';
import { NgxMaskModule } from 'ngx-mask';
import { CommitmentDatetimePickerComponent } from '../pages/agenda/agendamento/commitment/datetime-picker/commitment-datetime-picker.component';
import { ModalWithOptionsComponent } from './modal-with-options/modal-with-options.component';
import { InputMaskModule } from 'racoon-mask-raw';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AlertComponent } from './alert/alert.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
	imports: [
		NgbModule.forRoot(),
		PerfectScrollbarModule,
		ClickOutsideModule,
		TranslateModule,
		FormsModule,
		NgxSelectModule,
		NgxMaskModule,
		InputMaskModule,
	],
              exports: [
                  NgbModule,
                  ToggleFullScreenDirective,
                  AccordionAnchorDirective,
                  AccordionLinkDirective,
                  AccordionDirective,
                  CardToggleDirective,
                  PerfectScrollbarModule,
                  TitleComponent,
                  CardComponent,
                  ModalBasicComponent,
                  ModalAnimationComponent,
                  SpinnerComponent,
                  ClickOutsideModule,
                  CommitmentDatetimePickerComponent,
                  ModalWithOptionsComponent,
                  LoadingSpinnerComponent,
                  AlertComponent,
              ],
  declarations: [
    ToggleFullScreenDirective,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    CardToggleDirective,
    TitleComponent,
    CardComponent,
    ModalBasicComponent,
    ModalAnimationComponent,
    SpinnerComponent,
    PaymentReceiptComponent,
    CommitmentDatetimePickerComponent,
    ModalWithOptionsComponent,
    LoadingSpinnerComponent,
    AlertComponent
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  entryComponents: [PaymentReceiptComponent, ModalWithOptionsComponent],
})
export class SharedModule { }
