import { Injectable } from '@angular/core';
import { LoginApiService } from '../api/login-api.service';
import { UtilsService } from '../services/utils/utils.service';
import { UserModel, UsuarioPerfilContaModuloSelecionado } from '../core/models/user/user.model';
import { ContaApiService } from '../api/conta-api.service';
import { Subject } from 'rxjs';

const USER_STORAGE_KEY = 'authToken';

@Injectable({
                providedIn: 'root',
            })
export class LoginService {

    readonly contaChange$ = new Subject<void>();

    constructor(
        private readonly _loginApiService: LoginApiService,
        private readonly _contaApiService: ContaApiService,
        private readonly _utilsService: UtilsService,
    ) {
    }

    async login(usuario: string, senha: string) {
        const user = await this._loginApiService.loginUser(usuario, senha);

        this._legacyStateSynchronization(user);
    }

    logAsUser(user: UserModel): void {
        this._legacyStateSynchronization(user);
    }

    get currentUser(): UserModel | undefined {
        const { user } = this._utilsService;

        // Guarda para valor legado de objeto vazio
        return Object.keys(user || {}).length === 0 ? undefined : user;
    }

    get currentConta(): UsuarioPerfilContaModuloSelecionado | undefined {
        const user = this.currentUser;
        return (user && user.usuarioPerfilContaModuloSelecionado) || undefined;
    }

    async escolherConta(contaId: number): Promise<boolean> {
        const { user } = this._utilsService;

        if (user) {
            const conta = await this._contaApiService.escolherContaSessao(
                contaId,
            );

            user.usuarioPerfilContaModuloSelecionado = conta;
            this._utilsService.setStorage('accountId', conta.id);
            this._utilsService.setStorage('user', user);

            this.contaChange$.next();

            return true;
        }

        return false;
    }

    async reloadConta(): Promise<void> {
        const { currentConta } = this;

        if (!currentConta) {
            return;
        }

        this.escolherConta(currentConta.id);
    }

    private _legacyStateSynchronization(user: UserModel) {
        this._utilsService.user = user;
        this._utilsService.setStorage(USER_STORAGE_KEY, user.token);
    }

}
