import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { LocalStorageService } from "angular-2-local-storage";
import { Router } from "@angular/router";
import { FormsModel } from "../../core/models/forms/forms.model";
import { FormBuilder, FormGroup } from "@angular/forms";
import { UserModel } from "../../core/models/user/user.model";
import * as moment from "moment";
import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Subject } from "rxjs";
import { Location } from "@angular/common";
import { ROUTERS } from "../../core/constants/routers/routers";
import { AutoSizedDatatableService } from "../../lib/auto-sized-datatable/auto-sized-datatable.service";

/**
 * @deprecated quebrar esse serviço em outros
 */
@Injectable({
	providedIn: "root",
})
export class UtilsService {
	loadingTxt = "";

	/**
	 * @deprecated
	 */
	user = {} as UserModel;

	modalResult = new BehaviorSubject<any>(null);
	toggleMenu = new BehaviorSubject<boolean>(false);
	readonly modalClosed = new Subject<[string, unknown]>();

	public options: any = {
		position: ["bottom", "right"],
		maxStack: 8,
		timeOut: 5000,
		showProgressBar: true,
		pauseOnHover: true,
		lastOnBottom: true,
		clickToClose: true,
		preventDuplicates: false,
		preventLastDuplicates: false,
		theClass: "bg-c-pink",
		rtl: false,
		animate: "scale",
	};

	perPage = [
		{
			field: `10 ${this.translate.instant("COMMON.PER_PAGE")}`,
			value: 10,
		},
		{
			field: `20 ${this.translate.instant("COMMON.PER_PAGE")}`,
			value: 20,
		},
		{
			field: `30 ${this.translate.instant("COMMON.PER_PAGE")}`,
			value: 30,
		},
		{
			field: `40 ${this.translate.instant("COMMON.PER_PAGE")}`,
			value: 40,
		},
		{
			field: `50 ${this.translate.instant("COMMON.PER_PAGE")}`,
			value: 50,
		},
		{
			field: `100 ${this.translate.instant("COMMON.PER_PAGE")}`,
			value: 100,
		},
	];

	constructor(
		private http: HttpClient,
		public translate: TranslateService,
		public notification: NotificationsService,
		private storage: LocalStorageService,
		private router: Router,
		private fb: FormBuilder,
		private location: Location,
		private readonly _autoSizedDatatableService: AutoSizedDatatableService
	) {
		this.user = this.getStorage("user") as UserModel;
		if (!this.user) {
			this.navigate(ROUTERS.LOGIN, null);
		}

		this._subscribeTableRecalculationsToMenuToggle();
	}

	private _subscribeTableRecalculationsToMenuToggle() {
		this.toggleMenu.subscribe((toggled) => {
			if (toggled) {
				this._autoSizedDatatableService.recalculate.next();
			}
		});
	}

	loading(event: boolean, txt?: string) {
		const loader = document.querySelector("#loading");

		if (!loader) {
			return;
		}

		if (event) {
			if (txt) {
				this.loadingTxt = txt;
			} else {
				this.loadingTxt = this.translate.instant("COMMON.LOADING");
			}

			loader.classList.add("md-show");
		} else {
			loader.classList.remove("md-show");
		}
	}

	httpGET(endpoint: string, params = null) {
		if (params) {
			return this.http.get(endpoint, { params, observe: "response" });
		} else {
			return this.http.get(endpoint, { observe: "response" });
		}
	}

	httpDELETE(endpoint: string) {
		return this.http.delete(endpoint, { observe: "response" });
	}

	httpPOST(endpoint: string, body) {
		return this.http.post(endpoint, body, { observe: "response" });
	}

	httpPUT(endpoint: string, body, params = null) {
		if (params) {
			return this.http.put(endpoint, body, { params, observe: "response" });
		} else {
			return this.http.put(endpoint, body, { observe: "response" });
		}
	}

	setStorage(key: string, data) {
		this.storage.set(key, data);
	}

	getStorage(key: string) {
		return this.storage.get(key);
	}

	removeStorage(key: string) {
		this.storage.remove(key);
	}

	navigate(route: string, param) {
		this.router.navigate([`/${route}`, param ? param : {}]);
	}

	aplicaCssErro(campo, formulario, group = null, index = -1) {
		return {
			"has-error": this.verificaValidTouched(campo, formulario, group, index),
		};
	}

	verificaValidTouched(campo, formulario, group = null, index = -1) {
		if (group) {
			const parts = group.split(".");
			let control = formulario;
			parts.forEach((part) => {
				control = control.get(part);
			});

			if (index >= 0) {
				return !control.controls[index].get(campo).valid;
			} else {
				return !control.get(campo).valid;
			}
		} else {
			return !formulario.get(campo).valid && formulario.get(campo).touched;
		}
	}

	doOpenSelect() {
		const choices = document.getElementsByClassName("ngx-select__choices") as any;
		for (const choice of choices) {
			choice.setAttribute("style", "overflow-y:scroll");
		}

		const items = document.getElementsByClassName("ngx-select__item") as any;
		for (const item of items) {
			let active = false;
			for (const inner of item.classList) {
				if (inner === "ngx-select__item_active") {
					active = true;
				}
			}

			if (!active) {
				item.children[0].setAttribute("style", "white-space:pre-line; color:dimgray");
			} else {
				item.children[0].setAttribute("style", "white-space:pre-line");
			}
		}
	}

	parseDatePicker(date: string, format = "DD/MM/YYYY") {
		const momentDate = moment(date, format);
		const ngbDate: NgbDateStruct = {
			year: momentDate.year(),
			month: momentDate.month() + 1,
			day: momentDate.date(),
		};
		return ngbDate;
	}

	parseDateFromDatePicker(date, format = "DD/MM/YYYY") {
		const temp = `${date.year}-${("0" + date.month).slice(-2)}-${("0" + date.day).slice(-2)}`;
		return moment(temp).format(format);
	}

	createControl(obj, key: string, formModel: FormsModel[]) {
		const value = obj[key] ? obj[key] : null;
		let control = null;

		for (const item of formModel) {
			if (item.field === key) {
				control = this.fb.control(value, item.validators);
			}
		}

		return { control, value };
	}

	/**
	 * @deprecated evitar usar esse método, contruir os formulários em seus próprios seviços/componentes
	 */
	createForm(formModel: FormsModel[], obj) {
		const form: FormGroup = this.fb.group({});

		for (const key of Object.keys(obj)) {
			if (obj[key] && typeof obj[key] === "object") {
				const keys = Object.keys(obj[key]);

				const indexYear = keys.findIndex((item) => item === "year");
				const indexMonth = keys.findIndex((item) => item === "month");
				const indexDay = keys.findIndex((item) => item === "day");

				if (indexYear >= 0 && indexMonth >= 0 && indexDay >= 0) {
					// date structure
					const { control, value } = this.createControl(obj, key, formModel);
					form.addControl(key, control ? control : this.fb.control(value));
				} else {
					if (obj[key].length >= 0) {
						const arrayControl = this.fb.array([]);
						let index = 0;
						for (const rowSerie of obj[key]) {
							const keysSerie = Object.keys(rowSerie);
							const innerGroup = this.fb.group({});

							const group = formModel.find((item) => item.field === key)
								? formModel.find((item) => item.field === key).group
								: [];

							for (const keyGroup of keysSerie) {
								const { control, value } = this.createControl(rowSerie, keyGroup, group);

								innerGroup.addControl(keyGroup, control ? control : this.fb.control(value));
							}

							arrayControl.setControl(index, innerGroup);
							index++;
						}

						form.addControl(key, arrayControl);
					} else {
						const innerGroup = this.fb.group({});

						for (const keyGroup of keys) {
							const group = formModel.find((item) => item.field === key)
								? formModel.find((item) => item.field === key).group
								: [];
							const { control, value } = this.createControl(obj[key], keyGroup, group);

							innerGroup.addControl(keyGroup, control ? control : this.fb.control(value));
						}

						form.addControl(key, innerGroup);
					}
				}
			} else {
				// form control
				const { control, value } = this.createControl(obj, key, formModel);
				form.addControl(key, control ? control : this.fb.control(value));
			}
		}

		return form;
	}

	parseFormToObject(form: FormGroup, object) {
		const keys = Object.keys(object);
		const values = form.value;
		keys.map((key) => {
			if (values[key]) {
				object[key] = values[key];
			}
		});
	}

	/**
	 * @deprecated fechar o modal a partir da instância do modal
	 */
	closeModal(modal: string, result) {
		this.modalResult.next(result);
		document.querySelector(`#${modal}`).classList.remove("md-show");
		this.modalClosed.next([modal, result]);
	}

	back() {
		this.location.back();
	}

	checkPermission(ids: number | number[], children = false) {
		return (!Array.isArray(ids) ? [ids] : ids).some((id) => this._checkPermission(id, children));
	}

	_checkPermission(id: number, children = false) {
		if (id === -1) {
			return true;
		}

		if (
			!this.user ||
			!this.user.usuarioPerfilContaModuloSelecionado ||
			!this.user.usuarioPerfilContaModuloSelecionado.listaModuloTelaAcaoDTOSelecionado
		) {
			return false;
		}

		return this.user.usuarioPerfilContaModuloSelecionado.listaModuloTelaAcaoDTOSelecionado.some(
			(item) => (children ? item.moduloTelaAcaoId : item.moduloTelaId) === id
		);
	}
}
