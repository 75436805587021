import { Injectable } from '@angular/core';
import { MOBI_DENTAL_PATH, PrintConfigurationsService } from '../../../configs/medical-record/print/service/print-configurations.service';
import { PrintConfiguration } from '../../../configs/medical-record/print/model/print-configuration';
import { PDFMake } from '../../../../services/pdf-generator/pdf-generator.service';
import { LogoPosition } from '../../../configs/medical-record/print/model/enum/logo-position';
import { convertBlobToBase64 } from '../../../../services/utils/file-utils.service';
import { VisualizeIssuableDocumentComponent } from '../visualize-issuable-document/visualize-issuable-document.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { cloneDeep } from 'lodash';

export const MIME_TYPE_FOR_PDF = { type: 'application/pdf' };

@Injectable({
	providedIn: 'root',
})
export class CommonPrintService {

	constructor(private readonly _printConfigurationsService: PrintConfigurationsService,
				private readonly _modalService: NgbModal,
				private readonly _sanitizer: DomSanitizer) { }

	async getHeader(printConfiguration: PrintConfiguration, html: string): Promise<string> {
		if (printConfiguration.mostraCabecalho) {
			let align = 'center';
			switch (printConfiguration.posicaoLogomarcaCabelho) {
				case LogoPosition.ESQUERDA:
					align = 'left';
					break;
				case LogoPosition.DIREITA:
					align = 'right';
					break;
				default:
					align = 'center';
					break;
			}

			html = `<img style="margin-bottom: 20px; text-align: ${align}" width="80" src="${await this._getImageForHeader()}">` + html;
		}

		return html;
	}

	getFooter(printConfiguration: PrintConfiguration, pdfMake: PDFMake, extraFooter: any[] = []): PDFMake {
		if (printConfiguration.mostraRodape) {
			const { rodapeLinha1, rodapeLinha2, rodapeLinha3 } = this._printConfigurationsService.getRodapePlaceholder();
			pdfMake.footer = ((currentPage, pageCount) => {
				if (currentPage == pageCount) {
					extraFooter.push({
						type: 'none',
						ol: [
							{ text: this._getSecondIfFirstEmptyOrBlank(printConfiguration.rodapeLinha1, rodapeLinha1), margin: [0, 0, 0, 5] },
							{ text: this._getSecondIfFirstEmptyOrBlank(printConfiguration.rodapeLinha2, rodapeLinha2), margin: [0, 0, 0, 5] },
							{ text: this._getSecondIfFirstEmptyOrBlank(printConfiguration.rodapeLinha3, rodapeLinha3), margin: [0, 0, 0, 5] },
						],
						style: 'footer',
					});
					return extraFooter;
				}
			});
			pdfMake.styles = {
				footer: {
					fontSize: 9,
					alignment: 'right',
					margin: [0, 0, 20, 50],
				},
			};
		}

		return pdfMake;
	}

	async visualizeIssuableDocument(pdfMakeDefinition) {
		const modalRef = this._modalService.open(VisualizeIssuableDocumentComponent, { size: 'xl' as 'lg' });
		modalRef.componentInstance.pdfUrl = await this._getDataUrlFromPdfMakeDefinition(pdfMakeDefinition);
	}

	async returnFileFromPdfMakeDefinition(pdfMakeDefinition, fileName) {
		const blob: Blob = await this._getBlobFromPdfMakeDefinition(cloneDeep(pdfMakeDefinition));
		return new File([blob], fileName, MIME_TYPE_FOR_PDF);
	}

	private async _getImageForHeader() {
		let base64Url: any = MOBI_DENTAL_PATH;
		try {
			const blob = await this._printConfigurationsService.getLogoBlob();
			base64Url = await convertBlobToBase64(blob);
		} catch (e) {

		}

		return base64Url;
	}

	private _getBlobFromPdfMakeDefinition(pdfMakeDefinition): Promise<Blob> {
		return new Promise<Blob>(resolve => {
			pdfMakeDefinition.getBlob((blob: Blob) => {
				resolve(blob);
			})
		});
	}

	private _getDataUrlFromPdfMakeDefinition(pdfMakeDefinition): Promise<SafeResourceUrl> {
		return new Promise<SafeResourceUrl>(resolve => {
			pdfMakeDefinition.getDataUrl(dataUrl => resolve(this._sanitizer.bypassSecurityTrustResourceUrl(dataUrl)));
		})
	}

	private _getSecondIfFirstEmptyOrBlank(field: string, second: string): string {
		return !field || !field.trim() ? second : field;
	}
}
