import { Component, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { UtilsService } from "../../services/utils/utils.service";
import { ModalRef } from "./modal-ref";

let idCounter = 0;

@Component({
	selector: "app-modal-animation",
	templateUrl: "./modal-animation.component.html",
	styleUrls: ["./modal-animation.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ModalAnimationComponent implements OnDestroy, ModalRef {
	private _subs = new Subscription();

	@Input() modalClass: string = "md-effect-11";
	@Input() contentClass: string;
	@Input() modalID: string = "modal." + idCounter++;
	@Input() backDrop = false;
	@Input() destroyOnClosed = false;

	@Output() closed = new Subject<unknown>();

	constructor(private readonly _utilsService: UtilsService) {
		this._subToServiceClose();
	}

	ngOnDestroy(): void {
		this._subs.unsubscribe();
	}

	get showing() {
		const el = document.getElementById(this.modalID);
		return !!el && el.classList.contains("md-show");
	}

	close(data?: unknown) {
		const modalEl = this._modalElement();

		if (modalEl) {
			modalEl.classList.remove("md-show");
		}

		this.closed.next(data);
	}

	open() {
		const modalEl = this._modalElement();
		if (modalEl) {
			modalEl.classList.add("md-show");
		}
	}

	private _modalElement() {
		return document.getElementById(this.modalID);
	}

	private _subToServiceClose() {
		const sub = this._utilsService.modalClosed.subscribe(([id, value]) => {
			if (this.modalID === id) {
				this.close(value);
			}
		});

		this._subs.add(sub);
	}
}
