export interface DadosContrato {
	id?: number;
	statusContrato?: StatusContrato;
	mensagemStatusContrato?: string;
}

export enum StatusContrato {
	INATIVO = "INATIVO",
	ATIVO = "ATIVO",
	PAGAMENTO_ATRASADO = "PAGAMENTO_ATRASADO",
	BLOQUEADO = "BLOQUEADO",
}

const STATUS_CONTRATO_DESCRIPTION: { [key in StatusContrato]: string } = {
	INATIVO: "Inativo",
	ATIVO: "Ativo",
	PAGAMENTO_ATRASADO: "Pagamento atrasado",
	BLOQUEADO: "Bloqueado",
};
