import { Inject, Injectable } from '@angular/core';
import { API_CONFIGURATION, ApiConfiguration } from '../../../../../api/api-configuration';
import { HttpClient } from '@angular/common/http';
import { PrintConfiguration } from '../model/print-configuration';
import { ContaApiService, ContaModel } from '../../../../../api/conta-api.service';
import { LoginService } from '../../../../../auth/login.service';
import { MaskFormatService } from '../../../../../services/utils/mask-format.service';

const BASE_PATH = 'configuracaoImpressao';
export const MOBI_DENTAL_PATH = 'assets/images/mobidental-logo.jpeg';

@Injectable({
	providedIn: 'root',
})
export class PrintConfigurationsService {

	private contaModel: ContaModel;

	constructor(
			@Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
			private readonly _http: HttpClient,
			private readonly _loginService: LoginService,
			private readonly _maskFormatService: MaskFormatService,
			private readonly _contaApiService: ContaApiService,
	) {
		this._initConta();
	}

	getContaModel(): ContaModel {
		return this.contaModel;
	}

	getRodapePlaceholder() {
		let rodapeLinha1 = this.contaModel.endereco +
				(this.contaModel.numero ? ' N-' + this.contaModel.numero : this.contaModel.complemento) +
				' ' + this.contaModel.bairro;
		let rodapeLinha2 = 'CEP: ' + this.contaModel.cep + ' ' + this.contaModel.cidade + ' - ' + this.contaModel.estado;
		let rodapeLinha3 = 'Fone: ' + (this._maskFormatService.getFormatedPhone(this.contaModel.telefone) ||
				this._maskFormatService.getFormatedCellPhone(this.contaModel.celular));

		return {
			rodapeLinha1: rodapeLinha1,
			rodapeLinha2: rodapeLinha2,
			rodapeLinha3: rodapeLinha3,
		};
	}

	async _initConta() {
		const { currentConta } = this._loginService;
		if (currentConta && !this.contaModel) {
			this.contaModel = await this._contaApiService.fetchContaCompleta(currentConta.perfilContaModuloContaId);
		}

		return this.contaModel;
	}

	getConfigurations(): Promise<PrintConfiguration> {
		return this._http
				.get<PrintConfiguration>(`${ this._config.apiBasePath }/${ BASE_PATH }/findConfiguracaoImpressao`)
				.toPromise();
	}

	async getLogoBlob(): Promise<ArrayBuffer> {
		return this._http
				.get<ArrayBuffer>(`${ this._config.apiBasePath }/conta/obterLogomarcaBase64`, { responseType: 'blob' as 'json' })
				.toPromise();
	}

	update(printConfiguration: PrintConfiguration): Promise<PrintConfiguration> {
		return this._http
				.post<PrintConfiguration>(`${ this._config.apiBasePath }/${ BASE_PATH }/save`, printConfiguration)
				.toPromise();
	}
}
