import {Inject, Injectable} from '@angular/core';
import {API_CONFIGURATION, ApiConfiguration} from './api-configuration';
import {HttpClient} from '@angular/common/http';
import {PatientModel} from '../core/models/patients/patients.model';

@Injectable({
  providedIn: 'root',
})
export class PacienteApiService {
  constructor(
    @Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
    private readonly _http: HttpClient,
  ) {
  }

  cadastrar(paciente: PatientModel): Promise<PatientModel> {
    return this._http
      .post<PatientModel>(`${this._config.apiBasePath}/paciente/salvar`, paciente)
      .toPromise();
  }

  downloadProfileImage(patientId: number): Promise<ArrayBuffer> {
    return this._http
      .get<ArrayBuffer>(`${this._config.apiBasePath}/paciente/obterBytesImagem/${patientId}`,
        { responseType: 'blob' as 'json' })
      .toPromise();
  }
}
