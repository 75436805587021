import { Component, Input, OnInit } from "@angular/core";
import { PaymentReceiptService } from "./payment-receipt.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { PaymentReceipt } from "./model/paymentReceipt";
import { CONSTANTS } from "../../core/constants/constants";
import { ShortListItem } from "../../core/models/forms/common/common.model";
import { UtilsService } from "../../services/utils/utils.service";
import { PdfGeneratorService, PDFMake } from "../../services/pdf-generator/pdf-generator.service";
import { CommonPrintService } from "../../pages/patients/emit-documents/service/common-print.service";
import { CPF_LENGTH, CPF_MASK } from "../../lib/input-masks";
import { PrintConfigurationsService } from "../../pages/configs/medical-record/print/service/print-configurations.service";
import { MaskFormatService } from "../../services/utils/mask-format.service";
import { ProfessionalModel } from "../../core/models/config/professional/professional.model";
import { GlobalSpinnerService } from "../../lib/global-spinner.service";
import { ContaModel } from "../../api/conta-api.service";

const extenso = require("numero-por-extenso");
const getExtenso = (value: number): string => {
	return extenso.porExtenso(value, extenso.estilo.monetario);
};

const NO_LABEL = "Não Disponível";

@Component({
	selector: "app-payment-receipt",
	templateUrl: "./payment-receipt.component.html",
	styleUrls: ["./payment-receipt.component.scss"],
})
export class PaymentReceiptComponent implements OnInit {
	@Input() paymentReceipt: PaymentReceipt;
	loadingEmitter = false;

	readonly CNPJ_LENGTH = 18;
	readonly CPF_MASK = CPF_MASK;
	emitter = "clinic";
	responsible = false;

	professionals: ShortListItem[];
	currentProfessional: ProfessionalModel;
	contaInfo: ContaModel;

	readonly vias = ["1 Via", "2 Vias", "3 Vias"];
	selectedVia: string = this.vias[0];

	constructor(
		private readonly _paymentReceiptService: PaymentReceiptService,
		private readonly _commonPrintService: CommonPrintService,
		private readonly _pdfGeneratorService: PdfGeneratorService,
		private readonly _printConfigurationsService: PrintConfigurationsService,
		private readonly _globalSpinnerService: GlobalSpinnerService,
		public readonly _maskFormatService: MaskFormatService,
		public readonly _activeModal: NgbActiveModal,
		public readonly _service: UtilsService
	) {}

	ngOnInit() {
		this._initPaymentReceipt();
		this._initDropdown();
	}

	private async _initPaymentReceipt() {
		if (this.paymentReceipt.id) {
			this.paymentReceipt = await this._paymentReceiptService.getOne(this.paymentReceipt.id);
			if (this._isDifferentResponsible()) {
				this.responsible = true;
			}
			if (this._isDifferentEmitter()) {
				this.emitter = "dentist";
			}
			if (this._hasNoCnpjCpf()) {
				this.emitter = "simple";
			}
			this.emitterCpfCnpjMask();
		} else {
			this._initContaInfo();
		}
	}

	private async _initDropdown() {
		const professionals = await this._service
			.httpGET(CONSTANTS.ENDPOINTS.professional.active)
			.toPromise();
		this.professionals = professionals.body as ShortListItem[];
	}

	private async _initContaInfo() {
		if (!this.contaInfo) {
			this.loadingEmitter = true;
			try {
				this.contaInfo = await this._printConfigurationsService._initConta();
			} finally {
				this.loadingEmitter = false;
			}
		}
		this.paymentReceipt.cpfCnpjEmitente = this.contaInfo.cpfCnpj;
		this.paymentReceipt.nomeEmitente = this.contaInfo.razaoSocial;
	}

	private async _setCurrentProfessional() {
		const professional = await this._service
			.httpGET(CONSTANTS.ENDPOINTS.config.professional.findOne + this.paymentReceipt.profissionalId)
			.toPromise();
		this.currentProfessional = professional.body as ProfessionalModel;
	}

	emitterCpfCnpjMask() {
		if (!this.paymentReceipt.cpfCnpjEmitente) {
			return;
		}
		const emittent = this.paymentReceipt.cpfCnpjEmitente
			.replace(/\./g, "")
			.replace(/-/g, "")
			.replace(/\//g, "");
		this.paymentReceipt.cpfCnpjEmitente =
			emittent.length <= CPF_LENGTH
				? this._maskFormatService.getFormatedCpf(emittent)
				: this._maskFormatService.getFormatedCnpj(emittent);
	}

	clearEmitterInputs() {
		this.paymentReceipt.nomeEmitente = " ";
		this.paymentReceipt.cpfCnpjEmitente = " ";
	}

	async fillEmittentInfos(clinic: boolean) {
		if (clinic) {
			this.paymentReceipt.profissionalId = null;
			this._initContaInfo();
		} else {
			this.loadingEmitter = true;
			try {
				await this._setCurrentProfessional();
			} finally {
				this.loadingEmitter = false;
			}
			this.paymentReceipt.nomeEmitente = this.currentProfessional.profissionalNome;
			this.paymentReceipt.cpfCnpjEmitente = this.currentProfessional.profissionalCPF;
		}
	}

	async emit() {
		const loading = this._globalSpinnerService.loadingManager.start();
		const firstEmission = !this.paymentReceipt.id;
		try {
			if (firstEmission) {
				this.paymentReceipt = await this._paymentReceiptService.save(this.paymentReceipt);
			}
			const pdfMake = await this._buildPdfMake();
			const fileName = "Recibo.pdf";
			const pdfMakeDefinition = await this._pdfGeneratorService.exportPDFMakeAsPDF(
				pdfMake,
				"",
				fileName
			);
			if (firstEmission) {
				const file = await this._commonPrintService.returnFileFromPdfMakeDefinition(
					pdfMakeDefinition,
					fileName
				);
				this.paymentReceipt = await this._paymentReceiptService.save(this.paymentReceipt, file);
			}
			pdfMakeDefinition.download();
			this._service.notification.success("Recibo emitido.");
			this._activeModal.close(true);
		} catch (e) {
			this._service.notification.error("Ocorreu um erro ao emitir o recibo.");
		} finally {
			loading.finished();
		}
	}

	private async _buildPdfMake(): Promise<PDFMake> {
		const pdfMake: PDFMake = { beforeContent: [] };

		for (let i = 0; i <= this.vias.indexOf(this.selectedVia); i++) {
			pdfMake.beforeContent.push(await this._buildOnePaymentReceipt(i));
		}

		return pdfMake;
	}

	private async _buildOnePaymentReceipt(index: number) {
		const message = this._buildPaymentReceiptMessage();
		const maxCaracterPerLine = 96;
		return [
			{
				columns: [
					{
						text: "Nº. Recibo: " + this.paymentReceipt.numeroRecibo,
						fontSize: 10,
						bold: true,
						margin: [0, 10, 0, 0],
					},
					{ text: "Recibo", alignment: "center", fontSize: 20, bold: true },
					{ text: "R$ " + this.paymentReceipt.valor.toFixed(2), alignment: "center", bold: true },
				],
			},
			{
				canvas: [
					{
						type: "rect",
						x: 390,
						y: -30,
						w: 130,
						h: 30,
						lineWidth: 1,
						r: 100,
					},
				],
			},
			{ text: message, margin: [0, 30, 0, 0] },
			{
				text: [
					{ text: "Local e Data de Emissão: ", bold: true },
					(this._printConfigurationsService.getContaModel().cidade || NO_LABEL) +
						", " +
						this._buildDataEmissao(),
				],
				margin: [0, 15, 0, 0],
			},
			await this._buildPaymentReceiptFooter(),
			{
				canvas: [
					{
						type: "rect",
						x: -10,
						y:
							-145 +
							-((message.length / maxCaracterPerLine) * (4 + message.length / maxCaracterPerLine)),
						w: 570,
						h: 150 + (message.length / maxCaracterPerLine) * 5,
						lineWidth: 1,
					},
				],
			},
			this._strokedLine(index),
		];
	}

	private _buildDataEmissao(): string {
		if (!this.paymentReceipt.dataEmissao || !this.paymentReceipt.dataEmissao.trim()) {
			return NO_LABEL;
		}
		const splitted = this.paymentReceipt.dataEmissao.split(" ")[0];
		return !splitted.trim() ? NO_LABEL : splitted;
	}

	private _strokedLine(index: number) {
		if (this.vias.length !== index + 1) {
			return {
				canvas: [
					{
						type: "line",
						x1: -50,
						y1: 0,
						x2: 600,
						y2: 0,
						lineWidth: 1,
						dash: { length: 5, space: 5 },
					},
				],
				margin: [0, 20, 0, 20],
			};
		}
	}

	private _buildPaymentReceiptMessage(): string {
		const {
			nomeRespPagamento,
			cpfRespPagamento,
			valor,
			nomePaciente,
			cpfCnpjPaciente,
		} = this.paymentReceipt;
		const extenso = getExtenso(valor);
		if (this._isDifferentResponsible()) {
			return `Recebemos de ${nomeRespPagamento}, com o CPF ${this._maskFormatService.getFormatedCpf(
				cpfRespPagamento
			)}, a quantia de ${extenso}, referente ao tratamento odontológico do(a) paciente ${nomePaciente}, com o CPF ${this._maskFormatService.getFormatedCpf(
				cpfCnpjPaciente
			)} e por ser verdade, firmamos o presente recibo.`;
		} else {
			return `Recebemos de ${nomePaciente}, com o ${this._maskFormatService.getFormatedCpf(
				cpfCnpjPaciente
			)}, a quantia de ${extenso}, referente ao tratamento odontológico e por ser verdade, firmamos o presente recibo.`;
		}
	}

	private async _buildPaymentReceiptFooter() {
		let { nomeEmitente, cpfCnpjEmitente } = this.paymentReceipt;
		return {
			stack: [
				{ canvas: [{ type: "line", x1: 0, y1: -1, x2: 200, y2: -1, lineWidth: 1 }] },
				{ text: nomeEmitente || NO_LABEL, bold: true, margin: [0, 10, 0, 0] },
				{ text: cpfCnpjEmitente || NO_LABEL, margin: [0, 3, 0, 0] },
			],
			alignment: "center",
			margin: [0, 40, 0, 0],
		};
	}

	private _isDifferentEmitter(): boolean {
		return this.paymentReceipt.profissionalId;
	}

	private _hasNoCnpjCpf(): boolean {
		return this.paymentReceipt.cpfCnpjEmitente ? true : false;
	}

	private _isDifferentResponsible(): boolean {
		return !!this.paymentReceipt.nomeRespPagamento;
	}
}
