import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIGURATION, ApiConfiguration } from '../../../../../api/api-configuration';
import { ShortListItemDesc } from '../../../../../core/models/forms/common/common.model';

const BASE_PATH = 'tipoAtendimento';

@Injectable({
    providedIn: 'root',
})
export class TipoAtendimentoService {

    constructor(
        @Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
        private readonly _http: HttpClient,
    ) {
    }

    getTipoAtendimentoDropdown(): Promise<ShortListItemDesc[]> {
        return this._http
            .get<ShortListItemDesc[]>(`${ this._config.apiBasePath }/${ BASE_PATH }/dropdown`)
            .toPromise();
    }

    saveTipoAtendimentoDropdown(tipoAtendimento: ShortListItemDesc): Promise<ShortListItemDesc> {
        return this._http
            .post<ShortListItemDesc>(`${ this._config.apiBasePath }/${ BASE_PATH }/save`, tipoAtendimento)
            .toPromise();
    }

    removeTipoAtendimentoDropdown(id: number): Promise<void> {
        return this._http
            .delete<void>(`${ this._config.apiBasePath }/${ BASE_PATH }/` + id)
            .toPromise();
    }
}
