
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { translateFactory } from './TranslateLoader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translateFactory
            }
        })
    ],
    exports: [ TranslateModule ]
})

export class NgxTranslateModule {
    constructor(translate: TranslateService) {
        translate.addLangs(['pt']);
        translate.setDefaultLang('pt');
    }
}