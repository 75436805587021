import { Inject, Injectable } from "@angular/core";
import { DadosContrato } from "./model/dados-contrato";
import { API_CONFIGURATION, ApiConfiguration } from "./api-configuration";
import { HttpClient } from "@angular/common/http";
import { RespostaPaginada } from "./model/resposta-paginada";

export interface TagContrato {
	id: number;
	tag: string;
}

export enum TipoModeloContrato {
	MODELO_PADRAO = "MODELO_PADRAO",
}

export interface ModeloContrato {
	id: number | null;
	dataCriacao: string | null;
	nome: string;
	texto: string;
	tipoModeloContrato: TipoModeloContrato;
}

export interface ModeloContratoListing {
	id: number;
	dataCriacao: string;
	nome: string;
}

@Injectable({
	providedIn: "root",
})
export class ContractDataApiService {
	constructor(
		@Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
		private readonly _http: HttpClient
	) {}

	listContractModels(
		query: string = "",
		options: {
			size?: number;
			page?: number;
			sorting?: { [k in keyof ModeloContratoListing]: "asc" | "desc" };
		} = {}
	) {
		return this._http
			.post<RespostaPaginada<ModeloContratoListing>>(
				`${this._config.apiBasePath}/modeloContrato/all`,
				{ page: 0, size: 10, sorting: {}, query, ...options }
			)
			.toPromise();
	}

	getDadosContrato(): Promise<DadosContrato> {
		return this._http
			.get<DadosContrato>(`${this._config.apiBasePath}/contrato/getDadosContrato`)
			.toPromise();
	}

	listContractTags(): Promise<TagContrato[]> {
		return this._http
			.get<TagContrato[]>(`${this._config.apiBasePath}/tagDocumento/listarTagsContratoPadrao`)
			.toPromise();
	}

	saveContractModel(modeloContrato: ModeloContrato) {
		return this._http
			.post<void>(`${this._config.apiBasePath}/modeloContrato/save`, {
				...modeloContrato,
				tipoModeloContrato: "MODELO_PADRAO",
			})
			.toPromise();
	}

	fetchContractModel(id: number): Promise<ModeloContrato> {
		return this._http
			.get<ModeloContrato>(`${this._config.apiBasePath}/modeloContrato/findOne/${id}`)
			.toPromise();
	}
}
