import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UtilsService } from './services/utils/utils.service';
import { NotificationAnimationType, Options } from 'angular2-notifications';
import { Subscription } from 'rxjs';
import { GlobalSpinnerService } from './lib/global-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Mobidental';

  private _subs = new Subscription();

  readonly notificationOptions: Options = {
    position: ['bottom', 'right'],
    maxStack: 8,
    timeOut: 5000,
    showProgressBar: true,
    pauseOnHover: true,
    lastOnBottom: true,
    clickToClose: true,
    preventDuplicates: false,
    preventLastDuplicates: false,
    theClass: 'bg-c-pink',
    rtl: false,
    animate: NotificationAnimationType.Scale,
  };

  private _inviteIntervalId?: unknown;

  constructor(
    private router: Router,
    private readonly _activatedRoute: ActivatedRoute,
    private _globalSpinnerService: GlobalSpinnerService,
    public service: UtilsService,
  ) {
    this._subs.add(() => this._globalSpinnerService.unsubscribeToLoadingManager());
  }

  ngOnInit() {
    this._globalSpinnerService.subscribeToLoadingManager();

    this.checkForInvite();

    this._inviteIntervalId = setInterval(() => {
      this.checkForInvite();
    }, 1000);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);

      this.checkForInvite();
    });
  }

  @HostListener('window:popstate')
  checkForInvite() {
    this._checkForInvite(new URL(window.location.href));
  }

  ngOnDestroy(): void {
    clearInterval(this._inviteIntervalId as any);
    this._inviteIntervalId = undefined;
    this._subs.unsubscribe();
  }

  private _checkForInvite(url: URL) {
    const match = url.hash.match(/#\/convite\/aceitar\/([\w-]+)/);

    if (match) {
      this.router.navigate(
        ['/auth/login/invite'],
        { replaceUrl: true, queryParams: { inviteKey: match[1] } },
      );
    }
  }

}
