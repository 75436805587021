export interface EventAgenda {
  title: string;
  start: string;
  end: string;
  editable: boolean;
  eventDurationEditable: boolean;
  eventStartEditable: boolean;
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  eventObject: any;
}

export interface BusinessHours {
  daysOfWeek: number[];
  startTime: string;
  endTime: string;
}

export interface EventData {
  eventoId?: any;
  profissionalId?: any;
  dataEvento?: any;
  duracaoEvento?: any;
}

export class AgendaModel {
  id: number = null;
  pacienteId: number = null;
  profissionalId: number = null;
  profissionalNome?: string;
  tipoAtendimentoDescricao?: string;
  pacienteUrlImagemPerfil?: string;
  convenioId: number = null;
  celular: string = null;
  telefoneFixo: string = null;
  email: string = null;
  dataHoraInicialEvento: string = null;
  dataHoraFinalEvento?: string;
  pessoaNome?: string = null;
  duracao: number = null;
  emStatusAgendamento: string = null;
  anotacoes: string = null;
  pacienteCodigoProntuario: string = null;
  pacientePessoaNome?: string = null;
  nomePaciente?: string = null;
  tipoAtendimentoId: number = null;
  dataHoraCadastro: string = null;
  dataHoraAlteracao: string = null;
  usuarioCadastroNome: string = null;
  usuarioAlteracaoNome: string = null;
  emTipoAgendamento: string = null;
  alertaRetornoId: number = null;
}

export interface ServicesListModel {
  contaId: number;
  descricao: string;
  id: number;
}

export interface EventSearchTableModel {
  anotacoes: string;
  celular: string;
  convenioNome: string;
  dataHoraInicialEvento: string;
  emStatusAgendamento: string;
  id: number;
  pacientePessoaNome: string;
  profissionalNome: string;
  telefoneFixo: string;
  tipoAtendimentoDescricao: string;
  tipoAtendimentoId: number;
}
