export const CPF_MASK = '000.000.000-00';
export const CPF_LENGTH = 11;
export const CNPJ_LENGTH = 14;
export const CNPJ_MASK = '00.000.000/0000-00';
export const FULL_CELLPHONE_NUMBER_MASK = '(00) 00000-0000';
export const CELLPHONE_NUMBER_MASK = '(00) 0000-0000';
export const CEP_MASK = '00.000-000';
export const CEP_LENGTH = 8;


export const imasks = {
  cpf: () => ({ mask: CPF_MASK }),
  cnpj: () => ({ mask: CNPJ_MASK }),
  cpfCnpj: () => ({
    mask: [{ mask: CPF_MASK }, { mask: CNPJ_MASK }],
  }),
  phoneNumber: () => ({
    mask: [{ mask: CELLPHONE_NUMBER_MASK }, { mask: FULL_CELLPHONE_NUMBER_MASK }],
  }),
  cep: () => ({ mask: CEP_MASK }),
};
