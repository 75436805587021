import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIGURATION, ApiConfiguration } from './api-configuration';
import { AgendaModel } from '../core/models/agenda/agenda.model';

export interface PacienteAgendaDto {
  id: number;
  codigoProntuario: string;
  letraProntuario: string;
  numeroProntuario: number;
  pessoaId: number;
  pessoaContaId: number;
  pessoaNome: string;
}

@Injectable({
  providedIn: 'root',
})
export class AgendaApiService {

  constructor(
    private readonly _http: HttpClient,
    @Inject(API_CONFIGURATION) private readonly _config: Readonly<ApiConfiguration>,
  ) { }

  criarPaciente(nome: string): Promise<PacienteAgendaDto> {
    return this._http
      .get<PacienteAgendaDto>(
        `${ this._config.apiBasePath }/eventoAgenda/paciente/save`,
        { params: { nome } },
      )
      .toPromise();
  }

  listHistoryPatientScheduling(patientId: number): Promise<AgendaModel[]> {
    return this._http
      .get<AgendaModel[]>(`${ this._config.apiBasePath }/eventoAgenda/all/paciente/?pacienteId=${ patientId }`)
      .toPromise();
  }

}
