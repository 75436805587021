import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_CONFIGURATION, ApiConfiguration } from './api-configuration';
import { Conta } from './model/conta';
import { ContaUserModel, UserModel, UsuarioPerfilContaModuloSelecionado } from '../core/models/user/user.model';
import { Uf } from '../lib/uf';
import { omit } from 'ramda';

export interface ContaModel {
  id: number;
  email: string;
  razaoSocial: string;
  cpfCnpj: string;
  telefone: string;
  celular: string;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: Uf;
  horaAbertura: string;
  horaFechamento: string;
  urlImagem?: string;
  porcentagemDescontoDebito: string;
  porcentagemDescontoCredito: string;
  porcentagemDescontoCreditoParcelado: string;
  geraProntuarioAutomatico: string;
}

@Injectable({
  providedIn: 'root',
})
export class ContaApiService {

  constructor(
    private readonly _http: HttpClient,
    @Inject(API_CONFIGURATION) private readonly _config: Readonly<ApiConfiguration>,
  ) {
  }

  novaConta(conta: Conta): Promise<UserModel> {
    return this._http.post<UserModel>(`${this._config.openBasePath}/conta/novo`, conta).toPromise();
  }

  listarTodasContas(): Promise<ContaUserModel[]> {
    return this._http.get<ContaUserModel[]>(
      `${this._config.apiBasePath}/usuarioPerfilContaModulo/usuario/all`
    ).toPromise()
  }

  escolherContaSessao(usuarioPerfilContaModuloId: number): Promise<UsuarioPerfilContaModuloSelecionado> {
    return this._http
      .get<UsuarioPerfilContaModuloSelecionado>(
        `${this._config.apiBasePath}/usuario/contas/alternar`,
        { params: { usuarioPerfilContaModuloId: String(usuarioPerfilContaModuloId) } },
      )
      .toPromise();
  }

  fetchContaCompleta(id: number): Promise<ContaModel | undefined> {
    return this._http
      .get<ContaModel | null>(`${this._config.apiBasePath}/conta/findOne/${id}`)
      .toPromise()
      .then(it => it == null ? undefined : it);
  }

  salvarConta(contaNova: ContaModel, image?: File): Promise<void> {
    const formData = new FormData();
    formData.set('conta', JSON.stringify(
      image
        ? omit(['urlImagem'], contaNova)
        : contaNova,
    ));

    if (image) {
      formData.set('files', image);
    }

    return this._http
      .post<void>(`${this._config.apiBasePath}/conta/save`, formData)
      .toPromise();
  }

}
