import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { LoginService } from '../../auth/login.service';
import { Injectable } from '@angular/core';

export const PREV_ROUTE_PARAM = 'redirectTo';

@Injectable({
  providedIn: 'root',
})
export class ChoosenContaGuardService implements CanActivate, CanActivateChild {

  constructor(
    private readonly _loginService: LoginService,
    private readonly _router: Router,
  ) {
  }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    const hasConta = this._loginService.currentConta != null;

    if (!hasConta) {
      await this._router.navigate(
        ['/auth/conta'],
        {
          queryParams: { [PREV_ROUTE_PARAM]: state.url },
        },
      );
    }

    return hasConta;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ) {
    return this.canActivate(childRoute, state);
  }

}
