import { SimNaoResposta } from '../../../pages/patients/anamnese/models/SimNaoResposta';

export interface PatientsTableModel {
    id: number;
    nome: string;
    codigoProntuario: string;
    letraProntuario: string;
    numeroProntuario: string;
    pessoaNome: string;
    pessoaTelefone: string;
    pessoaCelular: string;
    convenioNome: string;
    isTitularConvenio: boolean;
    vencimentoCarterinha: string;
}

export class TreatmentModelTable {
    totalElements: number = null;
    totalPages: number = null;
    size: number = null;
    content: ContentTreatmentModel[] = [];
}

export class ContentBillTreatmentModel {
    id: number = null;
    numeroParcela: number = null;
    numeroTotalParcelas: number = null;
    emTipoParcelaReceber: null = null;
    formaDeRecebimento: string = null;
    formaDeRecebimentoDescricao: string = null;
    valor: number = null;
    juros: number = null;
    valorRecebido: number = null;
    dataEmissao: string = null;
    dataVencimento: string = null;
    tratamentoDataAprovacao: any = null;
    tratamentoId: number = null;
    tratamentoProfissionalId: number = null;
    tratamentoProfissionalNome: string = null;
    contaId: number = null;
    usuarioId: number = null;
    children: any[] = null;
    descricao: string = null;
}

export class ContentTreatmentModel {
    id: number = null;
    posicaoTratamento: number = null;
    descricao: string = null;
    convenioId: number = null;
    convenioNome: string = null;
    profissionalId: number = null;
    profissionalNome: string = null;
    dataAbertura: any = null;
    operadorVendedorId: number = null;
    operadorVendedorNome: string = null;
    dataEncerramento: string = null;
    pacienteId: number = null;
    pacientePessoaNome: string = null;
    pacientePessoaCpfCnpj: string = null;
    statusTratamento: string = null;
    statusTratamentoDescricao: string = null;
    formaDeRecebimento: string = null;
    formaPagamento: string = null;
    tipoDesconto: string = null;
    numeroTotalParcelas: number = null;
    valor: number = null;
    valorComDesconto: number = null;
    valorDesconto: number = null;
    valorPercentualDesconto: number = null;
    servicos: ProceduresModel[];
    parcelas: ParcelasModel[];
    guiaConvenio: string = null;
}

export class ParcelasModel {
    id: number = null;
    descricao: string = null;
    dataEmissao: string = null;
    dataVencimento: any = null;
    formaDeRecebimento: string = null;
    numeroParcela: number = null;
    tratamentoId: number = null;
    valor: number = null;
    recebido: number = null;
    usuarioId: number = null;
    juros: number = null;
    edited = false;
}

class emDenteModel {
    label: string = null;
    value: string = null;
}


export class ProceduresModel {
    id: number = null;
    tratamentoId: number = null;
    isFaceDistal: boolean = null;
    isFaceLingualPalatina: boolean = null;
    isFaceMesial: boolean = null;
    isFaceOclusalIncisal: boolean = null;
    isFaceVestibular: boolean = null;
    profissionalId: number = null;
    profissionalNome: string = null;
    convenioEspecialidadeProcedimentoId: number = null;
    convenioEspecialidadeProcedimentoNome: string = null;
    valor: number = null;
    statusTratamentoServico: string = null;
    statusTratamentoServicoDescricao: string = null;
    emDente: emDenteModel = new emDenteModel();
    emDenteDescricao: string = null;
}

export class BillTreatmentModelTable {
    valorTotalRecebido: number = null;
    quantidadeParcelasAPagar: number = null;
    quantidadeParcelasPagas: number = null;
    valorTotal: number = null;
    valorTotalARecer: number = null;
    content: ContentBillTreatmentModel[] = [];
}

export class PatientModel {
    id: number = null;
    codigoProntuario: string = null;
    letraProntuario: string = null;
    numeroProntuario: number = null;
    pessoaId: number = null;
    pessoaContaId: number = null;
    pessoaNome: string = null;
    pessoaSexo: string = null;
    modeloId: number = null;
    idade: number = null;
    pessoaDataCadastro: string = null;
    pessoaEstadoCivil: string = null;
    pessoaDataNascimento: any = null;
    pessoaCpfCnpj: string = null;
    pessoaRgIe: string = null;
    pessoaEmissorRgIe: string = null;
    pessoaCelular: string = null;
    pessoaCelular2: string = null;
    pessoaTelefone: string = null;
    profissionalId: number = null;
    pessoaObservacao: string = null;
    pessoaEmail: string = null;
    pessoaTelefoneRecado: string = null;
    emIndicacao: string = null;
    descricaoIndicacao: string = null;
    pessoaProfissaoId: number = null;
    especialidadesPaciente: any[] = null;
    situacaoId: number = null;
    empresaId: number = null;
    convenioId: number = null;
    convenioNome: string = null;
    isTitularConvenio: boolean = null;
    numeroCarteirinha: string = null;
    vencimentoCarterinha: any = null;
    nomeTitularDoConvenio: string = null;
    pessoaNomeResponsavel: string = null;
    pessoaDataNascimentoResponsavel: any = null;
    pessoaCelularResponsavel: string = null;
    pessoaCpfResponsavel: string = null;
    pessoaRgResponsavel: string = null;
    pessoaCep: string = null;
    pessoaEndereco: string = null;
    pessoaNumeroEndereco: string = null;
    pessoaBairro: string = null;
    pessoaCidade: string = null;
    pessoaUfCidade: string = null;
    pessoaComplemento: string = null;
    urlImagemPerfil: string = null;
    respostasAlerta: RespostaAlerta[] = null;
    treatment: TreatmentModelTable = new TreatmentModelTable();
    bills: BillTreatmentModelTable = new BillTreatmentModelTable();
    geraProntuarioAutomatico: string = null;
}

export interface RespostaAlerta {
    perguntaPergunta?: string;
    resposta?: string;
    respostaSimNao?: SimNaoResposta;
}


export interface AutoCompletePatientList {
    id: number;
    pessoaId: number;
    pessoaNome: string;
}

