import { Inject, Injectable } from '@angular/core';
import { API_CONFIGURATION, ApiConfiguration } from '../../api/api-configuration';
import { HttpClient } from '@angular/common/http';
import { PaymentReceipt } from './model/paymentReceipt';

const BASE_PATH = 'recibo';

@Injectable({
	providedIn: 'root',
})
export class PaymentReceiptService {

	constructor(
			@Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
			private readonly _http: HttpClient,
	) {
	}

	save(receipt: PaymentReceipt, file?: File): Promise<PaymentReceipt> {
		const formData = file ? PaymentReceiptService._buildFormData(receipt, file) : receipt;
		const path = file ? 'salvarAnexoRecibo' : 'salvarRecibo';

		return this._http
				.post<PaymentReceipt>(`${ this._config.apiBasePath }/${ BASE_PATH }/${ path }`, formData)
				.toPromise();
	}

	getOne(id: number): Promise<PaymentReceipt> {
		return this._http
				.get<PaymentReceipt>(`${ this._config.apiBasePath }/${ BASE_PATH }/findOne/` + id)
				.toPromise();
	}

	download(id: number): Promise<ArrayBuffer> {
		return this._http
				.get<ArrayBuffer>(`${ this._config.apiBasePath }/${ BASE_PATH }/download/` + id, { responseType: 'blob' as 'json' })
				.toPromise();
	}

	private static _buildFormData(receipt: PaymentReceipt, file: File): FormData {
		const formData = new FormData();

		formData.append('file', file);
		formData.append('dados', JSON.stringify(receipt));

		return formData;
	}
}
