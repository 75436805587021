import { Injectable } from '@angular/core';
import { LocalStorageService } from 'angular-2-local-storage';
import { UserModel } from '../core/models/user/user.model';

@Injectable({
    providedIn: 'root'
})
export class UserPermissionService {

    user?: UserModel;

    constructor(private _localStorageService: LocalStorageService) {
    }

    private getData() {
        this.user = this._localStorageService.get('user');
    }

    hasPermission(actionId: number): boolean {
        this.getData();
        return !!this.user.usuarioPerfilContaModuloSelecionado.listaModuloTelaAcaoDTOSelecionado.find(value => {
            return value.moduloTelaAcaoId == actionId;
        });
    }

}
