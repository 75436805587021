import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { BootstrapInputDirective } from './bootstrap-input-directive/bootstrap-input.directive';
import { GlobalSpinnerService } from './global-spinner.service';
import { AutoSizedDatatableDirective } from './auto-sized-datatable/auto-sized-datatable.directive';
import { AutoSizedDatatableService } from './auto-sized-datatable/auto-sized-datatable.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    BootstrapInputDirective,
    AutoSizedDatatableDirective,
  ],
  exports: [
    BootstrapInputDirective,
    AutoSizedDatatableDirective,
  ],
})
export class AppLibModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppLibModule,
      providers: [
        GlobalSpinnerService,
        AutoSizedDatatableService,
      ],
    };
  }

}
