import { ENDPOINTS } from './endpoint/endpoint';
import { ROUTERS } from './routers/routers';
import { STORAGE } from './storage/storage';

const COMMONS_VALUES = {
  CURRENCY_SYMBOL: 'R$',
  THOUSANDS: '.',
  DECIMAL: ','
};

const STATUS_LIST_COLOR = {
  ON_DATE: 'badge-dark',
  TODAY: 'badge-primary',
  RECEIVED_ALL: 'badge-success',
  RECEIVED_PARTIAL: 'badge-warning',
  LATE_PAYMENT: 'badge-pink'
};

export const CONSTANTS = {
  ENDPOINTS,
  ROUTERS,
  COMMONS_VALUES,
  STORAGE,
  STATUS_LIST_COLOR
};
