import { InjectionToken } from '@angular/core';

export interface ApiConfiguration {
  apiBasePath: string;
  openBasePath: string;
}

export const API_CONFIGURATION = new InjectionToken<ApiConfiguration>(
  'mobidental.API_CONFIGURATION',
);
