import { Injectable } from '@angular/core';
import { DadosContrato } from '../../api/model/dados-contrato';
import { BehaviorSubject } from 'rxjs';
import { ContractDataApiService } from '../../api/contract-data-api.service';

@Injectable({
                providedIn: 'root'
            })
export class ContractDataStore {

    private contractData$ = new BehaviorSubject<DadosContrato>(undefined);
    contractDataObservable = this.contractData$.asObservable();

    constructor(private readonly _contractDataApiService: ContractDataApiService) {
        this._getContractData();
    }

    private async _getContractData(): Promise<void> {
        this._emit(await this._contractDataApiService.getDadosContrato());
    }

    private _emit(contractData?: DadosContrato): void {
        this.contractData$.next(contractData);
    }

}
