import { translationPt } from './i18n/pt';
import { of, Observable } from 'rxjs';
import { TranslateLoader as NgxTranslateLoader } from '@ngx-translate/core';

const TRANSLATIONS = {
  pt: translationPt
};

export class TranslateLoader implements NgxTranslateLoader {
  public getTranslation(lang: string): Observable<any> {
    return of(TRANSLATIONS[lang]);
  }
}

export function translateFactory() {
  return new TranslateLoader();
}
