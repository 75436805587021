import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private service: UtilsService
  ) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.service.checkPermission(route.data.id, route.data.children);
  }

}
