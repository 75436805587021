import { Inject, Injectable } from '@angular/core';
import { API_CONFIGURATION, ApiConfiguration } from '../../../../api/api-configuration';
import { HttpClient } from '@angular/common/http';
import { ReturnAlert } from '../model/return-alert';
import { ShortListItem } from '../../../../core/models/forms/common/common.model';
import { ReturnAlertStatus } from '../model/enum/return-alert-status';

const BASE_PATH = 'alertaRetorno';
const REASON_BASE_PATH = 'motivoAlertaRetorno';

@Injectable({
	providedIn: 'root',
})
export class ReturnAlertService {

	constructor(
			@Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
			private readonly _http: HttpClient,
	) {
	}

	listAll(dataInicio: string, dataFim: string, isExpirados: boolean = false, status: string = ReturnAlertStatus.ABERTO): Promise<ReturnAlert[]> {
		const params = {
			dataInicio,
			dataFim,
			isExpirados,
			status,
		};

		return this._http
				.post<ReturnAlert[]>(`${ this._config.apiBasePath }/${ BASE_PATH }/all`, params)
				.toPromise();
	}

	getOne(id: number): Promise<ReturnAlert> {
		return this._http
				.get<ReturnAlert>(`${ this._config.apiBasePath }/${ BASE_PATH }/findOne/` + id)
				.toPromise();
	}

	save(returnAlert: ReturnAlert): Promise<ReturnAlert> {
		return this._http
				.post<ReturnAlert>(`${ this._config.apiBasePath }/${ BASE_PATH }/salvar`, returnAlert)
				.toPromise();
	}

	remove(id: number): Promise<void> {
		return this._http
				.delete<void>(`${ this._config.apiBasePath }/${ BASE_PATH }/` + id)
				.toPromise();
	}

	getMotivoAlertaRetornoDropdown(): Promise<ShortListItem[]> {
		return this._http
				.get<ShortListItem[]>(`${ this._config.apiBasePath }/${ REASON_BASE_PATH }/dropdown`)
				.toPromise();
	}

	saveMotivoAlertaRetornoDropdown(motivoAlertaRetorno: ShortListItem): Promise<ShortListItem> {
		return this._http
				.post<ShortListItem>(`${ this._config.apiBasePath }/${ REASON_BASE_PATH }/save`, motivoAlertaRetorno)
				.toPromise();
	}

	removeMotivoAlertaRetornoDropdown(id: number): Promise<void> {
		return this._http
				.delete<void>(`${ this._config.apiBasePath }/${ REASON_BASE_PATH }/` + id)
				.toPromise();
	}
}
