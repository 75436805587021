import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UtilsService } from './services/utils/utils.service';
import { JwtHelper } from './services/auth/jwt.helper';
import { CONSTANTS } from './core/constants/constants';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(public service: UtilsService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.authToken();
    const isIntern = this._isInternUrl(request.url);

    if (
      token
      && isIntern
      && !this._isWhitelistedUrl(request.url)
    ) {
      request = request.clone({
        headers: request.headers.set(
          'Authorization', 'Bearer ' + token,
        ),
      });
    }

    return next.handle(request).pipe(
      tap(undefined, error => {
        if (isIntern && error.status === 401) {
            this.service.navigate(CONSTANTS.ROUTERS.LOGIN, null);
          }
        },
      ));
  }

  private _isInternUrl(url: string): boolean {
    const currentUrl = new URL(location.href);
    const targetUrl = new URL(url, location.href);

    return currentUrl.host === targetUrl.host;
  }

  private _isWhitelistedUrl(url: string) {
    return url.indexOf('open/') > -1;
  }

  private authToken(): string {
    let token: string = this.service.getStorage('authToken') as string;
    if (token && new JwtHelper().isTokenExpired(token)) {
      this.service.removeStorage('authToken');
      token = '';
    }
    return token;
  }
}
