import { Component, Input, OnInit } from '@angular/core';

export type AlertType = 'success' | 'info' | 'warning' | 'danger' | 'primary' | 'secondary' | 'light' | 'dark';

@Component({
               selector: 'app-alert',
               templateUrl: './alert.component.html',
               styleUrls: ['./alert.component.scss']
           })
export class AlertComponent implements OnInit {

    @Input() dismissible = true;
    @Input() message = '';
    @Input() type: AlertType = 'danger';

    closed = false;

    constructor() {
    }

    ngOnInit() {
    }

}
