import { Injectable } from "@angular/core";
import html2pdf from "html2pdf.js";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as htmlToPdfmake from "html-to-pdfmake";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export type PdfMakePageOrientation = "portrait" | "landscape";

export interface PDFMake {
	pageMargins?: number[];
	footer?: any;
	styles?: any;
	defaultStyle?: any;
	afterContent?: any[];
	beforeContent?: any[];
	pageOrientation?: PdfMakePageOrientation;
}

export enum Action {
	OPEN,
	PRINT,
	DOWNLOAD,
}

export const defaultPageMargins = [20, 20, 20, 65];

/**
 * Esta classe é o serviço responsável por criar arquivo PDF pela aplicação
 */
@Injectable({
	providedIn: "root",
})
export class PdfGeneratorService {
	constructor() {}

	/**
	 * Este método cria um PDF através de um print de um elemento HTML da página
	 *
	 * @param elementId ID do elemento html que será impresso.
	 * @param filename Nome do arquivo que será gerado
	 */
	async exportElementAsPDF(elementId: string, filename = "mobi-dental.pdf") {
		const data = document.getElementById(elementId);
		data.style.display = "block";

		const options = {
			margin: [0, 0.5, 0, 0.5],
			filename,
			pagebreak: { mode: "avoid-all" },
			image: { type: "jpeg", quality: 1 },
			html2canvas: { scale: 2, allowTaint: true, useCORS: true },
			jsPDF: { unit: "cm", format: "a4", orientation: "p" },
		};

		await html2pdf().set(options).from(data).save();
		data.style.display = "none";
	}

	/**
	 * Este método cria um PDF através de um PDFMake e/ou html
	 *
	 * @param pdfMakeInterface PDFMake interface contendo footer e styles
	 * @param html Texto HTML
	 * @param filename Nome do arquivo que será gerado
	 */
	exportPDFMakeAsPDF(pdfMakeInterface: PDFMake, html: string, filename = "mobi-dental.pdf") {
		const content = html
			? htmlToPdfmake(html, { defaultStyle: pdfMakeInterface.defaultStyle })
			: null;

		const docDefinition = {
			info: {
				title: filename,
				author: "Mobi Dental",
			},
			pageOrientation: pdfMakeInterface.pageOrientation,
			pageMargins: pdfMakeInterface.pageMargins ? pdfMakeInterface.pageMargins : defaultPageMargins,
			footer: pdfMakeInterface.footer,
			content: [pdfMakeInterface.beforeContent, content, pdfMakeInterface.afterContent],
			styles: pdfMakeInterface.styles,
			defaultStyle: pdfMakeInterface.defaultStyle,
		};

		return pdfMake.createPdf(docDefinition);
	}
}
