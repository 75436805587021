import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UtilsService } from '../../../../services/utils/utils.service';
import { AgendaApiService } from '../../../../api/agenda.api.service';
import { GlobalSpinnerService } from '../../../../lib/global-spinner.service';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { PacienteApiService } from '../../../../api/paciente.api.service';
import { LISTS } from '../../lists';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AutoCompletePatientList, PatientModel } from '../../../../core/models/patients/patients.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ConvenantModel } from '../../../../core/models/patients/convenio.model';
import { ShortListItem, ShortListItemDesc } from '../../../../core/models/forms/common/common.model';
import { AgendaModel } from '../../../../core/models/agenda/agenda.model';
import { ModalAnimationComponent } from '../../../../shared/modal-animation/modal-animation.component';
import Swal from 'sweetalert2';
import { CONSTANTS } from '../../../../core/constants/constants';
import { FormAgenda } from '../../../../core/forms/agenda';
import { errorToString } from '../../../../lib/helpers/error-to-string';
import { TipoAtendimentoService } from './service/tipo-atendimento.service';

export const PATIENT_RECORD_LINK = (patientId: number) =>
    ['/pacientes/form', { id: patientId }];

@Component({
    selector: 'app-scheduling',
    templateUrl: './scheduling.component.html',
    styleUrls: ['./scheduling.component.scss'],
})
export class SchedulingComponent implements OnInit, OnChanges {

    @Input() convenantsList: ConvenantModel[] = [];
    @Input() professionals: ShortListItem[] = [];
    @Input() patient: PatientModel;
    @Input() eventObj: AgendaModel;
    @Input() modal?: ModalAnimationComponent;
    @Input() source?: string;

    @Output() eventsChange = new EventEmitter<boolean>(false);
    @Output() close = new EventEmitter<void>();

    fetchShedulings = new BehaviorSubject<number>(undefined);

    tipoAtendimentoList: ShortListItemDesc[] = [];
    searchingTipoAtendimento: string;

    constructor(
        public service: UtilsService,
        private readonly _agendaApiService: AgendaApiService,
        private readonly _tipoAtendimentoService: TipoAtendimentoService,
        private readonly _globalSpinnerService: GlobalSpinnerService,
        private readonly _notificationsService: NotificationsService,
        private readonly _router: Router,
        private readonly _pacienteApiService: PacienteApiService,
    ) {
        this._initTipoAtendimento();
    }

    showDateTimePicker = false;
    LOCAL_LISTS = new LISTS(this.service.translate);
    statusList = this.LOCAL_LISTS.listStatusAgenda;
    formAgenda: FormGroup;
    medicalRecord = '';
    debounce = moment().unix();
    patients: AutoCompletePatientList[] = [];
    searching = false;
    searchFailed = false;
    typeaheadValue = '';

    formatMatches = (value: AutoCompletePatientList) => value.pessoaNome;
    search = (text$: Observable<string>) =>
        text$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            tap(() => (this.searching = true)),
            switchMap(term =>
                term.length < 3
                    ? []
                    : this.findPatients(term).pipe(
                    tap((data: AutoCompletePatientList[]) => {
                        this.searchFailed = false;

                    }),
                    catchError(() => {
                        this.searchFailed = true;
                        return of([]);
                    }),
                    ),
            ),
            tap(() => (this.searching = false)),
        );

    ngOnChanges() {
        this.patchValues();
    }

    ngOnInit() {
        if (this.patient && this.patient.id) {
            this.fetchShedulings.next(this.patient.id);

            if (this.source && this.source === 'return-alert-page') {
                this.onSelectPatient({ item: { id: this.patient.id } });
            }
        }
    }

    findPatients(value: string) {
        return this.service
            .httpGET(CONSTANTS.ENDPOINTS.patients.general.autoComplete + value)
            .pipe(map(data => data.body));
    }

    patchValues() {
        const formObj = {
            nomePaciente: this.eventObj.pessoaNome || this.eventObj.pacientePessoaNome || '',
            ...this.eventObj,
        };

        if (this.formAgenda) {
            this.formAgenda.patchValue(formObj);
        } else {
            this.formAgenda = this.service.createForm(FormAgenda, formObj);
        }

        this.typeaheadValue = formObj.nomePaciente;

        if (this.patient) {
            this.medicalRecord = this.patient.codigoProntuario;
            if (this.patient.pessoaNome) {
                this.typeaheadValue = this.patient.pessoaNome;
            }
        }
    }

    toggleModalDate(applyValue?: boolean, event = null, id = null) {
        const delta = moment().unix() - this.debounce;

        if (
            applyValue &&
            delta > 0 &&
            event.target.tagName === 'DIV' &&
            event.srcElement.parentElement.className.indexOf('ngb-dp') === -1
        ) {
            this.showDateTimePicker = false;
        } else if (
            event &&
            delta > 0 &&
            event.target.tagName === 'INPUT' &&
            event.srcElement.className.indexOf('ngb-tp-input') === -1 &&
            event.srcElement.id === id
        ) {
            this.debounce = moment().unix();
            this.showDateTimePicker = !this.showDateTimePicker;
        } else if (delta > 0 && !event) {
            this.debounce = moment().unix();
            this.showDateTimePicker = !this.showDateTimePicker;
        }
    }

    applyDate(date) {
        this.formAgenda.patchValue({
            dataHoraInicialEvento: date,
        });
    }

    async onCadastrar() {
        const formControl = this.formAgenda && this.formAgenda.get('nomePaciente');
        const nome: string = (formControl && formControl.value || '').trim();

        if (!nome) {
            return;
        }

        const loadingToken = this._globalSpinnerService.loadingManager.start();

        try {
            const paciente = await this._pacienteApiService.cadastrar({
                pessoaNome: nome,
                pessoaCelular: this.formAgenda.get('celular').value,
                pessoaTelefone: this.formAgenda.get('telefoneFixo').value,
                pessoaEmail: this.formAgenda.get('email').value,
                convenioId: this.formAgenda.get('convenioId').value,
            } as any);

            this.formAgenda.patchValue({
                pacienteCodigoProntuario: paciente.codigoProntuario,
                pacienteId: paciente.id,
                nomePaciente: null,
            });

            this.medicalRecord = paciente.codigoProntuario;

            this._notificationsService.success('Paciente cadastrado com sucesso');
        } catch (e) {
            this._notificationsService.error('Erro ao cadastrar novo paciente', errorToString(e));
        } finally {
            loadingToken.finished();
        }

        this.saveEvent(false);
    }

    onChangePatientText(value: string | null) {
        this.formAgenda.patchValue({
            pacienteId: null,
            nomePaciente: value || '',
        });
    }

    onSelectPatient(patient) {
        this.formAgenda.patchValue({
            pacienteId: patient.item.id,
            nomePaciente: null,
        });

        this.service.loading(true);
        this.service
            .httpGET(
                CONSTANTS.ENDPOINTS.patients.general.findOne + patient.item.id,
            )
            .subscribe(
                data => {
                    const result = data.body as PatientModel;
                    this.formAgenda.patchValue({
                        email: result.pessoaEmail,
                        celular: result.pessoaCelular,
                        telefoneFixo: result.pessoaTelefone,
                        pacienteCodigoProntuario: result.codigoProntuario,
                        convenioId: result.convenioId,
                    });
                    this.medicalRecord = result.codigoProntuario;
                    this.fetchShedulings.next(result.id);
                    this.service.loading(false);
                },
                err => {

                    this.service.loading(false);
                    this.service.notification.error(
                        this.service.translate.instant('COMMON.ERROR.SEARCH'),
                        err.error.error,
                    );
                },
            );
    }

    saveEvent(close = true) {
        this.service.loading(true);
        const aux = { ...this.formAgenda.value };
        if (aux.dataHoraInicialEvento.length === 16) {
            aux.dataHoraInicialEvento += ':00';
        }
        this.service
            .httpPOST(CONSTANTS.ENDPOINTS.agenda.save, aux)
            .subscribe(
                data => {
                    if (close) {
                        this.close.emit();
                        this.service.notification.success(
                            this.service.translate.instant('AGENDA.TITLE'),
                            this.service.translate.instant('AGENDA.SUCCESS'),
                        );
                        this.eventsChange.emit(true);
                    }
                    this.service.loading(false);
                },
                err => {
                    this.service.loading(false);
                    this.service.notification.error(
                        this.service.translate.instant('COMMON.ERROR.SEARCH'),
                        err.error.error,
                    );
                },
            );
    }

    removeEvent() {
        const translate = this.service.translate;
        const refSWAL = 'AGENDA.SWAL';

        Swal.fire({
            title: translate.instant(`${ refSWAL }.TITLE`),
            text: translate.instant(`${ refSWAL }.TEXT_DELETE`),
            type: 'question',
            showCancelButton: true,
            confirmButtonText: translate.instant(`${ refSWAL }.CONFIRM_BUTTON_DELTE`),
            cancelButtonText: translate.instant(`${ refSWAL }.CANCEL_BUTTON`),
        }).then(result => {
            if (result.value) {
                this.service.loading(true);
                this.service
                    .httpDELETE(CONSTANTS.ENDPOINTS.agenda.delete + this.eventObj.id)
                    .subscribe(
                        data => {
                            this.close.emit();
                            this.service.loading(false);
                            this.service.notification.success(
                                this.service.translate.instant('AGENDA.TITLE'),
                                this.service.translate.instant('AGENDA.SUCCESS_DELETE'),
                            );
                            this.eventsChange.emit(true);
                        },
                        err => {

                            this.service.loading(false);
                            this.service.notification.error(
                                this.service.translate.instant('COMMON.ERROR.SEARCH'),
                                err.error.error,
                            );
                        },
                    );
            }
        });
    }

    get lastModificationInfo() {
        if (!this.eventObj.dataHoraAlteracao || !this.eventObj.usuarioAlteracaoNome) {
            return '';
        }
        return `Alterado em: ${ this.eventObj.dataHoraAlteracao }  por: ${ this.eventObj.usuarioAlteracaoNome }`;
    }

    getFormControl(name: string) {
        return this.formAgenda.get(name);
    }

    getPatientRecordLink(patientId: number) {
        return PATIENT_RECORD_LINK(patientId);
    }

    private async _initTipoAtendimento() {
        try {
            const data = await this.service.httpGET(CONSTANTS.ENDPOINTS.agenda.typeService,).toPromise();
            this.tipoAtendimentoList = data.body as ShortListItemDesc[];
        } catch (e) {
            this._notificationsService.error('', 'Ocorreu um erro ao obter os tipos de atendimento');
        }
    }

    prepareToSaveNewTipoAtendimento() {
        if (!this.searchingTipoAtendimento || !this.searchingTipoAtendimento.trim()) {
            this._notificationsService.alert('O tipo de atendimento não pode ser vazio!');
            return;
        }

        this.searchingTipoAtendimento = this.searchingTipoAtendimento.trim();
        const existingTipoAtendimento = this.tipoAtendimentoList.find(t => t.descricao === this.searchingTipoAtendimento);
        if (existingTipoAtendimento) {
            this.getFormControl('tipoAtendimentoId').setValue(existingTipoAtendimento.id);
            return;
        }

        this.saveNewTipoAtendimento();
    }

    async saveNewTipoAtendimento(tipoToSave: ShortListItemDesc = {
        id: null,
        descricao: this.searchingTipoAtendimento
    }) {
        const loading = this._globalSpinnerService.loadingManager.start();
        try {
            const reason = await this._tipoAtendimentoService.saveTipoAtendimentoDropdown(tipoToSave);
            this._notificationsService.success(`Tipo de atendimento ${ tipoToSave.id ? 'editado' : 'criado' }`);
            this.getFormControl('tipoAtendimentoId').setValue(reason.id);
            this._initTipoAtendimento();
        } catch (e) {
            this._notificationsService.error(`Ocorreu um erro ao ${ tipoToSave.id ? 'editar' : 'criar' } o tipo de atendimento`);
        } finally {
            loading.finished();
        }
    }

    async editCurrentTipoAtendimento() {
        const currentTipoAtendimento: ShortListItemDesc = this.tipoAtendimentoList.find(p => p.id === this.getFormControl(
            'tipoAtendimentoId').value);

        const input = await Swal.fire({
            title: 'Modifique o tipo de atendimento',
            input: 'text',
            inputValue: currentTipoAtendimento.descricao,
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
        });

        const value: string = String(input.value || '').trim();

        if (!value) {
            return;
        }

        this.saveNewTipoAtendimento({ id: currentTipoAtendimento.id, descricao: value });
    }

    async removeTipoAtendimento(id: number) {
        const confirmation = await Swal.fire({
            title: 'Confirmação de exclusão',
            text: 'Tem certeza que quer apagar este tipo de atendimento?',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim. Apague',
            cancelButtonText: 'Cancelar',
        });

        if (!confirmation.value) {
            return;
        }

        const loading = this._globalSpinnerService.loadingManager.start();
        try {
            await this._tipoAtendimentoService.removeTipoAtendimentoDropdown(id);
            this.tipoAtendimentoList = this.tipoAtendimentoList.filter(tipoAtendimento => tipoAtendimento.id !== id);
            const control = this.getFormControl('tipoAtendimentoId');
            if (control.value === id) {
                control.setValue(null);
            }
            this._notificationsService.success('Tipo de atendimento removido');
        } catch (e) {
            this._notificationsService.error('Ocorreu um erro ao remover o tipo de atendimento.');
        } finally {
            loading.finished();
        }
    }

}
