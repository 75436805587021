import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './layout/admin/admin.component';
import { AuthComponent } from './layout/auth/auth.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { MenuItems } from './shared/menu-items/menu-items';
import { BreadcrumbsComponent } from './layout/admin/breadcrumbs/breadcrumbs.component';
import { UtilsService } from './services/utils/utils.service';
import { TranslateStore } from '@ngx-translate/core';
import { NgxTranslateModule } from './core/translate/translate.module';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { LocalStorageModule } from 'angular-2-local-storage';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './http-config-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GestureConfig } from './services/hammer/hammer.service';
import { AuthGuard } from './services/guard/auth.guard';
import { ApiModule } from './api/api.module';
import { AppLibModule } from './lib/app-lib.module';
import { NgxMaskModule } from 'ngx-mask';
import { AccountChooserComponent } from './layout/admin/account-chooser/account-chooser.component';
import { LightboxModule } from 'ngx-lightbox';
import { UserPermissionService } from './auth/user-permission.service';

@NgModule({
    declarations: [
        AppComponent,
        AdminComponent,
        AuthComponent,
        BreadcrumbsComponent,
        AccountChooserComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        NgxTranslateModule,
        FormsModule,
        ReactiveFormsModule,
        ApiModule.forRoot({ apiBasePath: '/api', openBasePath: '/open' }),
        AppLibModule.forRoot(),
        NgxMaskModule.forRoot(),
        SimpleNotificationsModule.forRoot(),

        LocalStorageModule.forRoot({
            prefix: 'mobidental',
            storageType: 'localStorage'
        }),
        LightboxModule
    ],
    providers: [
        MenuItems,
        UtilsService,
        AuthGuard,
        TranslateStore,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true
        },
        { provide: LOCALE_ID, useValue: 'pt' },
        { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
        UserPermissionService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
