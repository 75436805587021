import { Directive, ElementRef, Host, HostBinding, Input, Optional } from "@angular/core";
import { NgControl } from "@angular/forms";

/**
 * Integração de inputs do angular com classes e estilos do bootstrap
 */
@Directive({
	selector: "[appBootstrapInput]",
})
export class BootstrapInputDirective {
	// Só existe pra permitir binding com [directiva], melhor tipagem
	@Input() appBootstrapInput: string = "";

	@Input() nativeValidation = true;
	@Input() ngModelValidation = true;

	constructor(
		@Host() @Optional() private readonly _control: NgControl,
		private readonly _elRef: ElementRef
	) {}

	@HostBinding("class.is-invalid")
	get invalidStyle(): boolean {
		return this._controlIsTouched() && (!this._controlIsValid() || !this._nativeElementIsValid());
	}

	private _controlIsTouched() {
		return !!this._control && !!this._control.touched;
	}

	private _controlIsValid(): boolean {
		return !this.ngModelValidation || (!!this._control && !!this._control.valid);
	}

	private _nativeElementIsValid() {
		const { nativeElement } = this._elRef;

		return (
			!this.nativeValidation ||
			(nativeElement instanceof HTMLInputElement && nativeElement.checkValidity())
		);
	}
}
