import { Injectable } from '@angular/core';

export const toDataURL = url => fetch(url).then(response => response.blob()).then(blob => convertBlobToBase64(blob));

export const convertBlobToBase64 = blob => new Promise((resolve, reject) => {
	const reader = new FileReader;
	reader.onerror = reject;
	reader.onload = () => {
		resolve(reader.result);
	};
	reader.readAsDataURL(blob);
});

@Injectable({
	providedIn: 'root',
})
export class FileUtilsService {

	constructor() { }
}
