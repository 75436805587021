import { Injectable } from '@angular/core';
import { CELLPHONE_LENGTH, FULL_CELLPHONE_LENGTH } from '../../pages/agenda/agenda.component';
import { CELLPHONE_NUMBER_MASK, CEP_LENGTH, CEP_MASK, CNPJ_LENGTH, CNPJ_MASK, CPF_LENGTH, CPF_MASK, FULL_CELLPHONE_NUMBER_MASK } from '../../lib/input-masks';
import { isEmpty } from 'lodash-es';

@Injectable({
	providedIn: 'root',
})
export class MaskFormatService {

	constructor() { }

	getFormatedLabel(label: string, maskSize: number, mask: string): string {
		if (isEmpty(label)) {
			return label;
		}

		let i = 0;
		return label.length === maskSize ? mask.replace(/0/g, _ => label[i++]) : label;
	}

	getFormatedCellPhone(cellphone: string): string {
		return this.getFormatedLabel(cellphone, FULL_CELLPHONE_LENGTH, FULL_CELLPHONE_NUMBER_MASK);
	}

	getFormatedPhone(phone: string): string {
		return this.getFormatedLabel(phone, CELLPHONE_LENGTH, CELLPHONE_NUMBER_MASK);
	}

	getFormatedCep(cep: string): string {
		return this.getFormatedLabel(cep, CEP_LENGTH, CEP_MASK);
	}

	getFormatedCpf(cpf: string): string {
		return this.getFormatedLabel(cpf, CPF_LENGTH, CPF_MASK);
	}

	getFormatedCnpj(cnpj: string): string {
		return this.getFormatedLabel(cnpj, CNPJ_LENGTH, CNPJ_MASK);
	}
}
