import { Inject, Injectable } from '@angular/core';
import { API_CONFIGURATION, ApiConfiguration } from '../../../../../api/api-configuration';
import { HttpClient } from '@angular/common/http';
import { Commitment } from '../model/commitment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalWithOptionsComponent, Option } from '../../../../../shared/modal-with-options/modal-with-options.component';
import { GlobalSpinnerService } from '../../../../../lib/global-spinner.service';
import { NotificationsService } from 'angular2-notifications';
import Swal from 'sweetalert2';
import * as moment from 'moment';

const BASE_PATH = '/compromisso/';
const SOMENTE_ESTE_LANCAMENTO = 'SOMENTE_ESTE_LANCAMENTO';
const DELETE_COMMITMENT_OPTIONS: Option[] = [
	{ label: 'Excluir somente este compromisso', value: SOMENTE_ESTE_LANCAMENTO },
	{ label: 'Excluir este e os compromissos seguintes', value: 'ESTE_E_PROXIMOS_LANCAMENTOS' },
	{ label: 'Excluir todos os compromissos', value: 'TODOS' },
];
const UPDATE_COMMITMENT_OPTIONS: Option[] = [
	{ label: 'Editar somente este compromisso', value: SOMENTE_ESTE_LANCAMENTO },
	{ label: 'Editar este e os compromissos seguintes', value: 'ESTE_E_PROXIMOS_LANCAMENTOS' },
];

@Injectable({
	providedIn: 'root',
})
export class CommitmentService {

	constructor(
			@Inject(API_CONFIGURATION) private readonly _config: ApiConfiguration,
			private readonly _http: HttpClient,
			private readonly _modalService: NgbModal,
			private readonly _globalSpinnerService: GlobalSpinnerService,
			private readonly _notificationsService: NotificationsService,
	) {
	}

	async removeDialog(commitment: Commitment): Promise<boolean> {
		const choosed = commitment.recorrenciaId ? await this._buildDialogWithOptions(
				'Confirmação de Exclusão de Compromisso',
				'Este compromisso possui outros eventos associados, você deseja' +
				'apagar todos os compromissos relacionados ou apenas este',
				'Excluir',
				DELETE_COMMITMENT_OPTIONS,
		) : SOMENTE_ESTE_LANCAMENTO;

		if (!choosed) {
			return false;
		}

		const { value } = await Swal.fire({
			title: 'Confirmação de exclusão',
			text: 'Tem certeza que deseja apagar?',
			type: 'question',
			showCancelButton: true,
			confirmButtonText: 'Sim. Apague',
			cancelButtonText: 'Cancelar',
		});

		if (!value) {
			return false;
		}

		const loading = this._globalSpinnerService.loadingManager.start();
		try {
			await this._remove(commitment.id, commitment.recorrenciaId, choosed);
			this._notificationsService.success('Compromisso removido.');
			return true;
		} catch (e) {
			this._notificationsService.error('Ocorreu um erro ao remover o Compromisso.');
			return false;
		} finally {
			loading.finished();
		}
	}

	async saveOrUpdate(commitment: Commitment): Promise<boolean> {
		if (commitment.id) {
			return this._updateDialog(commitment)
		}

		const loading = this._globalSpinnerService.loadingManager.start();
		try {
			await this._save(commitment);
			this._notificationsService.success('Compromisso cadastrado.');
			return true;
		} catch (e) {
			this._notificationsService.error('Ocorreu um erro ao cadastrar o Compromisso.');
			return false;
		} finally {
			loading.finished();
		}
	}


	private async _updateDialog(commitment: Commitment): Promise<boolean> {
		const choosed = await this._buildDialogWithOptions(
				'Confirmação de Edição de Compromisso',
				'Este compromisso possui outros eventos associados, o que deseja fazer?',
				'Salvar',
				UPDATE_COMMITMENT_OPTIONS,
		)

		if (!choosed) {
			return false;
		}

		const loading = this._globalSpinnerService.loadingManager.start();
		try {
			await this._update(commitment, choosed);
			this._notificationsService.success('Compromisso atualizado.');
			return true;
		} catch (e) {
			this._notificationsService.error('Ocorreu um erro ao atualizar o Compromisso.');
			return false;
		} finally {
			loading.finished();
		}
	}

	private _save(commitment: Commitment): Promise<Commitment> {
		const { profissionalId, descricao, dataFinal, dataInicial, tipoRecorrencia, isDiaInteiro } = commitment;
		const params = {
			profissionalId, descricao,
			dataFinal: CommitmentService._formatToTimeZone(dataFinal),
			dataInicial: CommitmentService._formatToTimeZone(dataInicial),
			tipoRecorrencia, isDiaInteiro,
		};
		return this._http
				.post<Commitment>(this._config.apiBasePath + BASE_PATH + 'salvarCompromisso', params)
				.toPromise();
	}

	private _update(commitment: Commitment, tipoEdicao: string): Promise<Commitment> {
		const id = commitment.id;
		const { profissionalId, descricao, dataFinal, dataInicial, tipoRecorrencia } = commitment;
		const compromisso = {
			profissionalId, descricao,
			dataFinal: CommitmentService._formatToTimeZone(dataFinal),
			dataInicial: CommitmentService._formatToTimeZone(dataInicial),
			tipoRecorrencia,
		};

		const params = {
			id,
			compromisso,
			tipoEdicao,
		};
		return this._http
				.put<Commitment>(this._config.apiBasePath + BASE_PATH + 'editarCompromisso', params)
				.toPromise();
	}

	private static _formatToTimeZone(date: Date): Date {
		return moment(date).subtract(3, 'hours').toDate();
	}

	private _remove(id: number, recorrenciaId: string, tipoExclusaoCompromisso: string): Promise<void> {
		const params = {
			id,
			recorrenciaId,
			tipoExclusaoCompromisso,
		};
		return this._http
				.put<void>(this._config.apiBasePath + BASE_PATH + 'removerCompromisso', params)
				.toPromise();
	}

	private async _buildDialogWithOptions(title: string, message: string, confirmText: string, options: Option[]) {
		const modalRef = this._modalService.open(ModalWithOptionsComponent, { size: 'lg', centered: true });
		modalRef.componentInstance.title = title;
		modalRef.componentInstance.message = message;
		modalRef.componentInstance.confirmText = confirmText
		modalRef.componentInstance.options = options;

		return await modalRef.result;
	}
}
