import { Injectable } from "@angular/core";
import { LoadingManager } from "./helpers/loading-manager";
import { UtilsService } from "../services/utils/utils.service";
import { Subscription } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class GlobalSpinnerService {
	readonly loadingManager = new LoadingManager();

	private _loadingManagerSub?: Subscription;

	constructor(private _utilsService: UtilsService) {}

	subscribeToLoadingManager() {
		if (!this._loadingManagerSub) {
			this._loadingManagerSub = this.loadingManager.loadingChange$.subscribe((loading) =>
				this._utilsService.loading(loading)
			);
		}
	}

	unsubscribeToLoadingManager() {
		if (this._loadingManagerSub) {
			this._loadingManagerSub.unsubscribe();
			this._loadingManagerSub = undefined;
		}
	}
}
