import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  Optional
} from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { AutoSizedDatatableService } from './auto-sized-datatable.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appAutosizedDatatable]'
})
export class AutoSizedDatatableDirective implements OnDestroy {
  private readonly _subs = new Subscription();
  @Input() appAutosizedDatatable = true;

  constructor(
    private readonly _autoSizedDatatableService: AutoSizedDatatableService,
    private readonly _datatable: DatatableComponent,
    private readonly _cdr: ChangeDetectorRef
  ) {
    this._subscribeToService();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _subscribeToService() {
    const sub = this._autoSizedDatatableService.recalculate.subscribe(() => {
      setTimeout(() => {
        if (this.appAutosizedDatatable) {
          this._datatable.recalculate();
          this._cdr.markForCheck();
        }
      });
    });

    this._subs.add(sub);
  }
}
