import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-visualize-issuable-document',
	templateUrl: './visualize-issuable-document.component.html',
	styleUrls: ['./visualize-issuable-document.component.scss'],
})
export class VisualizeIssuableDocumentComponent implements OnInit {

	@Input() pdfUrl: SafeResourceUrl;

	constructor(private readonly _activeModal: NgbActiveModal) { }

	ngOnInit() {
	}

	close() {
		this._activeModal.close();
	}

}
