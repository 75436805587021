import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { API_CONFIGURATION, ApiConfiguration } from './api-configuration';
import { HttpClientModule } from '@angular/common/http';
import { ContaApiService } from './conta-api.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
  ],
})
export class ApiModule {
  static forRoot(config: ApiConfiguration): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [
        { provide: API_CONFIGURATION, useValue: config },
        ContaApiService,
      ],
    };
  }
}
