import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface Option {
	label: string;
	value: any;
}

@Component({
	selector: 'app-modal-with-options',
	templateUrl: './modal-with-options.component.html',
	styleUrls: ['./modal-with-options.component.scss'],
})
export class ModalWithOptionsComponent implements OnInit {

	@Input() title: string;
	@Input() message: string;
	@Input() alertMessage: string;
	@Input() confirmText: string = 'Confirmar';
	@Input() closeText: string = 'Fechar';
	@Input() options: Option[];

	choosed: Option;

	constructor(private readonly _activeModal: NgbActiveModal) { }

	ngOnInit() {
		if (this.options && this.options.length > 0) {
			this.choosed = this.options[0].value;
		}
	}

	emitChoosed() {
		this._activeModal.close(this.choosed);
	}

	close() {
		this._activeModal.close();
	}

}
