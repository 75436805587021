export enum LogoPosition {
	ESQUERDA = 'ESQUERDA',
	CENTRO = 'CENTRO',
	DIREITA= 'DIREITA',
}

export const LOGO_POSITION_TRANSLATE: { [key in LogoPosition]: string } = {
	ESQUERDA: 'Alinhar à esquerda',
	CENTRO: 'Alinhar ao centro',
	DIREITA: 'Alinhar à direita',
};
