import { FormsModel } from '../models/forms/forms.model';
import { Validators } from '@angular/forms';

export const FormAgenda: FormsModel[] = [
  {
    field: 'pacienteId',
    validators: [],
  },
  {
    field: 'profissionalId',
    validators: [Validators.required],
  },
  {
    field: 'convenioId',
    validators: [Validators.required],
  },
  {
    field: 'emStatusAgendamento',
    validators: [Validators.required],
  },
  {
    field: 'tipoAtendimentoId',
    validators: [Validators.required],
  },
  {
    field: 'email',
    validators: [Validators.email],
  },
  {
    field: 'duracao',
    validators: [Validators.required, Validators.min(1)],
  },
  {
    field: 'nomePaciente',
    validators: [],
  },
];
