import { Directive, HostListener } from '@angular/core';
import * as screenfull from "screenfull";
import { Screenfull } from "screenfull";

@Directive({
  selector: '[appToggleFullScreen]'
})
export class ToggleFullScreenDirective {
  @HostListener('click')
  onClick() {
    if ((<Screenfull>screenfull).enabled) {
      (<Screenfull>screenfull).toggle();
    }
  }
}
