export const ENDPOINTS = {
	login: "/open/usuario/login",
	forgot: "open/usuario/recuperarSenha?email=",
	account: "api/usuarioPerfilContaModulo/usuario/all",
	changeAccount: "api/usuario/contas/alternar?usuarioPerfilContaModuloId=",
	patients: {
		general: {
			findOne: "api/paciente/findOne/",
			active: "/api/paciente/ativos",
			save: "api/paciente/salvar",
			delete: "api/paciente/",
			perfilImage: "api/paciente/salvar/imagem/perfil",
			autoComplete: "api/paciente/autocomplete?maxResults=10&query=",
		},
		treatment: {
			convenant: "api/convenio/dropdown",
			treatment: "api/tratamento/paciente",
			getTreatment: "api/tratamento/findTratamento/",
			saveTreatment: "api/tratamento/saveTratamento",
			services: "api/tratamentoServico/all/porTratamento",
			detachedTreatment: "api/tratamentoServico/adicionarItemServicoAvulso",
			removeDetachedTreatment: "api/tratamentoServico/",
			approveDesapprove: "api/tratamento/aprovarDesaprovar/",
			removeTreatment: "api/tratamento/",
			print: "api/tratamento/printPlanoTratamento/token",
			urlPlainTreatmentPdf: "/api/tratamento/printPlanoTratamento/export/?token=",
			updateStatusTratamento: "/api/tratamento/alterarStatusTratamento",
			createTreatmentFromNotAproved: "api/tratamento/createTreatmentFromNotAproved",
			getDefaultContract: "api/modeloContrato/obterModeloContratoPadrao",
			getContractTagsValue: "api/tratamento/obterValoresTagsContratoByTratamento",
		},
		financial: {
			checkIfPaid: "api/contaReceber/totalRecebidoTratamento/",
			byTreatment: "api/contaReceber/all/porTratamento",
			listQuota:
				"api/contaReceber/all/threeTable/porTratamentoPaciente/?tratamentoId=#treatmentId&pacienteId=",
			getParcelModal: "api/contaReceber/dialog/carregar/",
			payParcel: "api/contaReceber/receber",
			changeParcel: "api/contaReceber/alterar/aprovada",
			reverseParcel: "api/contaReceberRecebido/:id/remover",
			newDetachedParcel: "api/contaReceber/salvarParcelaAvulso",
			newDetachedParcelToReceived: "api/contaReceber/salvarParcelaReceberAvulsa",
			removeParcel: "api/contaReceber/removerParcela",
			patientReport: "api/contaReceber/buildContasReceberPacienteReport",
			getItemsPaid: "api/contaReceber/getListaItensRecebidosByContaReceber/?contaReceberId=",
		},
		records: {
			dropdown: "api/tratamento/#id/dropdown",
			changeRecord:
				"api/tratamentoServico/all/servicosAutorizados/porPaciente/?tratamentoId=#treatmentId&pacienteId=",
			saveProcedure: "api/tratamentoServico/salvar",
		},
	},
	specialty: {
		listByConvenant: "api/especialidade/all/convenio?idConvenio=",
		filterBySpecialty:
			"api/convenio_especialidade_procedimento/all/especialidade?especialidadeId=#id&convenioId=",
	},
	professional: {
		active: "api/profissional/all/dentista/ativo",
	},
	operator: {
		active: "api/profissional/all/operador/ativo",
	},
	agenda: {
		findAll: "api/eventoAgenda/all/calendario",
		professionalConfig: "api/eventoAgenda/profissionalAgendaConfig",
		updateDateTime: "api/eventoAgenda/updateDataHora",
		typeService: "api/tipoAtendimento/dropdown",
		save: "api/eventoAgenda/save",
		delete: "api/eventoAgenda/",
		findOne: "api/eventoAgenda/findOne/",
		searchByParam: "api/eventoAgenda/relatorio/all",
		print: "api/eventoAgenda/relatorio/all/token",
		urlAgendaPdf: "/api/eventoAgenda/relatorio/all/export/?token=",
	},
	config: {
		financial: {
			category: "api/categoria/all",
			centerCost: "api/centroCusto/all",
			saveCCost: "api/centroCusto/save",
			saveCat: "api/categoria/save",
		},
		covenant: {
			table: "api/convenio/all",
			create:
				"api/convenio/obterNovoConvenio/?convenioNome=#name&isCopiaProcedimentos=#copy&convenioId=#id",
			findDefault: "api/convenio/findConvenioPadrao",
			save: "api/convenio/salvarNovoConvenio",
			update: "api/convenio/atualizar",
			getSpecialties: "api/especialidade/all/convenio?idConvenio=",
			getProcedures:
				"api/convenio_especialidade_procedimento/all/especialidade?especialidadeId=#specialtyId&convenioId=",
			deleteProcedure: "api/convenio_especialidade_procedimento/",
			updateProcedure: "api/convenio_especialidade_procedimento/update",
			saveProcedure: "api/procedimento/save",
		},
		comunication: {
			getSettings: "api/comunicacao/obterConfiguracaoComunicacao",
			setSettings: "api/comunicacao/save",
		},
		professional: {
			table: "/api/usuarioPerfilContaModulo/all",
			findOne: "/api/usuarioPerfilContaModulo/findOne/",
			dropdownProfessionals: "/api/perfilContaModulo/dropdown/profissionais",
			new: "/api/usuarioPerfilContaModulo/padroes/novo",
			save: "/api/usuarioPerfilContaModulo/save",
			delete: "/api/usuarioPerfilContaModulo/",
			resendMail: "/api/usuarioPerfilContaModulo/reenviarConvite",
		},
		prosthetic: {
			table: "api/protetico/all",
			save: "api/protetico/save",
			findOne: "api/protetico/findOne/",
			delete: "api/protetico/",
		},
		permissions: {
			dropdown: "api/perfilContaModulo/dropdown",
			permissions: "api/perfilContaModulo/#id/telas",
			update: "api/atualizar/acessos",
		},
	},
	tools: {
		prosthetic: {
			table: "api/servicoProtetico/all",
			findOne: "api/servicoProtetico/findOne/",
			dropdownProsthetic: "api/protetico/dropdown",
			save: "api/servicoProtetico/save",
			saveDropdown: "api/itemServicoProtetico/save",
			deleteDropdown: "api/itemServicoProtetico/",
			dropdownServices: "api/itemServicoProtetico/dropdown",
			dropdownTooth: "api/servicoProtetico/dropdown/emDente",
			dropdownPatient: "api/paciente/dropdown",
		},
	},
	financial: {
		common: {
			centerCostDropdown: "api/centroCusto/dropdown/descricao",
			categoryDropdown: "api/categoria/dropdown/descricao",
		},
		billsToPay: {
			save: "api/contaPagar/save",
			searchTable: "api/contaPagar/all",
			findOne: "api/contaPagar/findOne/",
			pay: "/api/contaPagar/pagar",
			reverse: "/api/contaPagar/cancelarPagamento",
			remove: "api/contaPagar/removerLancamento",
			getByProstheticService: "api/contaPagar/getContaPagarServicoProtetico/",
		},
		billsToReceave: {
			searchTable: "api/contaReceber/all",
			reverse: "/api/contaReceber/cancelarPagamento",
		},
		commission: {
			table: "api/comissao/all",
			authorize: "api/comissao/autorizar/pagamentos",
			unauthorize: "api/comissao/desautorizar/pagamentos",
			pay: "api/comissao/pagar",
		},
	},
	zipCode: "open/endereco/por/cep",
};
