import { Component, OnDestroy, Output } from '@angular/core';
import { LoginApiService } from '../../../api/login-api.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { flatMap, map, shareReplay, startWith } from 'rxjs/operators';
import { ContaUserModel } from '../../../core/models/user/user.model';
import { ContaApiService } from '../../../api/conta-api.service';
import { LoginService } from '../../../auth/login.service';
import { NotificationsService } from 'angular2-notifications';
import { errorToString } from '../../../lib/helpers/error-to-string';
import { UtilsService } from '../../../services/utils/utils.service';

export const DEFAULT_AVATAR_PROFILE_PATH = 'assets/images/avatar-blank.jpg';

@Component({
	selector: 'app-account-chooser',
	templateUrl: './account-chooser.component.html',
	styleUrls: ['./account-chooser.component.scss'],
})
export class AccountChooserComponent implements OnDestroy {

	private readonly _subs = new Subscription();
	private readonly _refreshSubject = new Subject<void>();

	readonly contas: Observable<ContaUserModel[]> = this._refreshSubject.pipe(
			startWith(undefined),
			flatMap(() => this._loginApiService.encontraContas()),
			flatMap(contas => Promise.all(contas.map(this._resolveImagemDeConta))),
			shareReplay(1),
	);

	readonly contasExceptCurrent = this.contas.pipe(
			map(contas => contas.filter(conta => !this.isCurrentConta(conta))),
			shareReplay(1),
	);

	private readonly _resolveImagemDeConta = async (
			conta: ContaUserModel,
	): Promise<ContaUserModel> => {
		const { urlImagem } = await this._contaApiService.fetchContaCompleta(
				conta.contaId,
		);

		return {
			...conta,
			perfilContaModuloContaUrlImagem: urlImagem,
		};
	};

	@Output()
	readonly currentConta = this.contas.pipe(
			map(contas => contas.find(conta => this.isCurrentConta(conta))),
			shareReplay(1),
	);

	constructor(
			private readonly _router: Router,
			private readonly _activatedRoute: ActivatedRoute,
			private readonly _loginApiService: LoginApiService,
			private readonly _contaApiService: ContaApiService,
			private readonly _loginService: LoginService,
			private readonly _notificationsService: NotificationsService,
			private readonly _utilsService: UtilsService,
	) {
		this._subs.add(this._loginService.contaChange$.subscribe(this._refreshSubject));
	}

	ngOnDestroy(): void {
		this._subs.unsubscribe();
	}

	async changeConta(conta: ContaUserModel) {
		try {
			const changed = await this._loginService.escolherConta(
					conta.usuarioPerfilContaModuloId,
			);

			if (changed) {
				if (this._router.url === '/dashboard/default') {
					location.reload()
				} else {
					this._router.navigate(['/']);
				}
			}
		} catch (e) {
			this._notificationsService.error('Erro ao alterar conta', errorToString(e));
		}
	}

	isCurrentConta(conta: ContaUserModel): boolean {
		const { currentConta } = this._loginService;
		const currentId = currentConta && currentConta.id;

		return conta.usuarioPerfilContaModuloId === currentId;
	}

	buildCssAvatarUrl(url: string | null | undefined): string {
		return `url(${url || DEFAULT_AVATAR_PROFILE_PATH})`;
	}

	podeConfigurarConta(): boolean {
		return this._utilsService.checkPermission(8, true);
	}

}
