import { BehaviorSubject } from "rxjs";
import { __, add, gt, once, bind, partial, pipe } from "ramda";
import { map, distinctUntilChanged } from "rxjs/operators";

export interface LoadingToken {
	finished(): void;
}

export class LoadingManager {
	private readonly _countSubject = new BehaviorSubject(0);

	readonly loadingChange$ = this._countSubject.pipe(map(gt(__, 0)), distinctUntilChanged());

	private _updateCounter = (fn: (current: number) => number): void =>
		this._countSubject.next(fn(this._countSubject.value));

	start(): LoadingToken {
		this._updateCounter(add(1));

		return {
			finished: once(partial(this._updateCounter, [add(-1)])),
		};
	}

	wrapPromise<P extends Promise<any>>(promise: P): P {
		const loadingToken = this.start();
		promise.then(loadingToken.finished, loadingToken.finished);
		return promise;
	}
}
