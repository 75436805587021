export const ROUTERS = {
  LOGIN: 'auth/login/basic',
  LOCK: 'auth/lock-screen',
  DASHBOARD: 'dashboard/default',
  PATIENTS: {
    LIST: 'pacientes',
    FORM: 'pacientes/form'
  },
  FINANCIAL: {
    BILLS_TO_PAY: {
      LIST: 'financial/bills-to-pay'
    }
  },
  CONFIG: {
    COVENANT: {
      FORM: 'config/covenant/form'
    }
  }
};
