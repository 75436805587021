import { Injectable } from "@angular/core";
import { ROUTERS } from "../../core/constants/routers/routers";

export interface BadgeItem {
	type: string;
	value: string;
}

export interface ChildrenItems {
	state: string;
	target?: boolean;
	name: string;
	type?: string;
	children?: ChildrenItems[];
	permission?: any;
}

export interface MainMenuItems {
	state: string;
	short_label?: string;
	main_state?: string;
	target?: boolean;
	name: string;
	type: string;
	icon: string;
	badge?: BadgeItem[];
	children?: ChildrenItems[];
	permission?: any;
}

export interface Menu {
	label: string;
	main: MainMenuItems[];
}

const MENUITEMS = [
	{
		label: "Menu",
		main: [
			{
				state: "dashboard",
				short_label: "D",
				name: "Dashboard",
				type: "sub",
				icon: "ci icon-dashboard",
				children: [
					{
						state: "default",
						name: "Default",
					},
					{
						state: "ecommerce",
						name: "Ecommerce",
					},
					{
						state: "analytics",
						name: "Analytics",
						badge: [
							{
								type: "info",
								value: "NEW",
							},
						],
					},
				],
			},
			{
				state: "agenda",
				short_label: "A",
				name: "Agenda",
				type: "link",
				icon: "ci icon-calendar",
				permission: 4,
			},
			{
				state: ROUTERS.PATIENTS.LIST,
				short_label: "A",
				name: "Pacientes",
				type: "link",
				icon: "ci icon-patients",
				permission: 2,
			},
			{
				state: "produtos",
				short_label: "A",
				name: "Estoque",
				type: "link",
				icon: "ci icon-bill",
				permission: 8,
			},
			{
				state: "financial",
				short_label: "F",
				name: "Financeiro",
				type: "sub",
				icon: "ci icon-finance",
				permission: 6,
				children: [
					{
						state: "bills-to-pay",
						name: "Contas a Pagar",
						permission: 12,
					},
					{
						state: "bills-to-receive",
						name: "Contas a Receber",
						permission: 13,
					},
					{
						state: "fluxo-caixa",
						name: "Fluxo de Caixa",
						// TODO: Adicionar permissão correta
						permission: 27,
					},
					{
						state: "sell-report",
						name: "Relatório de Vendas",
						// TODO: Adicionar permissão correta
						permission: 28,
					},
					{
						state: "commission",
						name: "Comissão",
						permission: 14,
					},
				],
			},
			{
				state: "reports",
				short_label: "R",
				name: "Relatórios",
				type: "sub",
				icon: "icofont icofont-law-document",
				permission: 9,
				children: [
					{
						state: "patients-report",
						name: "Pacientes",
						permission: 20,
					},
					{
						state: "birthday-report",
						name: "Aniversariantes",
						permission: 25,
					},
					{
						state: "age-range-report",
						name: "Faixa Etária",
						permission: 26,
					},
					{
						state: "receipts-report",
						name: "Recibos",
						permission: 21,
					},
					{
						state: "treatments-report",
						name: "Tratamentos",
						permission: 22,
					},
					{
						state: "indications-report",
						name: "Indicações",
						permission: 23,
					},
					{
						state: "schedulings-report",
						name: "Agendamentos",
						permission: 24,
					},
				],
			},
			{
				state: "tools",
				short_label: "C",
				name: "Ferramentas",
				type: "sub",
				icon: "ci icon-tools",
				permission: 7,
				children: [
					{
						state: "prosthetic",
						name: "Serviços Protético",
						permission: 15,
					},
				],
			},
			{
				state: "config",
				short_label: "C",
				name: "Configuração",
				type: "sub",
				icon: "ci icon-settings",
				permission: [1, 2, 3, 4, 29, 17, 18, 19],
				children: [
					{
						state: "covenant",
						name: "Convênio",
						permission: 1,
					},
					{
						state: "comunication",
						name: "Comunicação",
						permission: 29,
					},
					{
						state: "permissions",
						name: "Permissões",
						permission: 2,
					},
					{
						state: "professionals",
						name: "Profissionais",
						permission: 3,
					},
					{
						state: "medical-record",
						name: "Prontuário",
						type: "sub",
						permission: [17, 18, 19],
						children: [
							{
								state: "anamnese",
								name: "Anamnese",
								permission: 17,
							},
							{
								state: "document-model",
								name: "Modelo de Documento",
								permission: 18,
							},
							{
								state: "print",
								name: "Impressão",
								permission: 19,
							},
						],
					},
					{
						state: "prosthetic",
						name: "Protético",
						permission: 4,
					},
					/*          {
            state: 'financial',
            name: 'Financeiro',
            type: 'sub',
            icon: 'ci icon-finance',
            permission: 5,
            children: [
              {
                state: 'centerCost',
                name: 'Centro de Custo',
                permission: 10,
              },
              {
                state: 'category',
                name: 'Categoria',
                permission: 11,
              },
            ],
          },*/
				],
			},
		],
	},
];

@Injectable()
export class MenuItems {
	getAll(): Menu[] {
		return MENUITEMS;
	}
}
