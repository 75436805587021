import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AutoSizedDatatableService {

  readonly recalculate = new Subject<void>();

  recalcule() {
    this.recalculate.next();
  }

}
