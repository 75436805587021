export const translationPt = {
	COMMON: {
		footerMessage: {
			emptyMessage: "Nenhum registro para mostrar",
			totalMessage: "registros encontrados",
		},
		LOADING: "Carregando...",
		CLOSE: "Fechar",
		ERROR: {
			SEARCH: "Erro na consulta...",
			SEARCH_INFO: "Algo deu errado ao consultar servidor!",
		},
		GENDER: {
			MAN: "Masculino",
			WOMAN: "Feminino",
		},
		YESNO: {
			YES: "SIM",
			NO: "NÃO",
		},
		REFRESH: "Atualizar",
		RANGE: "Período",
		REQUIRED: "Campo Obrigatório!",
		MORE_THAN_ZERO: "O valor deve ser maior que zero.",
		PLACEHOLDER: "Selecione uma opção",
		NORESULT: "Nenhum item",
		PER_PAGE: "por Página",
		CURRENCY_SYMBOL: "R$",
		THOUSANDS: ".",
		DECIMAL: ",",
		LOCALE: "pt-BR",
		SAVE: "Salvar",
		NEW: "Novo",
		DELETE: "Excluir",
		BACK: "Fechar",
		CANCEL: "Cancelar",
		APPLY: "Aplicar",
		ADD: "Adicionar",
		APPLY_FILTER: "Aplicar Filtro",
		ALL: "Todos",
		REMOVE_ALL_FILTERS: "Remover filtros",
		SELECT_ALL: "Marcar todos",
		UNSELECT_ALL: "Desmarcar todos",
		INIT_DATE: "Data inicial",
		END_DATE: "Data final",
		EDIT_REGISTER: "Editar esse registro",
		VIEW_REGISTER: "Ver esse registro",
		DELETE_REGISTER: "Apagar esse registro",
		PRINT_BTN: "Imprimir",
		DOWNLOAD_BTN: "Download",
		PLACEHOLDER_DATE: "DIA/MÊS/ANO",
		FIND_BY: "Buscar por",
	},
	FORGOT: {
		TITLE: "Recuperação de senha",
		BACK: "Voltar para o",
		RECOVERY: "Enviar e-mail",
		EMAIL: "Informe seu e-mail",
		SUCCESS: {
			TITLE: "Recuperação de senha",
			SUBTITLE: "E-mail enviado com sucesso.",
		},
	},
	LOGIN: {
		TITLE: "Acesse sua conta",
		SIGNIN: "LOGAR",
		LOGIN: "LOGANDO...",
		FORGOT: "Esqueceu sua senha?",
		ERROR: {
			TITLE: "ERRO LOGIN",
			INFO: "Algo deu errado ao realizar login",
		},
		CHOOSE_ACCOUNT: "Qual clínica você deseja acessar agora?",
	},
	ADMIN: {
		LOGOUT: "Sair",
	},
	PATIENTS: {
		TITLE: "PACIENTES",
		NEW: "NOVO",
		ALERTS: "Alertas",
		TABLE: {
			COL1: "Ações",
			COL2: "Nome",
			COL3: "Prontuário",
			COL4: "Celular",
			COL5: "Telefone",
		},
		TREATMENT_PRINT: {
			TITLE: "Impressão de Orçamento",
			PRINT_IMAGE: "Imprimir Odontograma",
			PRINT_TOTAL_VALUE: "Imprimir somente valor total",
		},
		SWAL: {
			TITLE: "APAGAR PACIENTE",
			TEXT: "Você deseja realmente apagar o paciente selecionado?",
			CONFIRM_BUTTON: "SIM, Apague",
			CANCEL_BUTTON: "NÃO, Cancele",
		},
		FORM: {
			MARITAL: [
				{
					label: "Solteiro", // just translate label
					value: "SOLTEIRO", // don't translate this...
				},
				{
					label: "Casado",
					value: "CASADO",
				},
				{
					label: "Separado",
					value: "SEPARADO",
				},
				{
					label: "Divorciado",
					value: "DIVORCIADO",
				},
				{
					label: "Viúvo",
					value: "VIUVO",
				},
			],
			INDICATIONS: [
				{
					label: "Central de Marcações",
					value: "CENTRAL_DE_MARCACAO",
				},
				{
					label: "Campanha de Marketing",
					value: "CAMPANHA_DE_MARKETING",
				},
				{
					label: "Facebook",
					value: "FACEBOOK",
				},
				{
					label: "Google",
					value: "GOOGLE",
				},
				{
					label: "Indicação Amigo",
					value: "INDICACAO_AMIGO",
				},
				{
					label: "Indição Outro Profissional",
					value: "INDICACAO_OUTRO_PROFISSIONAL",
				},
				{
					label: "Jornal",
					value: "JORNAL",
				},
				{
					label: "Outdoor",
					value: "OUTDOOR",
				},
				{
					label: "Rádio",
					value: "RADIO",
				},
				{
					label: "TV",
					value: "TV",
				},
				{
					label: "Outros",
					value: "OUTROS",
				},
				{
					label: "Instagram",
					value: "INSTAGRAM",
				},
				{
					label: "Indicação familiar",
					value: "INDICACAO_FAMILIAR",
				},
			],
			TABLE_TREATMENT: {
				COL1: "Ações",
				COL2: "Trat.",
				COL3: "Descrição",
				COL4: "Valor",
				COL5: "Abertura",
				COL6: "Situação",
				COL7: "Convênio",
			},
			listaStatusTratamento: [
				{
					label: "Aguardando Aprovação",
					value: "AGUARDANDO_APROVACAO",
				},
				{ label: "Em Tratamento", value: "EM_TRATAMENTO" },
				{ label: "Finalizado", value: "FINALIZADO" },
			],
			SWAL: {
				TITLE: "EDIÇÃO DE TRATAMENTOS",
				TITLE_CHOOSE: "ESCOLHA UMA OPÇÃO DE CAPTURA",
				TEXT:
					"Existe um tratamento iniciado para esse paciente, NÃO SALVO, deseja retomar a edição?",
				CONFIRM_BUTTON: "SIM, Desejo!",
				CANCEL_BUTTON: "NÃO, Inicie um novo...",
			},
			MEDICAL_RECORDS: "Prontuário",
			PERSONAL: "Dados",
			DOCUMENTS: "Imagens",
			ANAMNESE: "Anamnese",
			EMIT_DOCUMENTS: "Documentos",
			ANNOTATIONS: "Anotações",
			TREATMENT: "Orçamentos",
			RECORDS: "Prontuário",
			BILLING: "Conta Corrente",
			CUT_IMAGE: "APLICAR",
			GALERY: "GALERIA",
			CAM: "CÂMERA",
			NEW: "Ficha de novo paciente...",
			REGISTER: "Cadastro",
			ADDRESS: "Endereço",
			AGREEMENT: "Convênio",
			RESPONSIBLE: "Responsável",
			ADDITIONAL_DATA: "Dados Adicionais",
			NEW_TREATMENT: "Novo Tratamento",
			CEP_INEXISTENTE: "CEP não encontrado",
			pessoaNome: "Nome do Paciente",
			pessoaSexo: "Sexo",
			pessoaEstadoCivil: "Estado Civil",
			pessoaDataNascimento: "Data de Nascimento",
			pessoaCpfCnpj: "CPF",
			pessoaRgIe: "RG",
			pessoaEmissorRgIe: "Orgão Emissor",
			pessoaCelular: "Celular",
			pessoaTelefone: "Telefone",
			pessoaEmail: "E-mail",
			pessoaCep: "CEP",
			pessoaEndereco: "Endereço",
			pessoaNumeroEndereco: "Número",
			pessoaBairro: "Bairro",
			pessoaCidade: "Cidade",
			pessoaUfCidade: "Estado",
			complemento: "Complemento",
			convenioNome: "Convênio",
			isTitularConvenio: "Titular?",
			numeroCarteirinha: "Nº da Carteirinha",
			vencimentoCarterinha: "Vcto. Carteirinha",
			pessoaNomeResponsavel: "Nome",
			pessoaDataNascimentoResponsavel: "Data de Nascimento",
			pessoaCelularResponsavel: "Celular",
			pessoaCpfResponsavel: "CPF",
			pessoaRgResponsavel: "RG",
			pessoaCelular2: "Celular 2",
			emIndicacao: "Indicação",
			descricaoIndicacao: "Descrição da Indicação",
			pessoaObservacao: "Observações gerais",
			pessoaProfissaoId: "Profissão",
			situacaoId: "Situação",
			empresaId: "Empresa",
			especialidadesPaciente: "Especialidade",
			profissionalId: "Profissional Responsável",
		},
		FORM_TREATMENT: {
			PROCEDURES: "Procedimentos",
			TREATMENT_SAVE: "Tratamento salvo com sucesso",
			descricaoDefault: "Tratamento Odontológico",
			descricao: "Descrição",
			convenioId: "Convênio",
			dataAbertura: "Abertura",
			guiaConvenio: "Nº da Guia",
			profissionalId: "Profissional",
			operadorVendedorId: "Vendedor",
			statusTratamento: "Status",
			formaPagamento: "Forma Pagamento",
			formaDeRecebimento: "Forma Recebimento",
			tipoDesconto: "Tipo Desconto",
			valorDesconto: "Valor Desconto",
			numeroTotalParcelas: "Parcelas",
			dataVencimento: "Data Vencimento",
			INIT_TREATMENT: "Iniciar Orçamento",
			NEW_PROCEDURE: "Novo Procedimento",
			APPROVE: "Autorizados",
			DESAPPROVE: "Não Autorizados",
			LISTA_STATUS_TRATAMENTO: {
				AGUARDANDO_APROVACAO: "Aguardando Aprovação",
				EM_TRATAMENTO: "Em Tratamento",
				FINALIZADO: "Finalizado",
			},
			LISTA_FORMA_PAGAMENTO: {
				A_VISTA: "A vista",
				A_PRAZO: "A prazo",
				NUM_PROCEDIMENTO: "Número de procedimentos",
			},
			LISTA_TIPO_DESCONTO: {
				MOEDA: "R$",
				PORCENTAGEM: "%",
			},
			TABLE_FINANCIAL: {
				COL1: "Ações",
				COL2: "Vencimento",
				COL3: "Parcela",
				COL4: "Valor",
				COL5: "Descrição",
				COL6: "Recebimento",
			},
			TABLE_PROCEDURES: {
				COL1: "Ações",
				COL2: "Procedimento",
				COL3: "Dente",
				COL4: "Profissional",
				COL5: "Valor",
				COL6: "Situação",
			},
			APPROVE_BUDGET: "Aprovar Orçamento",
			SAVE_BUDGET: "Salvar Orçamento",
			DESAPPROVE_BUDGET: "Desaprovar Orçamento",
			APPROVE_REGISTER: "Autorizar esse registro",
			DISAPPROVE_REGISTER: "Não autorizar esse registro",
			SWAL: {
				TITLE: "ESTÁ CERTO DISSO?",
				TEXT_DISAPPROVE: "Você confirma que este procedimento NÃO DEVE ser autorizado?",
				TEXT_APPROVE: "Você confirma que este procedimento DEVE ser autorizado?",
				TEXT_DELETE: "Você confirma a REMOÇÃO deste procedimento?",
				TEXT_DELETE_TREATMENT: "Você confirma a EXCLUSÃO deste orçamento?",
				TEXT_DISAPPROVE_TREATMENT: "Você confirma a DESAPROVAÇÃO do orçamento?",
				CONFIRM_BUTTON: "SIM, Confirmo",
				CANCEL_BUTTON: "NÃO, Cancele",
			},
			FINANCIAL: "Financeiro",
			RESUME: {
				valorComDesconto: "Valor Final",
				valorDesconto: "Desconto",
				valorTotal: "Valor Total",
			},
			footerMessageProcedures: {
				zero: {
					totalMessage: "Procedimentos - Aguardando solicitação",
				},
				approved: {
					totalMessage: "Procedimento(s) Autorizado(s)",
				},
				desapproved: {
					totalMessage: "Procedimento(s) Não Autorizado(s)",
				},
			},
			footerMessageFinancial: {
				zero: {
					totalMessage: "Parcelas - Aguardando financeiro",
				},
				totalMessage: "Parcela(s)",
			},
			EDIT_PROCEDURE: {
				TITLE: "Edição de Procedimento",
				PROCEDURE: "Procedimento",
				TEETH: "Dente/Região",
			},
		},
		DOCUMENT: {
			ADD: "Adicionar",
			TAKE_PHOTO: "Tirar foto por câmera",
			SINGLE_PHOTO: "Foto única",
			MULTIPLES_PHOTOS: "Múltiplas fotos",
			DELETE: {
				TITLE: "Excluir documento",
				CONFIRM: "Sim, excluir",
				DECLINE: "Não",
				MESSAGE: "Deseja realmente excluir o documento",
			},
			EDIT: {
				TITLE: "Editar documento",
				CONFIRM: "Salvar",
				CONFIRM_ALL: "Salvar todos",
				DECLINE: "Voltar",
				NOTE: "Observação",
				DESCRIPTION: "Descrição",
			},
			SAVE: {
				TITLE: "Novos documentos",
				MORE: "Salvar e adicionar continuar adicionando",
				REMOVE: "Remover",
				MESSAGE: "Clique aqui para selecionar, ou arraste um ou mais documentos",
				CAMERA_UPLOAD_SHOT: "Capturar Imagem",
				CAPTURES: "Imagens Capturadas",
				CAMERA: "Câmera",
				REPEAT: "Repetir",
			},
			VISUALIZE: {
				TITLE: "Visualizar documento",
			},
			DOWNLOAD: {
				TITLE: "Baixar documento",
			},
		},
		EMIT_DOCUMENTS: {
			TITLE: "Documentos",
			HEADER: {
				EMIT_DOCUMENT: "Emitir Documento",
				EMIT_PRESCRIPTION: "Emitir Receituário",
			},
			TABLE: {
				ACTIONS: "Ações",
				NAME: "Nome do Modelo",
				PRESCRIPTION_TYPE: "Tipo do Receituário",
				TYPE: "Tipo",
				CREATE_AT: "Data Criação",
			},
			LABELS: {
				CREATE: "Cadastrar novo Medicamento",
				DATE: "Data",
				PROFESSIONAL: "Profissinal",
				MODEL: "Modelos de Atestados e Documentos",
				SIMPLE_PRESCRIPTION: "Receituário Simples",
				ESPECIAL_PRESCRIPTION: "Receituário Especial",
				MEDICINE_NAME: "Nome do medicamento",
				QTD: "Quantidade",
				MEASURE: "Medida",
				DOSAGE: "Posologia",
				ADD: "Incluir",
			},
			FOOTER: {
				EMIT: "Emitir",
			},
		},
		DETACHED_TREATMENT: {
			TITLE: "Adicionar Tratamento",
			COVENANT: "Convênio",
			PROCEDURE: "Procedimento",
			TOOTH: "Dente",
			VALUE: "Valor",
			PROFESSIONAL: "Profissional",
			FACES: "Seleciona a(s) face(s)",
		},
		MODAL_PROCEDURE: {
			TITLE: "Novo Procedimento",
			TABLE_SPECIALTIES: {
				COL1: "Especialidade",
			},
			TABLE_PROCEDURES: {
				COL1: "Procedimentos",
			},
			TABLE_TOOTH: {
				COL1: "Dentes",
			},
			STATUS_TREATMENT: {
				REALIZAR: "A Realizar",
				FINALIZADO: "Finalizado",
				INICIADO: "Iniciado",
				OBSERVADO: "Observado",
				NAO_AUTORIZADO: "Não Autorizado",
			},
			LIST_FACE: {
				isFaceDistal: "D",
				isFaceOclusalIncisal: "O/I",
				isFaceLingualPalatina: "L/P",
				isFaceMesial: "M",
				isFaceVestibular: "V",
			},
			TOOTHS: "Dentes",
			VALUE: "Valor",
			PROFESSIONAL: "Profissional",
			STATUS: "Status",
			ADD: "Incluir",
			ADD_AND_NEW: "Incluir e Novo",
			FACES: "Selecione a(s) face(s)",
			SWAL: {
				TITLE: "MÚLTIPLAS FACES",
				TEXT: "Multiplicar o valor pelo número de faces?",
				CONFIRM_BUTTON: "SIM",
				CANCEL_BUTTON: "NÃO",
			},
		},
		MODAL_EDIT_PARCELA: {
			TITLE: "Editando parcela",
		},
		FORM_RECORDS: {
			ADD_TREATMENT: "Adicionar Tratamento",
			TITLE: "Prontuário",
			FINISH: "Finalizar este procedimento",
			DIAGNOSTIC: "Diagnóstico",
			OBSERVATIONS: "Observações",
			MORE: "Mais dados desse registro",
			RESUME: "Resumo deste tratamento",
			PAYMENT_MADE: "PAGO",
			TOTAL_CLIENTE: "Total em tratamentos",
			TOTAL_TREAT: "Total do tratamento",
			TOTAL_TREAT_MIN: "Total Trat.",
			TOTAL_RECEIVED: "Total recebido",
			TOTAL_REMAINING: "Total a pagar",
			selectProntuario: "Tratamento",
			REPORT: "Relatório",
			PROMISSORY: "Promisória",
			BOOKLET: "Carnê",
			PARCEL_DOCUMENT: {
				PATIENT: {
					TITLE: "Dados do Paciente",
					NAME: "Nome *",
					CPF: "CPF *",
					ADDRESS: "Endereço *",
					NEIGHBORHOOD: "Bairro *",
					ZIP_CODE: "CEP *",
				},
				CLINIC: {
					TITLE: "Dados da Clínica",
					NAME: "Nome/Razão Social *",
					CPF_CNPJ: "CPF/CNPJ *",
				},
				ALERT_MESSAGE:
					"Atenção! Antes de emitir, certifique-se de que os dados do paciente estão preenchidos.",
			},
			DETACHED_PARCEL: {
				TITLE: "Parcela Avulsa",
				ADD: "Adicionar Parcela Avulsa",
				PATIENT: "Paciente",
				PARCEL_TYPE: "Tipo da Parcela",
				DESCRIPTION: "Descrição",
				VALUE: "Valor",
				DATE: "Data de Vencimento",
				COVENANT: "Convênio",
				PAYMENT_METHOD: "Forma de Pagamento",
				REPEAT_DETEACHED_PARCEL: "Repetir essa parcela",
				OCURRENCY_N: "Nº Ocorrências",
			},
			TABLE_PROCEDURES: {
				COL1: "Ações",
				COL2: "Procedimento",
				COL3: "Dente",
				COL4: "Profissional",
				COL5: "Valor",
				COL6: "Situação",
				COL7: "Finalizado",
			},
			footerMessageProcedures: {
				zero: { totalMessage: "Procedimentos" },
				approved: { totalMessage: "Procedimento(s)" },
			},
			SWAL: {
				TITLE: "FINALIZAR PROCEDIMENTO",
				TEXT: "Deseja realmente finalizar esse procedimento?",
				CONFIRM_BUTTON: "SIM",
				CANCEL_BUTTON: "NÃO",
			},
		},
		MODAL_EDIT_RECORD: {
			TITLE: "Editando procedimento",
			procedure: "Procedimento",
			professional: "Profissional",
			tooth: "Dente",
			status: "Status",
			finish: "Fechamento",
			diagnostic: "Diagnóstico",
			obs: "Observação",
		},
		FORM_CHECKING_ACCOUNT: {
			selectProntuario: "Tratamento",
			RECEIVE: "Receber pagamento",
			REVERSE_TITLE: "Estorno de parcela",
			REVERSE_MSG: "Devolução realizada com sucesso!",
			TABLE_QUOTA: {
				COL1: "Ações",
				DESCRIPTION: "Descrição",
				COL2: "Parcela",
				COL3: "Vencimento",
				COL4: "Valor",
				COL5: "Recebido",
				COL6: "Forma Recebimento",
				COL7: "Status",
			},
			TABLE_DETAILS: {
				COL1: "Ações",
				COL2: "Recebido em",
				COL3: "Valor",
				COL4: "Forma de recebimento",
			},
			STATUS_LIST: {
				ON_DATE: "A vencer",
				TODAY: "Dia do vencimento",
				RECEIVED_ALL: "Pago",
				RECEIVED_PARTIAL: "Pago parcialmente",
				LATE_PAYMENT: "Vencido",
			},
			footerMessageProcedures: {
				zero: { totalMessage: "Parcelas" },
				parcel: { totalMessage: "Parcela(s)" },
			},
			reverse: "Estornar o valor pago",
			SWAL: {
				TITLE: "ESTONRO DE PARCELA",
				TEXT: "Deseja realmente estornar a parcela selecionada?",
				CONFIRM_BUTTON: "SIM, Estorne!",
				CANCEL_BUTTON: "NÃO, Cancele...",
			},
		},
		MODAL_PAYMENT: {
			DESCRIPTION: "Descrição",
			TITLE: "Pagamento de parcela",
			parcel: "Parcela",
			paymentDateLimit: "Data de vencimento",
			paymentDate: "Data do pagamento",
			amount: "Valor da parcela",
			amountPaid: "Valor pago",
			amountRemaining: "Valor restante",
			status: "Status",
			dataBomPara: "Bom para",
			titular: "Titular",
			numCheque: "Cheque número",
			banco: "Banco",
			agencia: "Agência",
			chequeConta: "Conta",
			cpfCnpj: "CPF ou CNPJ",
			observacao: "Observçaões",
			payAmount: "Pagamento",
			pay: "Pagar",
		},
		MODAL_EDIT_PARCEL_ACCOUNT: {
			TITLE: "Editando parcela",
			dataVencimento: "Data de vencimento",
		},
	},
	AGENDA: {
		TITLE: "Agenda",
		ALL_PROFESSIONALS: "Todos os profissionais",
		SUCCESS: "Evento salvo com sucesso!",
		SUCCESS_DELETE: "Evento removido com sucesso!",
		PROFESSIONAL: "Profissionais",
		TODAY: "HOJE",
		WEEK: "SEMANA",
		DAY: "DIA",
		PRINT_BTN: "IMPRIMIR",
		NEW: "Novo agendamento",
		CALENDAR_BTN: "AGENDA",
		RETURN_ALERT_BTN: "ALERTA DE RETORNO",
		SEARCH_BTN: "PESQUISAR",
		SCHEDULING: "Agendamento",
		COMMITMENT: {
			TITLE: "Compromisso",
			LABELS: {
				DESCRIPTION: "Descrição do Compromisso",
				ALL_DAY: "Dia inteiro",
				REPEAT: "Repetir esse compromisso",
				SAVE: "Criar compromisso",
				INITIAL_DATE: "Data Inicial",
				FINAL_DATE: "Data Final",
			},
		},
		TABS: {
			CURRENT_SCHEDULING: "Agendamento atual",
			SCHEDULING_HISTORY: {
				TITLE: "Últimos agendamentos",
				DATE: "Data",
				PROCEDURE: "Procedimento",
				PROFESSIONAL: "Profissional",
				STATUS: "Status",
			},
		},
		SWAL: {
			TITLE: "AGENDAMENTOS",
			TEXT: "Este não é um horário de atendimento da clínica, deseja continuar?",
			TEXT_DELETE: "Deseja realmente excluir este evento?",
			CONFIRM_BUTTON: "SIM, Continue!",
			CONFIRM_BUTTON_DELTE: "SIM, Exclua!",
			CANCEL_BUTTON: "NÃO, Cancele...",
		},
		STATUS: {
			MARCADO: "Marcado",
			REMARCADO: "Remarcado",
			AGUARDANDO: "Aguardando",
			CONFIRMADO: "Confirmado",
			ATENDIDO: "Atendido",
			EM_ATENDIMENTO: "Em atendimento",
			FALTOU: "Faltou",
			PACIENTE_DESMARCOU: "Desmarcado pelo paciente",
			PROFISSIONAL_DESMARCOU: "Desmarcado pelo profissional",
		},
		FORM: {
			pacienteId: "Paciente",
			profissionalId: "Profissional",
			convenioId: "Convênio",
			celular: "Celular",
			telefoneFixo: "Fixo",
			email: "E-mail",
			dataHoraInicialEvento: "Data e hora",
			duracao: "Duração (min)",
			emStatusAgendamento: "Status",
			anotacoes: "Anotações",
			tipoAtendimentoId: "Tipo atendimento",
			pacienteCodigoProntuario: "N° do Prontuário",
		},
		RETURN_ALERT: {
			TITLE: "Alertas de Retorno",
			CREATE: {
				TITLE: "Alerta de Retorno",
				PROFESSIONAL: "Profissional",
				PATIENT: "Paciente",
				REASON: "Motivo",
				OBS: "Observação",
				RETURN_AT: "Retornar em",
				OR_AT: "ou em:",
			},
			EXIBITION: "Exibindo",
			INITIAL_DATE: "Data Inicial",
			EXPIRED_INITIAL_DATE: "A Partir de",
			FINAL_DATE: "Data Final",
			ADD: "Adicionar Alerta",
			TABLE: {
				DENTIST: "Dentista:",
				REASON: "Motivo:",
				RETURN: "Retorno Previsto",
				SCHEDULE: "Agendar",
				REMOVE: "Excluir",
				EDIT: "Editar",
			},
			WHATSAPP_TOOLTIP: "Enviar um WhatsApp para o paciente",
			NO_DATA_MESSAGE: "Nao há Alertas de Retorno cadastrados.",
		},
		SEARCH: {
			TITLE: "Pesquisa de agendamentos",
			SEARCH_DATE: "A partir dessa data",
			PATIENT: "Paciente",
		},
		TABLE: {
			COL1: "Ações",
			COL2: "Data e hora",
			COL3: "Paciente",
			COL4: "Celular",
			COL5: "Profissional",
			COL6: "Tipo",
			COL7: "Status",
		},
		PRINT: {
			TITLE: "Impressão de agenda",
			DATE_INIT: "Data inicial",
			DATE_END: "Data final",
			PRINT: "Imprimir",
		},
		POPOVER: {
			LABELS: {
				N: "Nº",
				CELLPHONE: "Telefone",
				SCHEDULE: "Horário",
			},
			SCHEDULING: {
				DELETE: "Excluir agendamento",
				EDIT: "Editar",
			},
		},
	},
	CONFIG: {
		FINANCIAL: {
			TITLE: {
				centerCost: "Centros de custo",
				category: "Categorias",
			},
			ADD_CAT: "Nova categoria",
			ADD_CCOST: "Novo c. custo",
			ADD_CAT_CHILD: "Adicionar uma categoria filha a ",
			ADD_CCOST_CHILD: "Adicionar uma centro de custo filho a ",
			MODAL: {
				CAD_CAT: "Formulário de categoria",
				CAD_CCOST: "Formulário de categoria",
				DESCRIPTION: "Descrição",
				ACTIVE: "Ativo",
			},
			ACTIVE: "Item ativo",
			DEACTIVE: "Item inativo",
			SWAL: {
				TITLE_TOGGLE: "Mudança de estado de item",
				TITLE_DELETE: "Excluindo item",
				TEXT_TOGGLE_ACTIVE: "Deseja realmente ativar este item?",
				TEXT_TOGGLE_DEACTIVE: "Deseja realmente desativar este item?",
				TEXT_DELETE: "Deseja realmente remover este item?",
				CONFIRM_BUTTON: "SIM",
				CANCEL_BUTTON: "NÃO",
			},
		},
		COVENANT: {
			TITLE: "Convênios",
			COPY_TOOLTIP: "Copiar esse registro",
			TABLE: {
				COL1: "Ações",
				COL2: "Nome",
				COL3: "Padrão",
			},
			CREATE: {
				TITLE: "Novo convênio",
				convenioNome: "Nome",
				convenioId: "Copiar de",
				isCopiaProcedimentos: "Copiar procedimento?",
				CREATE_BTN: "Criar",
			},
			FORM: {
				nome: "Nome",
				TITLE: "Cadastro de convênio",
				ADD_PROC: "Adicionar procedimento",
				SPECIALTIES: "Especialidades",
				PROCEDURES: "Procedimentos",
				procedimentoNome: "Procedimento",
				preco: "Valor",
				procedimentoPorcentagemComissao: "% Comissão",
				procedimentoValorCustoProtetico: "Protético",
				SAVE: {
					TITLE: "CONVÊNIOS",
					SUB: "Convênio salvo com sucesso!",
				},
				SAVE_PROCEDURE: {
					TITLE: "EDIÇÃO DE CONVÊNIOS",
					SUB: "Procedimento alterado com sucesso!",
					NEW: "Procedimento criado com sucesso!",
				},
			},
			ADD_PROC: {
				TITLE: "Novo procedimento",
				procedimentoNome: "Nome",
				preco: "Valor",
				procedimentoPorcentagemComissao: "% Comissão",
				procedimentoValorCustoProtetico: "Protético",
				procedimentoIsAceitaFace: "Aceita faces?",
				procedimentoStatus: "Ativo?",
				CREATE_BTN: "Adicionar",
			},
		},
		PROFESSIONAL: {
			TITLE: "Profissionais",
			STATUS: {
				ATIVO: "Ativo",
				AGUARDANDO_ATIVACAO: "Aguardando Ativação",
				INATIVO: "Inativo",
			},
			TOAST: {
				SUCCESS: {
					TITLE: "Cadastro de profissional",
					SUBTITLE: "Profissional salvo com sucesso!",
				},
				DELETE: {
					TITLE: "Remoção de profissional",
					SUBTITLE: "Profissional removido com sucesso!",
				},
				SWAL: {
					TITLE_DELETE: "Excluindo item",
					TEXT_DELETE: "Deseja realmente remover este item?",
					CONFIRM_BUTTON: "SIM",
					CANCEL_BUTTON: "NÃO",
				},
			},
			TABLE: {
				COL1: "Ações",
				COL2: "Nome",
				COL3: "Perfil",
				COL4: "E-mail",
				COL5: "Status",
			},
			FORM: {
				TITLE: "Ficha do profissional",
				CONTACT: "Contato",
				AGENDA: "Agenda",
				COMMISSION: "Comissão",
				DAYOFWEEK: "Dia da semana",
				INITTIME: "Hora inicial",
				ENDTIME: "Hora final",
				profissionalNome: "Nome",
				profissionalDataNascimento: "Data nascimento",
				perfilContaModuloId: "Perfil",
				profissionalSexo: "Sexo",
				profissionalCPF: "CPF",
				profissionalCRO: "CRO",
				usuarioEmail: "E-mail",
				profissionalCelular: "Celular",
				profissionalTelefone: "Telefone",
				profissionalIntervaloPadrao: "Tempo padrão para atendimento",
				fixedLunch: "Horário de almoço fixo",
				profissionalIntervaloAlmocoInicial: "Início",
				profissionalIntervaloAlmocoFinal: "Fim",
				profissionalDescontaPorcentagemCartaoDebito: "Desconta cartão débito",
				profissionalDescontaPorcentagemCartaoCreditoVista: "Desconta cartão crédito a vista",
				profissionalDescontaPorcentagemCartaoParcelado: "Desconta cartão crédito parcelado",
				PORCENTAGEM_FIXA: "Fixa",
				POR_PROCEDIMENTO: "Por procedimento",
				VALOR_FIXO_PROCEDIMENTO: "Valor Fixo Procedimento",
				profissionalTipoComissao: "Tipo Comissão",
				profissionalPorcentagemComissao: "Comissão (%)",
			},
		},
		ANAMNESE: {
			TITLE: "Modelos de Anamnese",
			TABS: {
				ACTIONS: "Ações",
				NAME: "Nome",
				QUESTION: "Pergunta",
				QUESTION_TYPE: "Tipo da Pergunta",
				QUESTION_ALERT: "Alerta para Pergunta",
				ACTIVE: "Ativa",
			},
			BUTTONS: {
				NEW_MODEL: "Novo Modelo",
				NEW_QUESTION: "Nova Pergunta",
				CLOSE: "Fechar",
				CANCEL: "Cancelar",
				SAVE: "Salvar",
				FILLED_PRINT: "Imprimir",
				EMPTY_PRINT: "Imprimir modelo vazio",
			},
			LABELS: {
				NEW_QUESTION: "Nova Pergunta",
				EDIT_QUESTION: "Ediar Pergunta",
				ACTIVE_QUESTION: "Pergunta Ativa",
				ANAMNESE_MODEL_NAME: "Nome do Modelo de Anamnese",
				INATIVE_QUESTION: "Esta questão não aparecerá para preenchimento",
				ANAMNESE_MODEL_QUESTIONS: "Perguntas para o modelo de anamnese",
				ANSWER_PLACEHOLDER: "Escreve a resposta",
				ANSWER: "Resposta",
			},
		},
		PRINT: {
			TITLE: "Configuração de Impressão",
			HEADER: {
				SHOW: "Mostrar cabeçalho",
				MESSAGE: "Caso não haja uma imagem na conta, esta imagem aparecerá na impressão",
			},
			FOOTER: {
				TITLE: "Rodapé",
				MESSAGE:
					"Caso não digite nada, o valor mostrado será o que está cadastrado no sistema referente ao dados da clínica",
			},
		},
		ANNOTATIONS: {
			TITLE: "Listagem de Anotações",
			ADD_BUTTON: "Adicionar Anotação",
			DIALOG: {
				NEW: "Nova Anotação",
				EDIT: "Editar Anotação",
				SUBJECT: "Assunto *",
				PRINT: "Imprimir",
			},
			TABLE: {
				ACTIONS: "Ações",
				SUBJECT: "Assunto",
				LAST_UPDATE: "Última Alteração",
			},
		},
		DOCUMENT_MODEL: {
			TITLE: "Documentos e Atestados",
			HEADER: {
				BUTTON: "Novo Modelo",
				MESSAGE:
					"Nesta seção você pode  cadastrar os modelos de documentos e atestados que você mais utiliza para economizar tempo" +
					" durante a consulta com o paciente. Uma vez salvos, eles aparecerão no prontuário do paciente, necessitando de apenas um clique para emiti-los.",
			},
			LABELS: {
				NEW: "Novo Modelo",
				EDIT: "Editar Modelo",
				ACTIONS: "Ações",
				NAME: "Nome do Modelo",
				CREATE_AT: "Criado em",
				TEXT: "Texto *",
				MESSAGE:
					"*Caso o cadastro do paciente não possua o RG e/ou CPF, esses campos ficarão em branco",
			},
		},
		PROSTHETIC: {
			TITLE: "Protético",
			TABLE: {
				COL1: "Ações",
				COL2: "Nome",
				COL3: "Telefine",
				COL4: "Celular",
				COL5: "E-mail",
			},
			TOOLTIP_MAIL: "Reenviar e-mail",
			TOAST: {
				SUCCESS: {
					TITLE: "Cadastro Protético",
					SUBTITLE: "Profissional protético cadastrado com sucesso!",
				},
				DELETE: {
					TITLE: "Remoção de profissional protético",
					SUBTITLE: "Profissional removido com sucesso!",
				},
				MAIL: {
					TITLE: "Convite de profissional",
					SUBTITLE: "E-mail reenviado com sucesso!",
				},
			},
			SWAL: {
				TITLE_DELETE: "Excluindo item",
				TITLE_MAIL: "Reenvio de convite",
				TEXT_DELETE: "Deseja realmente remover este item?",
				TEXT_MAIL: "Deseja realmente reenviar e-mail para o profissional?",
				CONFIRM_BUTTON: "SIM",
				CANCEL_BUTTON: "NÃO",
			},
			FORM: {
				TITLE: "Ficha do profissional protético",
				CONTACT: "Contato",
				ADDRESS: "Endereço",
				nome: "Nome",
				telefone: "Telefone",
				email: "E-mail",
				celular: "Celular",
				cep: "CEP",
				endereco: "Endereço",
				numeroEndereco: "Número",
				complemento: "Complemento",
				bairro: "Bairro",
				cidade: "Cidade",
				ufCidade: "Estado",
				observacao: "Observações",
			},
		},
		PERMISSIONS: {
			TITLE: "Perfis e permissões",
			ALL_PERMS: "Acesso total",
		},
	},
	FINANCIAL: {
		FIND_BY: {
			DOC: "Documento",
			DESC: "Descrição",
			VALUE: "Busca",
		},
		LIST_SEARCH: {
			RANGE: {
				TODAY: "Hoje",
				WEEK: "Essa semana",
				MONTH: "Esse mês",
				PREV_MONTH: "Mês passado",
				BY_RANGE: "Por período",
			},
			TYPE_BILL: {
				TO_PAY: "A pagar",
				PAID: "Pago",
				LAZY: "Atrasado",
				TO_RECEIVE: "A Receber",
				RECEIVED: "Recebido",
			},
			TYPE_PAYMENT: {
				BOLETO: "Boleto Bancário",
				CARTAO_CREDITO: "Cartão de Crédito",
				CARTAO_DEBITO: "Cartão de Débito",
				CHEQUE: "Cheque",
				DINHEIRO: "Dinheiro",
				DEBITO_AUTOMATICO: "Débito Automático",
				TRANSFERENCIA_BANCARIA: "Transferência Bancária",
				PERMUTA: "Permuta",
			},
			STATUS_LIST: {
				ON_DATE: "A vencer",
				TODAY: "Hoje",
				RECEIVED_ALL: "Pago",
				RECEIVED_PARTIAL: "Pago parcialmente",
				LATE_PAYMENT: "Vencido",
			},
		},
		BILLS_TO_PAY: {
			TITLE: "Contas a pagar",
			MORE_SEARCH: "Mais opções de consulta",
			LESS_SEARCH: "Menos opções de consulta",
			TYPE_BILL: "Tipo de lançamento",
			TYPE_PAYMENT: "Forma de pagamento",
			ALL_CCOST: "Todos os centros de custo",
			ALL_CAT: "Todas as categorias",
			CCCOST: "Centro(s) de custo",
			CATEGORY: "Categoria(s)",
			CCOST_SELECTED: "Centro(s) de custo selecionado(s)",
			CATEGORY_SELECTED: "Categoria(s) selecionada(s)",
			PAY_BTN: "Pagar",
			PAY: "Pagar essa parcela",
			PAY_TITLE: "Pagar despesa",
			REVERSE_PAY: "Estornar essa parcela",
			SWAL: {
				TITLE: "ESTONRO DE PARCELA",
				TEXT: "Deseja realmente estornar a parcela selecionada?",
				CONFIRM_BUTTON: "SIM, Estorne!",
				CANCEL_BUTTON: "NÃO, Cancele...",
			},
			PRINT_REPORT: {
				SYNTHETIC: "Relatório Sintético",
				ANALYTICAL: "Relatório Analítico",
			},
			TABLE: {
				COL1: "Ações",
				COL2: "Vencimento",
				COL3: "Descrição",
				COL4: "Parcela",
				COL5: "Valor",
				COL6: "Pagamento",
				COL7: "Categoria",
				COL8: "Status",
				CCOST: "C. CUSTO",
				CAT: "CATEGORIA",
				VALUE: "VALOR",
			},
			PLACEHOLDERS: {
				TYPE_BILL: "Todos os tipos",
				TYPE_PAYMENT: "Todas as formas de pagamento",
			},
			ACCOUNT: {
				TYPE_COST: {
					FIXED: "Fixo",
					VARIABLE: "Variável",
				},
				TITLE: "Nova conta a pagar",
				VIEW_TITLE: "Visualizar conta",
				TITLE_EDIT: "Edição de conta a pagar",
				descricao: "Descrição",
				numeroDocumento: "Doc. N°",
				tipoCusto: "Tipo custo",
				dataCompetencia: "Competência",
				valor: "Valor",
				juros: "Juros",
				desconto: "Desconto",
				numeroTotalParcelas: "Nº Parcelas",
				dataVencimento: "Vencimento",
				formaDeRecebimento: "Forma Pagamento",
				categoriaId: "Categoria",
				centroCustoId: "Centro de custo",
				observacao: "Observações",
				dataPagamento: "Pagamento",
				totalToPay: "Total a pagar",
				PARCELS: {
					TITLE: "Parcelas",
					PARCEL: "Parcela",
					ERROR_PARCEL: "ERRO: Inconsistência nos valores das parcelas",
				},
				RATIO: {
					TITLE: "Rateio da conta",
					MIN_ITEM: "A conta deve ter pelo menos 1 item de rateio",
					ERROR_RATIO: "ERRO: Inconsistência nos valores do rateio",
				},
			},
		},
		BILLS_TO_RECEIVE: {
			TITLE: "Contas a Receber",
			RANGE: "Período",
			MORE_SEARCH: "Mais opções de consulta",
			LESS_SEARCH: "Menos opções de consulta",
			TYPE_BILL: "Tipo de lançamento",
			TYPE_PAYMENT: "Forma de pagamento",
			PLACEHOLDERS: {
				TYPE_BILL: "Todos os tipos",
				TYPE_PAYMENT: "Todas as formas de pagamento",
			},
			TABLE: {
				COL1: "Ações",
				COL2: "Vencimento",
				COL3: "Recebido em",
				COL4: "Cliente",
				COL5: "Convênio",
				COL6: "Descrição",
				COL7: "Parcela",
				COL8: "Recebimento",
				COL9: "Valor",
				COL10: "Recebido",
			},
			SWAL: {
				TITLE: "ESTONRO DE CONTA",
				TEXT: "Deseja realmente estornar a conta selecionada?",
				CONFIRM_BUTTON: "SIM, Estorne!",
				CANCEL_BUTTON: "NÃO, Cancele...",
			},
		},
		COMMISSION: {
			TITLE: "Comissão",
			PAYMENT_TYPE: "Tipo de pagamento",
			CONVENANT: "Convênio",
			AUTHORIZE: "Autorizar",
			UNAUTHORIZE: "Desautorizar",
			PAY: "Pagar",
			REMOVE_PAYMENT: "Remover pagamento(s)",
			BUTTONS: {
				SEARCH: "Pesquisar",
			},
			TABLE: {
				COL1: "Ações",
				COL2: "Data",
				COL3: "Dentista",
				COL4: "Paciente",
				COL5: "Procedimento",
				COL6: "Convênio",
				COL7: "Valor procedimento",
				COL8: "Valor Recebido",
				COL9: "Comissão %",
				COL10: "Valor Pagar",
			},
			STATUS: {
				OPEN: "Procedimentos",
				AUTHORIZED: "Autorizados",
				PAID: "Pagos",
			},
			SUCCESS: {
				AUTHORIZE: "Comissões autorizadas com sucesso!",
				UNAUTHORIZE: "Comissões desautorizadas com sucesso!",
			},
			SWAL: {
				TITLE: "PAGAMENTO DE COMISSÃO",
				TEXT: "Deseja incluir os procedimentos selecionados como PAGOS ao profissional?",
				CONFIRM_BUTTON: "SIM, Pague!",
				CANCEL_BUTTON: "NÃO, Cancele...",
			},
			SWAL_AUTHORIZE: {
				TITLE: "AUTORIZAÇÃO",
				TEXT: "Deseja autorizar o(s) procedimento(s) selecionado(s)?",
				CONFIRM_BUTTON: "SIM, Autorize!",
				CANCEL_BUTTON: "NÃO, Cancele...",
			},
			SWAL_UNAUTHORIZE: {
				TITLE: "DESAUTORIZAÇÃO",
				TEXT: "Deseja desautorizar o(s) procedimento(s) selecionado(s)?",
				CONFIRM_BUTTON: "SIM, Desautorize!",
				CANCEL_BUTTON: "NÃO, Cancele...",
			},
			SWAL_UNPAY: {
				TITLE: "REMOVER PAGAMENTO",
				TEXT: "Deseja remover o pagamento do(s) procedimento(s) selecionado(s)?",
				CONFIRM_BUTTON: "SIM, Remova!",
				CANCEL_BUTTON: "NÃO, Cancele...",
			},
			SWAL_SEND_TO_FINANCIAL_FLOW: {
				TITLE: "LANÇAR PAGAMENTO",
				TEXT:
					"Deseja lançar este pagamento no controle financeiro (Contas pagar e Fluxo de caixa)?",
				CONFIRM_BUTTON: "SIM, Lance!",
				CANCEL_BUTTON: "NÃO...",
			},
		},
		SELL_REPORT: {
			TITLE: "Relatório de Vendas",
			CARDS: {
				SALES: "Vendas",
				ENTRANCES: "Entradas",
				OUT: "Saídas",
			},
			LIST: {
				NO_DATA: "Nenhuma Transação Registrada.",
				PATIENT: "Paciente:",
				TYPE: "Tipo:",
				DESCRIPTION: "Descrição:",
				PAYMENT_METHOD: "Forma Pagamento:",
			},
		},
	},
	REPORTS: {
		RECEPITS: {
			TITLE: "Relatório de Recibos",
		},
		TREATMENTS: {
			TITLE: "Relatório de Tratamentos",
		},
		CASH_FLOW: {
			DAILY: {
				TITLE: "Fluxo de Caixa Diário",
				TABLE: {
					DATE: "Data",
					RECEIPTS: "Recebimentos",
					PAYMENTS: "Pagamentos",
					BALANCE: "Saldo Final",
				},
			},
			MONTHLY: {
				TITLE: "Fluxo de Caixa Mensal",
				PREDICTED: "Previsto",
				REALIZED: "Realizado",
				PREVIOUS_MONTH_BALANCE: "Saldo do Mês Anterior",
				RECEIVES: "Recebimentos",
				PAYMENTS: "Pagamentos",
				FINAL_BALANCE: "Saldo Final de Caixa",
			},
		},
		AGE_RANGE: {
			TITLE: "Relatório de Faixa Etária",
			CURRENT_AGE_RANGE: "Faixa etária selecionada:",
		},
		BIRTHDAY: {
			TITLE: "Relatório de Aniversariantes",
			CURRENT_MONTH: "Mês selecionado:",
		},
		INDICATIONS: {
			TITLE: "Relatório de Indicações",
		},
		SCHEDULINGS: {
			REPORT_TITLE: "Relatório de Agendamentos",
			STATISTICS_TITLE: "Indicadores de Comparecimento",
			STATISTICS: {
				NEGATIVE_LABEL: "Faltas e Cancelamentos",
				POSITIVE_LABEL: "Agendamentos realizados no período",
				ATTENDANCE: "Comparecimento",
				MISS: "Falta",
				DAYS: "Dias",
				MONTHS: "Meses",
			},
		},
		PATIENTS: {
			TITLE: "Relatório de Pacientes",
		},
		FILTERS: {
			EMISSION_DATE: "Data de Emissão",
			REGISTER_DATE: "Cadastrado em",
			INDICATION: "Indicação",
			TREATMENT_SITUATION: "Situação do Tratamento",
			CURRENT_STATUS: "Status Atual",
			PERIOD: "Período",
			COVENANT: "Convênio",
			SITUATION: "Situação",
			RESPONSIBLE_PROFESSIONAL: "Dentista Responsável",
			EXPERTISES: "Especialidade(s)",
			ORGANIZATION: "Empresa",
			BIRTH_DATE: "Nascimento",
			CLEAR_BIRTH_DATES: "Limpar datas",
			AT: "Até",
			ALL: "Todos",
			NO_DATA_TEXT: "Sem registros",
			PROFESSIONAL: "Dentista",
			PATIENT: "Paciente",
			PRINT: "Imprimir",
			SEARCH: "Pesquisar",
			PDF: "PDF",
			SHEETS: "Planilha",
		},
		TABLE: {
			DATE: "Data",
			EMISSION_DATE: "Data de Emissão",
			REGISTER_DATE: "Data Cadastro",
			BIRTH_DATE: "Data de Nascimento",
			EMAIL: "Email",
			STATUS: "Status",
			CANCEL_REASON: "Motivo Cancelamento",
			RECEIPT_NUMBER: "Número Recibo",
			GENDER: "Sexo",
			AGE: "Idade",
			CELL: "Celular",
			PHONE: "Telefone",
			PROCEDURE: "Procedimento",
			OBS: "Observação",
			ANOTATIONS: "Anotações",
			INDICATION: "Indicação",
			INDICATION_DESCRIPTION: "Descrição Indicação",
			ATTENDANCE_TYPE: "Tipo Atendimento",
			PROFESSIONAL: "Dentista",
			ORGANIZATION_NAME: "Nome Empresa",
			PROFESSIONAL_NAME: "Nome Profissional",
			RESPONSIBLE_PROFESSIONAL: "Profissional Responsável",
			PATIENT: "Paciente",
			EMITTER: "Emitente",
			DATA: "CPF/CNPJ",
			EMITTER_CPF_CNPJ: "CPF/CNPJ Emitente",
			PATIENT_CPF_CNPJ: "CPF/CNPJ Paciente",
			CPF_PAYMENT: "CPF Responsável Pagamento",
			PAYMENT_NAME: "Nome Responsável Pagamento",
			EMITTER_NAME: "Nome Emitente",
			REFER: "Referente a",
			VALUE: "Valor (R$)",
			OPENING_DATE: "Data de Abertura",
			SELLER_NAME: "Nome Vendedor",
			APROVAL_DATE: "Data de Aprovação",
			FINISH_DATE: "Data Encerramento",
			TREATMENT_STATUS: "Status Tratamento",
			RECEIPT_WAY: "Forma Recebimento",
			SCHEDULING_STATUS: "Status Agendamento",
			DISCOUNT_VALUE: "Valor do Desconto",
			DISCOUNT_PERCENT: "Percentual de Desconto",
			VALUE_WITH_DISCOUNT: "Valor com Desconto",
			DATE_TIME_EVENT: "Data e Hora do Evento",
			PAYMENT_WAY: "Forma Pagamento",
			COVENANT: "Convênio",
			SITUATION: "Situação",
			PATIENT_SITUATION: "Situação do Paciente",
			NAME: "Nome",
			EXPERTISES: "Especialidades",
			PATIENT_NAME: "Nome do Paciente",
			WHATSAPP: "Whatsapp",
			AMOUNT: "Quantidade",
			ADDRESS: "Endereço",
			N_ADDRESS: "Número Endereço",
			CEP: "CEP",
			NEIGHBORHOOD: "Bairro",
			CITY: "Cidade",
			UF: "UF",
		},
	},
	TOOLS: {
		PROSTHETIC: {
			CLEAR_FILTER: "Limpar filtros",
			ADD_SERVICE: "Adicionar serviço",
			TOOLTIP_DONE: "Marcar como entregue ou pago",
			TOOLTIP_REMOVE_DELIVERY: "Excluir entrega",
			TOOLTIP_PAY: "Pagar",
			TITLE_ACCOUNT: "Serviço protético",
			TITLE: "Serviços protéticos",
			ABERTO: "Aberto",
			RECEBIDO: "Recebido",
			PROSTHETIC: "Protético",
			DENTIST: "Dentista",
			OPEN: "SOLICITADOS",
			RECEIVED: "ENTREGUES",
			TABLE: {
				COL1: "Ações",
				COL2: "Solicitação",
				COL3: "Serviço",
				COL4: "Valor",
				COL5: "Paciente",
				COL6: "Protético",
				COL7: "Profissional",
				COL8: "Prev. Entrega",
			},
			SWAL: {
				TITLE_REMOVE: "EXCLUIR ENTREGA",
				TITLE_ADD_ACCOUNT: "ADICIONAR CONTA A PAGAR",
				TEXT_ADD_ACCOUNT: "Você deseja realmente excluir a entrega?",
				TEXT_REMOVE: "Você deseja realmente excluir a entrega?",
				CONFIRM_BUTTON_REMOVE: "SIM, Exclua",
				CANCEL_BUTTON: "NÃO, Cancele",
			},
			TOAST: {
				SUCCESS: {
					TITLE: "Serviço protético",
					SUBTITLE: "Serviço salvo com sucesso!",
				},
				WARN_ACCOUNT: {
					TITLE: "Serviço protético salvo com ressalva",
					SUBTITLE:
						"Você informou um pagamento sem criar uma conta a pagar, então invalidamos a informação de pagamento.",
				},
				REMOVED: {
					TITLE: "Serviço protético",
					SUBTITLE_WITH_PAYMENT: "Entrega e conta a pagar removida com sucesso!",
					SUBTITLE_WITHOUT_PAYMENT: "Entrega removida com sucesso!",
				},
			},
			FORM: {
				TITLE: "Serviço protético",
				addItemDropdown: "Adicionar",
				itemServicoProteticoId: "Serviço *",
				pacienteId: "Paciente *",
				proteticoId: "Protético *",
				profissionalId: "Profissional *",
				valor: "Valor *",
				dentes: "Dentes *",
				dataPrevistaEntrega: "Previsão",
				dataEntrega: "Entrega",
				dataPagamento: "Pagamento",
				metal: "Metal",
				cor: "Cor",
			},
			CONFIRM: {
				PATIENT: "Paciente",
				SERVICE: "Serviço",
				PROSTHETIC: "Protético",
				PROFESSIONAL: "Profissional",
				PAID: "Pago",
			},
		},
	},
	PAYMENT_RECEIPT: {
		EMIT_TOOLTIP: "Emitir Recibo",
		PRINT_TOOLTIP: "Imprimir Recibo",
		TITLE: "Emissão de Recibo",
		LABELS: {
			DATE: "Data: ",
			N: "Nº Recibo: ",
			VALUE: "Valor: ",
			EMITTER: "Dados do Emitente",
			SIMPLE: "Simples",
			SELECT_DENTIST: "Selecione o Dentista",
			CLINIC: "Clínica",
			DENTIST: "Dentista",
			NAME: "Nome",
			CPF_CNPJ: "CPF/CNPJ",
			CPF: "CPF",
			CNPJ: "CNPJ",
			RECEIPT_FOR: "Emitir o recibo em nome de: ",
			RESPONSIBLE: "Dados do responsável pelo pagamento",
			ANOTHER: "Outra Pessoa",
		},
		EMIT: "Emitir Recibo",
	},
};
